.reviewpricing{
  &--mobile{
    &__padding{
      padding-top: 10px;
    }

    &__text-right{
      text-align: right;
    }
  }
}