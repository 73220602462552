.allocateguests{

  &--rooms {

    hr {
      margin-bottom: 20px;
    }
  }

  &--room {
    margin-bottom: 10px;
  }

  &--button__text{
    font-size: 12px;
    font-size: 1.2rem;

  }

  &--roomtypes__font{
    color: #666 !important;
    font-size: 14px;
    font-size: 1.4rem;
  }

  &--traveldates__headings{
    padding-bottom: 5px;
    display: block;
  }


  &__submit{
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: center;
  }

  &--description{
    b{
      font-size: 14px;
      font-size: 1.4rem;
    }
  }

  &--form{
    width: 100px;
    display: inline;
    padding-left: 5px;
  }

  &--steps{
    font-size: 25px;
    font-size: 2.5rem;
    padding: 0;
    float: left;

    &__completed{
      background-color: #62370d;
    }

    &__uncompleted{
      color: #ebebeb;
    }

    &__current{
      color: $green;

    }

    &__break{
      padding: 0;
      margin-top: 10px;
    }

    &__padding{
        padding-left: 27%;
        padding-top: 15px;

      &-description{
        padding-top: 0;
        padding-left: 220px;
        padding-bottom: 40px;
      }
    }

    &__text{
      font-size: 11px;
    }


    &__selectdate{
      padding-left: 0;
    }

    &__allocateguests{
      padding-left: 64px;
    }

    &__reviewpricing{
      padding-left: 79px;
    }

    &__paymentdetails{
      padding-left: 69px;

    }


  }

  &--headings {
    padding-top: 10px;
    margin-bottom: 20px;

    &__border {
      border-bottom: solid 1px #AB864F;
      padding: 0 15px;

    }
    h3 {
      font-weight: bold;
      padding: 0px 0px 6px 0px;
      margin-top: 20px;
      font-size: 1.5rem;
      font-size: 15px;
    }
    &__establishment-link {
      text-decoration: none !important;
    }
    &__enquire{
      a {
        color: $bodygray;
        text-decoration: underline;
      }
    }
    &__safebooking {
      text-align: right;
      margin-top: 20px;
    }

    &__padding{
      padding: 0;
      span{
        font-weight: normal;
        font-size: 12px;
        font-size: 1.2rem;
      }
    }
  }

  &--traveldate{

    padding-top: 10px;
    padding-bottom: 30px;

    &__mainheading{
      font-weight: bold;
      font-size: 1.4rem;
      font-size: 14px;
      margin-top: 20px;
    }

    span{
      font-weight: bold;
      margin-top: 20px;
      display: block;
    }

    &__dates{
      display: inline-block;
      padding: 0px 20px 0px 0px;

      label{
        font-weight: bold;
        padding-right: 5px;
      }
    }
  }

  &--roomdesc{

    margin-top: 20px;


    &__heading{
      span{
        vertical-align: bottom;
        color: #8C5D31;
        font-weight: bold;
      }
    }
  }

  &__room-rates {
    margin-bottom: 10px;

    table {
      float: right;
    }

    td {
      padding: 0 5px;
    }

    &--old {
      text-decoration: line-through;
      color: $bodygray !important;
    }

    &--discounted {
      color: $pink;
      font-weight: bold;
    }
  }

  &--pricing{

    &__padding{
      padding: 0;
    }

    &__margin-row{
      margin-top: 20px;
    }
  }

  &--display{
    display: inline-block;

  }

  &--context__padding{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &--maxguests{
    text-align: right;

    img {
      vertical-align: bottom;
    }
  }

}
.allocateguest--selects__mobile-width{
  width: 50px;

}
.bestpricegaurentee--text{
  color: #75706d;
  text-align: center;
  font-style: normal;

  span{
    font-style: italic;
  }



  i{
    color: $green;
  }
}



@media (min-width: 1200px){
  /*set width of website*/
}

@media (max-width: 1199px) {
}

@media (max-width: 991px){
  /*set width of website*/

  .allocateguests {

    &--steps {
      &__padding-description {
        padding-left: 148px;
      }

      &__allocateguests {
        padding-left: 20px;
      }

      &__reviewpricing {
        padding-left: 33px;
      }

      &__paymentdetails {
        padding-left: 32px;
      }
    }

    &--headings__padding{
      padding-left: 5px;
      padding-right: 5px;

      span{
        text-align: center;
        font-size: 1.0rem;
      }
    }
  }
}

@media (max-width: 767px) {
  .allocateguests {

    &--description{
      b{
        font-size: 16px;
        font-size: 1.6rem;
      }
    }
  }

  .allocateguest {

    &__label {
      line-height: 30px;
      margin-top: 7px;
      text-align: left;
    }

    &--selects__mobile{
      text-align: center;

      select{
        width: 100% !important;
        height: 30px;
        margin-top: 7px;
      }

      span{
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;
        display: block;
      }
    }

    &--maxguests{
      text-align: left;
    }
  }

  .toggle-liveavailability{
    display: none;
  }

  .allocateguests--description{
  }

  .details-liveavailability-toggler{
    padding: 10px 0;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .toggle-liveavailability{
    padding: 10px ;
  }
}