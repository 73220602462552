.establishment-heading{
  &__gallery{
    margin-top: 20px;

    h2{
      font-size: 1.4rem;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }

    h1{
      font-size: 1.7rem;
      font-size: 17px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }
  }
}


.gallery{

  &--heading{

    h2{
      font-weight: bold;
      font-size: 1.4rem;
      font-size: 14px;
      padding: 0px 0px 6px 0px;
      margin-top: 20px;
      text-align: center;
    }
  }

  &--height{
    height: auto !important;
  }
}


.video{

  &__content{
    img{
      width: 100%;
    }
  }
}