.target-control {
  position: absolute;
  height: 100%;
  cursor: pointer;
  top: 0;
}

.target-control--next {
  width: 25%;
  right: 0;
}

.target-control--prev {
  width: 25%;
  left: 0;
}

.target-control__prev-arrow {
  left: 20px;
  width: 32px;
  height: 56px;
  position: absolute;
  display: block;
  top: 50%;
  margin-top: -28px;
  background: url('/img/arrow_prev.png') no-repeat;
}

.target-control__next-arrow {
  right: 34px;
  width: 32px;
  height: 56px;
  position: absolute;
  display: block;
  top: 50%;
  margin-top: -28px;
  background: url('/img/arrow_next.png') no-repeat;
}

.target-control__arrow--small {
  width: 16px;
  height: 28px;
  background-size: contain;
  margin-top: -14px;
}
