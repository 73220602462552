.new-booking {

    .light-grey-background {
        background-color: #F4F4F4 !important;
    }

    &__container {
      margin: 15px;
      background-color: #FFF;
    }

    &__inner {
      margin: 15px;
    }

    &__header {
        position: relative;
        margin: 0;
        margin-bottom: 15px;
        padding: 0;
        h2 {
            font-size: 24px;
            font-size: 2.4rem;
            font-weight: bold;
        }
    }

    &__summary {
        padding: 20px;
        overflow: hidden;
        outline: 1px solid #CCC;
        background-color: #F4F4F4 !important;

        &-margin {
            margin-bottom: 5px;
            padding: 0;

          &-total {
              margin-bottom: 12px;
              padding: 0;
          }
        }

        &-minus {
            position: absolute;
            left: -15px;
        }

        &-topline {
            border-top: 1px dotted #000;
        }

        &-dbl-line {
            border-bottom: 1px dotted #000;
            border-top: 1px dotted #000;
        }

        &-due-at-establishment {
            padding: 5px 0 0 0;
        }
    }

    &__loyalty {
        margin-top: 15px;
    }

    &__left-content {
        padding: 0;
    }

    &__payable {
        margin-top: -4px;

        &-tri {
            border-color: transparent #3f97ff transparent transparent;
            border-style: solid;
            border-width: 13px 13px 13px;
            height: 0px;
            width: 0px;
            position: relative;
        }

        &-tri--small {
            border-color: transparent #FFFFFF transparent transparent;
            border-style: solid;
            border-width: 12px 12px 12px;
            height: 0px;
            width: 0px;
            position: absolute;
            top: -12px;
            right: -13px;
        }

        &-body {
            background-color: white;
            padding-top: 2px;
            padding-bottom: 2px;
            padding-right: 10px;
            border-top: solid 1px #3f97ff;
            border-right: solid 1px #3f97ff;
            border-bottom: solid 1px #3f97ff;

            .hidden-sm{
                position: relative;
                top: 1px;
                left: 3px;
            }
        }
    }

    &__price-spacing {
        padding: 5px 0;
        margin-bottom: 0;
    }

    &__details {
        font-size: 13px;
        font-size: 1.3rem;
        padding: 0;

        &-header {
            font-size: 16px;
            font-size: 1.6rem;
            font-weight: bold;
            margin: 20px 0;

            &-numbering{
                display: inline-block;
                margin-top: 40px;
                position: relative;
                top: 2px;
                padding-bottom: 15px;
            }
        }

        &-content {
            padding-right: 0;
            color: $black;
        }

        &-name {
            padding-bottom: 10px;
            padding-left: 0;
            font-weight: bold;

            &-print {
                padding-bottom: 10px;
            }
        }

        &-email {
            padding-bottom: 10px;
            font-weight: bold;
            padding-left: 0;

            &-print {
                padding-bottom: 10px;
                white-space: normal;
            }
        }

        &-number {
            font-weight: bold;
            padding: 0;

            &-container {
                padding: 0 0 10px 15px;
            }

            &-code {
                float: left;
                display: inline-block;
                width: 70px;
            }

            &-btn {
                height: 30px;
                padding: 3px 0 3px 6px;
                width: 70px;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
            }

            &-dropdown {
                min-width: 236px;
                height: 400px;
                overflow-y: scroll;
                border: 1px solid #93785D;
                border-radius: 1px;
                padding-top: 0px;
                font-size: 12px;
                font-size: 1.2rem;
            }

            &-print {
                width: 110px;
                float: left;
                height: 30px;
                padding: 5px;
                border-radius: 0px 4px 4px 0px;
                border: #ccc 1px solid;
                border-left-style: none;
            }

            &-request {
                padding: 0px;
                padding-bottom: 10px;
                padding-left: 0px;
                font-weight: bold;
                font-size: 13px;
                font-size: 1.3rem;

                span {
                  color: #999999;
                }
            }

            &-motivate {
                color: #999999;
                padding: 0;
            }

            &-comment {
                padding: 0;
                textarea {
                    border-radius: 0;
                }
            }

        }
    }

    &__payment {
        padding: 0;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: bold;
        margin: 20px 0;

        &-eft {
            border: 2px solid #FFF;
            outline: 1px solid #E2E2E2;
            padding: 10px;
            margin-bottom: 10px;

            span{
                float: right;
            }

            &-details {
                font-size: 13px;
                font-size: 1.3rem;
                display: none;
                margin-top: 35px;

                h2 {
                    font-weight: bold;
                    font-size: 13px;
                    font-size: 1.3rem;
                    margin-left: 15px;

                }
            }
        }

        &-cc {
            padding: 10px;
            border: 2px solid #FFF;
            outline: 1px solid #E2E2E2;
            z-index: 1000;

            &-details {
                padding-top: 20px;
                font-size: 13px;
                font-size: 1.3rem;
                margin-left: 15px;
            }

            &-type {
                padding: 0;
                padding-bottom: 5px;
                margin-top: 40px;
            }

            &-number {
                padding: 0;
            }

            &-name {
                padding: 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &-expiry {
                padding-left: 0;
            }

            &-month {
                padding: 0;
                margin-bottom: 10px;
            }

            &-year {
                padding: 0;
                margin-bottom: 10px;
                margin-left: 12px;
            }

            &-cvc {
                clear: both;
                padding: 0;
                max-width: 50px;
            }

            &-symbol {
                padding: 0;
                display: block;
                img {
                    padding-left: 30px;
                }
                span {
                    font-size: 11px;
                    font-size: 1.1rem;
                }
            }
        }
    }

    &__form-width {
        padding-left: 0;
        margin-bottom: 10px;
        width: 100%;
        float: left;
   
        &-modified {
            padding: 0;
            margin: 0;
            margin-right: 10px;
        }
    }

    &__voucher {
        border: 2px solid #FFF;
        outline: 1px solid #E2E2E2;
        padding: 10px;
        margin-top: 10px;
        h2 {
            font-size: 13px;
            font-size: 1.3rem;
            margin: 0;
        }
        a {
            cursor: pointer;
            text-decoration: underline;
        }
        span {
            font-weight: bold;
        }

        &-submit {
            padding: 0;
            margin-top: 5px;
            label {
                font-weight: normal;
            }
            input {
                max-width: 80px;
            }
            button {
                font-weight: bold;
                background-color: #94946E;
                color: #FFF;
                padding: 3px 6px;
                &:hover {
                    background-color: #373333;
                    color: #FFF;
                }
            }
        }
    }

    &__terms {
        text-align: center;
        margin-top: 20px;
        h2 {
            font-size: 12px;
            font-size: 1.2rem;
            font-weight: normal;
        }

        &-cond {
            text-decoration: underline;
        }

        &-button {
            color: #ffffff !important;
            background-color: $green;
            padding: 6px;
            font-size: 13px;
            border-radius: 0;
            border: none;
            text-transform: uppercase;
            font-weight: bolder;

            &:hover {
                color: #FFF;
            }

            &:focus {
                color: #FFF;
            }

        }
    }

    &__sidebar {
        float: right;
        padding: 0;

        &-more {
            font-size: 12px;
            font-size: 1.2rem;
            a {
                text-decoration: underline;
                float: left;
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }

        &-desk {
            margin: 0;
            float: left;
            text-align: center;
            padding: 0 10px;
            background-color: #F4F4F4 !important; 
            font-size: 12px;
            font-size: 1.2rem;

            h2 {
                font-size: 14px;
                font-size: 1.4rem;
                font-weight: bold;
                margin-bottom: 10px;
                color: $black;
            }

            &-number {
                font-weight: bold;
                color: #000;
                font-size: 13px;
                font-size: 1.3rem;
            }

            &-ref {
                color: #000;
                margin: 0;
            }

            &-refno {
                font-weight: bold;
                color: #000;
            }

        }

        &-mobi {
            float: left;
            background-color: #F4F4F4 !important;
            margin: 0;
            margin-top: 40px;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 12px;
            font-size: 1.2rem;

            h2 {
                font-size: 14px;
                font-weight: bold;
                color: #653305;
                margin-bottom: 10px;
            }

            &-call {
                font-weight: bold;
                background-color: #97966F;
                border-radius: 7px;
                color: #FFF;
                display: inline-block;
                padding: 5px;
                transition: 0.5s;

                &:hover {
                    background-color: #737255;
                    transition: 0.5s;
                }
            }

            &-ref {
                color: #000;
            }

            &-refno {
                font-weight: bold;
                color: #000;
            }
        }
    }

    &__thawte {
        margin-top: 20px;
        float: left;
    }
}

// Separate CSS

.width-60 {
    width: 60px;
}

.width-70 {
    width: 70px;
}

#mtEnquiryBookingPayment{
    span{
        float: right;
    }
}

#mtCreditCardLogos {
    background: url('/img/card_logos.png') no-repeat;
    width: 132px;
    height: 20px;
}

.brown-text {
}

.border__flag {
    outline: 1px solid #ccc;
    min-width: 31px;
}

.padding-right-zero {
    padding-right: 0;
}

.padding-left-zero {
    padding-left: 0;
}

.margin-right-10 {
    margin-right: 10px;
}

/* Repeated across multiple styleshets */
/* This is due to module separation if we split stylesheets instead of using 1 compiled*/

.padding-zero {
    padding: 0;
}

.display-none {
    display: none;
}

// ------------

@media (max-width: 335px) {
    .new-booking {

        &__summary {
            &-due-at-establishment {
                padding: 0;
            }
        }
    }
}

@media (max-width: 380px) {
    #mtCreditCardLogos {
        background: url('/img/card_logos_small.png') no-repeat;
        width: 63px;
    }
}

@media (max-width: 380px) {
  .new-booking {
      &__summary {
          padding-left: 10px !important;
          padding-right: 10px !important;
      }

      &__payable {
          margin-left: -7px !important;
          margin-right: -8px !important;
          margin-top: -4px !important;
      }
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
    .new-booking {
        &__container {
            margin-left: 0;
            margin-right: 0;
        }
        &__payable {
            font-size: 9px;
            font-size: 0.9rem;
            margin-left: 5px;
            padding-bottom: 10px !important;

            &-body {
                padding-bottom: 10px !important;
            }

            &-body .hidden-sm {
                top: 4px !important;
            }
        }
        &__details {
            &-number {
                width: 100%;

                &-container {
                    width: 100%;
                    clear: both;
                }
            }
        }
    }
}

@media screen and (min-width: 480px) {
    .new-booking {
        &__details {
            &-email-print {
                white-space: nowrap;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .new-booking {
        &__header {
            h2 {
                margin-top: 0;
            }
        }

        &__details {
            &-name {
                padding-bottom: 0;

                &-print {
                    padding-left: 0;
                }
            }

            &-email {
                padding-bottom: 0;

                &-print {
                    padding-left: 0;
                }
            }

            &-number-container {
                padding-left: 0;
            }
        }

        &__payment-cc-year {
            margin-left: 0;
        }

        &__payment-cc-symbol {
            img {
                padding-left: 0;
                padding-top: 10px;
            }
        }
    }

    .new-booking__voucher{
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .new-booking {
        &__form-width {
                width: 100%;
        }
    }
}

@media screen and (min-width: 992px) {
    .new-booking {
        &__form-width {
                width: 60%;
        }
    }
}