/*
VOUCHERS
*/
div.voucher_box_recommendations {
  border-top: solid 2px rgb(171, 134, 79);
  margin-top: 40px;
  padding-left: 20px;
}

div.voucher_box_tile {
  /*width: 220px;*/
  height: 223px;
}

div.voucher_box_tile .border, div.voucher_box_recommendations .border {
  border: 1px solid #6D6F40;
  box-shadow: 3px 3px 2px #CCCCCC;
}

div.voucher_box_landscape {
  border: 1px solid #CCC;
  background: url('/img/smallLogo.png') no-repeat 15px top;
  background-color: transparent;
}



div.voucher_box_landscape_content {
  text-align: left;
  font-size: 12px;
  padding: 10px 0px 15px 115px;
  margin-top: 5px;
}

div.voucher_box_teaser {
  border: 1px solid #D2D1A3;
  background: #EFEADD no-repeat center top;
  color: #6D6F40;
  float: left;
  clear: none;
  /*width: 182px;*/
  width: 360px;
}

/*defunct*/
div.voucher_box_portrait {
  border: 1px solid #D2D1A3;
  background: #EFEADD no-repeat center top;
  color: #6D6F40;
  float: left;
  clear: none;
  margin: 0px 14px;
  width: 170px;
  padding-left: 5px;
  padding-right: 5px;
}

div.voucher_box_thumbnail {
  border: dashed 2px #b4abae;
  color: rgb(109, 111, 64);
  clear: none;
  margin: 0px 9px;
//  width: 184px;
  height: 199px;
  width: 193px;
  padding-left: 4px;
  padding-right: 4px;
}

//div.voucher_box_thumbnail:first-child {
//  margin-left: 12px;
//}


.voucher_button_box {
  font-size: 16px;
  background: #E7EAF1;
  color: #007FA0;
  padding: 0px 0px 12px 0px;
  text-align: center;
  vertical-align: baseline;
  margin-top: 18px;
  max-width: 794px;
}

.voucher_button_box img {
  position: relative;
  top: 10px;
}

.voucher_button_box .email_sent {
  margin-top: 16px;
  display: block;
}

div.voucher_box_portrait:hover, div.voucher_box_teaser:hover {
  background: rgb(248, 247, 243);
}

div.cape_union_mart_logo {
  background: #EFEADD url('/img/smallLogo.png') no-repeat center top;
}

div.voucher_box_top {
  text-align: center;
  padding-top: 45px;
  padding-bottom: 15px;
  padding-top: 5px;
  height: 92px;
  width: 185px;
}

div.voucher_box_bottom {
  height: 55px;
  border-top: 1px dotted #5C350E;
}

div.voucher_box_thumbnail .voucher_image {
  width: 185px;
  height: 44px;
}

div.voucher_box_thumbnail .voucher_no_image {
  width: 185px;
  height: 50px;
}

div.voucher_box_thumbnail .voucher_heading {
  font-size: 16px;
  font-weight: bold;
  line-height: 100%;
  display: block;
  margin-top: 5px;
  color: #666464;
  overflow: hidden;
  height: 39px;
}

div.voucher_box_thumbnail .voucher_call_to_action {
  font-size: 12px;
  display: block;
  color: $pink;
  height: 28px;
  line-height: 100%;
}

div.voucher_box_thumbnail .voucher_button {
  margin-left: 0px;
}

div.voucher_box_thumbnail .voucher_button a {
  margin: auto;
}

div.voucher_box_thumbnail .voucher_distance {
  font-size: 12px;
  display: block;
  color: #3f3f41;
  font-weight: bold;
}

.voucher_tcs_link {
  color: #5C350E;
  font-size: 11px;
  font-weight: bold;
}

p.explanatory {
  margin-left: 15px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 15px;
}

#booking_confirmation_vouchers p.explanatory {
  margin-left: 0px;
  margin-top: 0px;
}

/*carousel*/

div.ribbon {
  float: left;
}

div.slide {
  //border: solid 1px #AB864F;
  //background-color: #E9E3D3;
  padding: 10px;
  height: 200px;
}

div.external_slide {
  border-top-width: 1px;
  margin: 0px 15px 5px 15px;
}

div.slide .thumbnail {
  display: block;
  margin-bottom: 0px;
}

div.slide .surround_shadow_dark {
  float: left;
  margin-left: 4px;
}

div.slide .left_arrow {
  float: left;
  display: block;
  margin: 30px 10px 0px 20px;
}

div.slide .right_arrow {
  float: right;
  display: block;
  margin: 30px 20px 0px 00px;
}

div.slide .help_text {
  font-size: 65%;
  font-weight: bold;
  float: right;
  margin-right: 72px;
  margin-bottom: 8px;
}

div.mini_slide {
  float: left;
  clear: left;
  width: 470px;
  margin-left: 5px;
  margin-top: 10px;
  height: 115px;
  border-top-width: 1px;
  margin-right: 15px;
  margin-bottom: 10px;
}

div.mini_slide .right_arrow {
  margin-right: 5px;
  margin-left: 5px;
}

div.mini_slide .left_arrow {
  margin-left: 5px;
  margin-right: 5px;
}

div.mini_slide .surround_shadow_dark {
  float: left;
  margin-left: 2px;
}

div.mini_slide div.ribbon {
  width: 410px;
}

div.big_slide .left_arrow, div.big_slide .right_arrow {
  margin-top: 65px;
}

div.big_slide {
  height: 205px;
}

div.big_slide div.ribbon {
  width: 850px;
}

div.slide .help_text {
  font-size: 65%;
  font-weight: bold;
  float: right;
  margin-right: 72px;
  margin-top: 35px;
}

div.mini_slide div.help_text {
  margin-top: 40px;
  margin-right: 50px;
}

.sli {
  position: absolute;
  visibility: hidden;
}

#ribbon, #pictureRibbon, #voucherRibbon {
  position: relative;
  width: 850px;
  height: 95px;
}

.ribbonNav {
  left: 177px;
  width: 480px;
  text-align: center;
}

.ribbonNav img {
  margin-left: 10px;
}

.pictureRibbonNav {
  top: 125px;
}

.bigRibbonNav {
  top: 185px;
}

.tinyRibbonNav {
  top: 93px;
  left: 134px;
  width: 150px;
}

#tinyRibbon {
  height: 55px;
  position: relative;
  width: 425px;
}

#tinyRibbon .surround_shadow_dark {
  margin-left: 2px;
}

#tinyRibbon .sli {
  padding-left: 2px;
}

#bigRibbon {
  height: 110px;
  position: relative;
  width: 850px;
}

#bigRibbon .surround_shadow_dark {
  margin-left: 6px;
}

#voucherRibbon {
  height: 110px;
}

.voucherRibbonNav {
  margin-top: 189px;
}

#voucherville_reccomendations {
  margin: 0px 15px 0px 15px;
  margin-top: 10px;
  //padding-left: 11px;
}

#booking_confirmation_vouchers #voucherRibbon {
  width: 790px;
}

#booking_confirmation_vouchers #voucherville_reccomendations {
  width: 835px;
}

#booking_confirmation_vouchers .voucherRibbonNav {
  margin-top: 200px;
  width: 423px;
}

div.fancybox-skin {
  background-color: #ffffff !important;
}

#booking_confirmation_vouchers div.voucher_box_thumbnail {
  margin: 0px 2px;
}

//#booking_confirmation_vouchers div.voucher_box_thumbnail:first-child {
//  margin-left: 2px;
//}

#booking_confirmation_vouchers .slide {
  margin: 0px 0px 0px 0px;
}

.slick__container {
  margin: auto;
}

.slick__appearance {
  border: solid 1px $silver;
  padding: 10px;
  position: relative;
  margin-bottom: 40px !important;
}

.voucher__override {
  height: 195px !important;
  margin-bottom: 20px !important;
}

.voucher__override .slick-list {
  height: auto;
}

.voucher__override .slick-track {
  height: auto;
}

.voucher__override .slick__container {
  width: auto;
}

.margin-auto  {
  margin: auto !important;
}

.carousel__indicator {
  width: 100px;
  background-color: #5C350E;
  color: #FFF;
  border-radius: 4px;
  height: 25px;
  padding-top: 2px;
}

.banner_image {
  width: 100%;
  height: 100%;
  max-width: 794px;
}

.voucher-line {
  width: 100%;
}

.description-padding {
  padding-top: 10px;
}

.booking__heading {
  margin-left: 15px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 25px;
}

.text-align-center {
  text-align: center;
}

@media (max-width: 767px) {
  .slick-dots {
    display: none !important;
  }
  .voucherville_info_cape_union_mart {
    font-size: 12px !important;
  }
  .voucherville_info_cape_union_mart .business .name {
    font-size: 21px !important;
  }
  .voucherville_info_cape_union_mart .business .tag_line {
    font-size: 11px !important;
  }
  .voucherville_info_cape_union_mart .voucher .name {
    font-size: 17px !important;
  }

  #carousel-counter{
    margin-top: 20px !important;
  }
}

@media (max-width: 480px) {
  .carousel__indicator {
   padding-top: 4px;
  }
  .voucherville_info_cape_union_mart .voucher .custom_text {
    float: left !important;
    margin-top: 5px !important;
  }
  .voucherville_info_cape_union_mart .voucher .logo {
    float: left !important;
  }
  .slick-prev {
    left: 10px !important;
  }
  .slick-next {
    right: 10px !important;
  }
}
