/*
This is a list of styles that Cameron introduced for quickly setting padding, margins etc
It should be actively phased out, as the classes represent styling and not semantic meaning (e.g. "font-15" vs "room-type-heading")
*/


/*default css should not be edited*/

.full-width{width: 100% !important;}
.full-max-width{max-width: 100% !important;}
.full-height{height: 100% !important;}
.full-max-height{max-height: 100% !important;}
/******************************************************************************************************************************/
/*********PADDING***********/
/******************************************************************************************************************************/
.p-0{padding: 0px !important}
.p-l-0{padding-left: 0px !important}
.p-r-0{padding-right: 0px !important}
.p-lr-0{padding-left: 0px !important; padding-right: 0 !important}
.p-t-0{padding-top: 0px !important;}
.p-b-0{padding-bottom: 0px !important;}
.p-tb-0{padding-top: 0px !important; padding-bottom: 0 !important}


.p-5{padding: 5px !important}
.p-l-5{padding-left: 5px !important}
.p-r-5{padding-right: 5px !important}
.p-lr-5{padding-left: 5px !important; padding-right: 5px !important}
.p-t-5{padding-top: 5px !important;}
.p-b-5{padding-bottom: 5px !important;}
.p-tb-5{padding-top: 5px !important; padding-bottom: 5px !important}

.p-10{padding: 10px !important}
.p-l-10{padding-left: 10px !important}
.p-r-10{padding-right: 10px !important}
.p-lr-10{padding-left: 10px !important; padding-right: 10px !important}
.p-t-10{padding-top: 10px !important;}
.p-b-10{padding-bottom: 10px !important;}
.p-tb-10{padding-top: 10px !important; padding-bottom: 10px !important}

.p-15{padding: 15px !important}
.p-l-15{padding-left: 15px !important}
.p-r-15{padding-right: 15px !important}
.p-lr-15{padding-left: 15px !important; padding-right: 15px !important}
.p-t-15{padding-top: 15px !important;}
.p-b-15{padding-bottom: 15px !important;}
.p-tb-15{padding-top: 15px !important; padding-bottom: 15px !important}

.p-20{padding: 20px !important}
.p-l-20{padding-left: 20px !important}
.p-r-20{padding-right: 20px !important}
.p-lr-20{padding-left: 20px !important; padding-right: 20px !important}
.p-t-20{padding-top: 20px !important;}
.p-b-20{padding-bottom: 20px !important;}
.p-tb-20{padding-top: 20px !important; padding-bottom: 20px !important}

.p-25{padding: 25px !important}
.p-l-25{padding-left: 25px !important}
.p-r-25{padding-right: 25px !important}
.p-lr-25{padding-left: 25px !important; padding-right: 25px !important}
.p-t-25{padding-top: 25px !important;}
.p-b-25{padding-bottom: 25px !important;}
.p-tb-25{padding-top: 25px !important; padding-bottom: 25px !important}

.p-30{padding: 30px !important}
.p-l-30{padding-left: 30px !important}
.p-r-30{padding-right: 30px !important}
.p-lr-30{padding-left: 30px !important; padding-right: 30px !important}
.p-t-30{padding-top: 30px !important;}
.p-b-30{padding-bottom: 30px !important;}
.p-tb-30{padding-top: 30px !important; padding-bottom: 30px !important}

.p-35{padding: 35px !important}
.p-l-35{padding-left: 35px !important}
.p-r-35{padding-right: 35px !important}
.p-lr-35{padding-left: 35px !important; padding-right: 35px !important}
.p-t-35{padding-top: 35px !important;}
.p-b-35{padding-bottom: 35px !important;}
.p-tb-35{padding-top: 35px !important; padding-bottom: 35px !important}

.p-40{padding: 40px !important}
.p-l-40{padding-left: 40px !important}
.p-r-40{padding-right: 40px !important}
.p-lr-40{padding-left: 40px !important; padding-right: 40px !important}
.p-t-40{padding-top: 40px !important;}
.p-b-40{padding-bottom: 40px !important;}
.p-tb-40{padding-top: 40px !important; padding-bottom: 40px !important}

.p-45{padding: 45px !important}
.p-l-45{padding-left: 45px !important}
.p-r-45{padding-right: 45px !important}
.p-lr-45{padding-left: 45px !important; padding-right: 45px !important}
.p-t-45{padding-top: 45px !important;}
.p-b-45{padding-bottom: 45px !important;}
.p-tb-45{padding-top: 45px !important; padding-bottom: 45px !important}

.p-50{padding: 50px !important}
.p-l-50{padding-left: 50px !important}
.p-r-50{padding-right: 50px !important}
.p-lr-50{padding-left: 50px !important; padding-right: 50px !important}
.p-t-50{padding-top: 50px !important;}
.p-b-50{padding-bottom: 50px !important;}
.p-tb-50{padding-top: 50px !important; padding-bottom: 50px !important}

.p-60{padding: 60px !important}
.p-l-60{padding-left: 60px !important}
.p-r-60{padding-right: 60px !important}
.p-lr-60{padding-left: 60px !important; padding-right: 60px !important}
.p-t-60{padding-top: 60px !important;}
.p-b-60{padding-bottom: 60px !important;}
.p-tb-60{padding-top: 60px !important; padding-bottom: 60px !important}

.p-70{padding: 70px !important}
.p-l-70{padding-left: 70px !important}
.p-r-70{padding-right: 70px !important}
.p-lr-70{padding-left: 70px !important; padding-right: 70px !important}
.p-t-70{padding-top: 70px !important;}
.p-b-70{padding-bottom: 70px !important;}
.p-tb-70{padding-top: 70px !important; padding-bottom: 70px !important}

.p-80{padding: 80px !important}
.p-l-80{padding-left: 80px !important}
.p-r-80{padding-right: 80px !important}
.p-lr-80{padding-left: 80px !important; padding-right: 80px !important}
.p-t-80{padding-top: 80px !important;}
.p-b-80{padding-bottom: 80px !important;}
.p-tb-80{padding-top: 80px !important; padding-bottom: 80px !important}

.p-90{padding: 90px !important}
.p-l-90{padding-left: 90px !important}
.p-r-90{padding-right: 90px !important}
.p-lr-90{padding-left: 90px !important; padding-right: 90px !important}
.p-t-90{padding-top: 90px !important;}
.p-b-90{padding-bottom: 90px !important;}
.p-tb-90{padding-top: 90px !important; padding-bottom: 90px !important}

.p-100{padding: 100px !important}
.p-l-100{padding-left: 100px !important}
.p-r-100{padding-right: 100px !important}
.p-lr-100{padding-left: 100px !important; padding-right: 100px !important}
.p-t-100{padding-top: 100px !important;}
.p-b-100{padding-bottom: 100px !important;}
.p-tb-100{padding-top: 100px !important; padding-bottom: 100px !important}

.p-150{padding: 150px !important}
.p-l-150{padding-left: 150px !important}
.p-r-150{padding-right: 150px !important}
.p-lr-150{padding-left: 150px !important; padding-right: 150px !important}
.p-t-150{padding-top: 150px !important;}
.p-b-150{padding-bottom: 150px !important;}
.p-tb-150{padding-top: 150px !important; padding-bottom: 150px !important}

.p-200{padding: 200px !important}
.p-l-200{padding-left: 200px !important}
.p-r-200{padding-right: 200px !important}
.p-lr-200{padding-left: 200px !important; padding-right: 200px !important}
.p-t-200{padding-top: 200px !important;}
.p-b-200{padding-bottom: 200px !important;}
.p-tb-200{padding-top: 200px !important; padding-bottom: 200px !important}

.p-250{padding: 250px !important}
.p-l-250{padding-left: 250px !important}
.p-r-250{padding-right: 250px !important}
.p-lr-250{padding-left: 250px !important; padding-right: 200px !important}
.p-t-250{padding-top: 250px !important;}
.p-b-250{padding-bottom: 250px !important;}
.p-tb-250{padding-top: 250px !important; padding-bottom: 200px !important}


/******************************************************************************************************************************/
/*********MARGINS***********/
/******************************************************************************************************************************/
.m-0{margin: 0px !important}
.m-l-0{margin-left: 0px !important}
.m-r-0{margin-right: 0px !important}
.m-lr-0{margin-left: 0px !important; margin-right: 0 !important}
.m-t-0{margin-top: 0px !important;}
.m-b-0{margin-bottom: 0px !important;}
.m-tb-0{margin-top: 0px !important; margin-bottom: 0 !important}

.m-10{margin: 10px !important}
.m-l-10{margin-left: 10px !important}
.m-r-10{margin-right: 10px !important}
.m-lr-10{margin-left: 10px !important; margin-right: 10px !important}
.m-t-10{margin-top: 10px !important;}
.m-b-10{margin-bottom: 10px !important;}
.m-tb-10{margin-top: 10px !important; margin-bottom: 10px !important}

.m-15{margin: 15px !important}
.m-l-15{margin-left: 15px !important}
.m-r-15{margin-right: 15px !important}
.m-lr-15{margin-left: 15px !important; margin-right: 15px !important}
.m-t-15{margin-top: 15px !important;}
.m-b-15{margin-bottom: 15px !important;}
.m-tb-15{margin-top: 15px !important; margin-bottom: 15px !important}

.m-20{margin: 20px !important}
.m-l-20{margin-left: 20px !important}
.m-r-20{margin-right: 20px !important}
.m-lr-20{margin-left: 20px !important; margin-right: 20px !important}
.m-t-20{margin-top: 20px !important;}
.m-b-20{margin-bottom: 20px !important;}
.m-tb-20{margin-top: 20px !important; margin-bottom: 20px !important}

.m-25{margin: 25px !important}
.m-l-25{margin-left: 25px !important}
.m-r-25{margin-right: 25px !important}
.m-lr-25{margin-left: 25px !important; margin-right: 25px !important}
.m-t-25{margin-top: 25px !important;}
.m-b-25{margin-bottom: 25px !important;}
.m-tb-25{margin-top: 25px !important; margin-bottom: 25px !important}

.m-30{margin: 30px !important}
.m-l-30{margin-left: 30px !important}
.m-r-30{margin-right: 30px !important}
.m-lr-30{margin-left: 30px !important; margin-right: 30px !important}
.m-t-30{margin-top: 30px !important;}
.m-b-30{margin-bottom: 30px !important;}
.m-tb-30{margin-top: 30px !important; margin-bottom: 30px !important}

.m-35{margin: 35px !important}
.m-l-35{margin-left: 35px !important}
.m-r-35{margin-right: 35px !important}
.m-lr-35{margin-left: 35px !important; margin-right: 35px !important}
.m-t-35{margin-top: 35px !important;}
.m-b-35{margin-bottom: 35px !important;}
.m-tb-35{margin-top: 35px !important; margin-bottom: 35px !important}

.m-40{margin: 40px !important}
.m-l-40{margin-left: 40px !important}
.m-r-40{margin-right: 40px !important}
.m-lr-40{margin-left: 40px !important; margin-right: 40px !important}
.m-t-40{margin-top: 40px !important;}
.m-b-40{margin-bottom: 40px !important;}
.m-tb-40{margin-top: 40px !important; margin-bottom: 40px !important}

.m-45{margin: 45px !important}
.m-l-45{margin-left: 45px !important}
.m-r-45{margin-right: 45px !important}
.m-lr-45{margin-left: 45px !important; margin-right: 45px !important}
.m-t-45{margin-top: 45px !important;}
.m-b-45{margin-bottom: 45px !important;}
.m-tb-45{margin-top: 45px !important; margin-bottom: 45px !important}

.m-50{margin: 50px !important}
.m-l-50{margin-left: 50px !important}
.m-r-50{margin-right: 50px !important}
.m-lr-50{margin-left: 50px !important; margin-right: 50px !important}
.m-t-50{margin-top: 50px !important;}
.m-b-50{margin-bottom: 50px !important;}
.m-tb-50{margin-top: 50px !important; margin-bottom: 50px !important}

.m-60{margin: 60px !important}
.m-l-60{margin-left: 60px !important}
.m-r-60{margin-right: 60px !important}
.m-lr-60{margin-left: 60px !important; margin-right: 60px !important}
.m-t-60{margin-top: 60px !important;}
.m-b-60{margin-bottom: 60px !important;}
.m-tb-60{margin-top: 60px !important; margin-bottom: 60px !important}

.m-70{margin: 70px !important}
.m-l-70{margin-left: 70px !important}
.m-r-70{margin-right: 70px !important}
.m-lr-70{margin-left: 70px !important; margin-right: 70px !important}
.m-t-70{margin-top: 70px !important;}
.m-b-70{margin-bottom: 70px !important;}
.m-tb-70{margin-top: 70px !important; margin-bottom: 70px !important}

.m-80{margin: 80px !important}
.m-l-80{margin-left: 80px !important}
.m-r-80{margin-right: 80px !important}
.m-lr-80{margin-left: 80px !important; margin-right: 80px !important}
.m-t-80{margin-top: 80px !important;}
.m-b-80{margin-bottom: 80px !important;}
.m-tb-80{margin-top: 80px !important; margin-bottom: 80px !important}

.m-90{margin: 90px !important}
.m-l-90{margin-left: 90px !important}
.m-r-90{margin-right: 90px !important}
.m-lr-90{margin-left: 90px !important; margin-right: 90px !important}
.m-t-90{margin-top: 90px !important;}
.m-b-90{margin-bottom: 90px !important;}
.m-tb-90{margin-top: 90px !important; margin-bottom: 90px !important}

.m-100{margin: 100px !important}
.m-l-100{margin-left: 100px !important}
.m-r-100{margin-right: 100px !important}
.m-lr-100{margin-left: 100px !important; margin-right: 100px !important}
.m-t-100{margin-top: 100px !important;}
.m-b-100{margin-bottom: 100px !important;}
.m-tb-100{margin-top: 100px !important; margin-bottom: 100px !important}

.m-150{margin: 150px !important}
.m-l-150{margin-left: 150px !important}
.m-r-150{margin-right: 150px !important}
.m-lr-150{margin-left: 150px !important; margin-right: 150px !important}
.m-t-150{margin-top: 150px !important;}
.m-b-150{margin-bottom: 150px !important;}
.m-tb-150{margin-top: 150px !important; margin-bottom: 150px !important}

.m-200{margin: 200px !important}
.m-l-200{margin-left: 200px !important}
.m-r-200{margin-right: 200px !important}
.m-lr-200{margin-left: 200px !important; margin-right: 200px !important}
.m-t-200{margin-top: 200px !important;}
.m-b-200{margin-bottom: 200px !important;}
.m-tb-200{margin-top: 200px !important; margin-bottom: 200px !important}

.m-250{margin: 250px !important}
.m-l-250{margin-left: 250px !important}
.m-r-250{margin-right: 250px !important}
.m-lr-250{margin-left: 250px !important; margin-right: 200px !important}
.m-t-250{margin-top: 250px !important;}
.m-b-250{margin-bottom: 250px !important;}
.m-tb-250{margin-top: 250px !important; margin-bottom: 200px !important}

/******************************************************************************************************************************/
/*********POSITIONING***********/
/******************************************************************************************************************************/
.float-left{float: left !important}
.float-right{float: right !important}
.float-none{float: none !important}

.inline, a.inline, a.inline:visited{ display: inline !important; }
.inline-block, a.inline-block, a.inline-block:visited{ display: inline-block !important; }
.block, a.block, a.block:visited{ display: block !important; }

.text-left{text-align: left !important;}
.text-right{text-align: right !important;}
.text-center{text-align: center !important;}

/* used in a few places, like tabs, banners etc */
.relative{position: relative !important;}


/******************************************************************************************************************************/
/*********Typography***********/
/******************************************************************************************************************************/
.font-weight-bold{font-weight: bold !important;}

.font-11, a.font-11, a.font-11:visited, a.font-11:hover, a.font-11:focus{font-size: 11px !important}
.font-12, a.font-12, a.font-12:visited, a.font-12:hover, a.font-12:focus{font-size: 12px !important}
.font-14, a.font-14, a.font-14:visited, a.font-14:hover, a.font-14:focus{font-size: 14px !important}
.font-15, a.font-15, a.font-15:visited, a.font-15:hover, a.font-15:focus{font-size: 15px !important}

