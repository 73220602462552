.booking-complete {
  margin: 30px 0;

  &__pnp-instr {
    padding: 15px;
    background-color: $light-silver;
    text-align: center;

    img {
      float: right;
    }

    &-heading {
      margin-bottom: 15px;
      text-align: left;

      h3 {
        margin: 0;
      }
    }

    &-pnpBarcode {
      width: 200px;
      margin: 5px 50%;
      left: -100px;
      position: relative;
    }

    &-print {
      margin: 10px 50%;
      left: -35px;
      width: 70px;
      position: relative;
    }

    &-powered {
      text-align: right;
      font-size: 11px;
    }
  }
}

@media print {
  footer, .voucher-block, .reservation-summary, .booking-complete__pnp-instr-print {
    display: none;
  }
}
