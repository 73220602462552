.liveavailability{
  &--reservation{
    &__textarea{
      height: 100px;
      width: 100%;
    }
  }

  &__creditcard{
    width: 70px;
    margin-bottom: 7px;
    height: 25px;

    &__border{
      padding: 10px;
      border: 2px solid #FFF;
      outline: 1px solid #E2E2E2;
    }

  }

  &__eft, &__pnp {
    border: 2px solid #FFF;
    outline: 1px solid #E2E2E2;
    padding: 10px;
    margin-bottom: 10px;
    background-color: transparent;

    span{
      float: right;
    }

    &--padding{
      padding: 0 30px;

      &-ol{
        padding-left: 33px;
        padding-top: 30px;
      }

      &-datainputs{
        padding-left: 33px;
      }
    }
  }

  &__giftcardvoucher{
    height: 32px;
    width: 235px;
  }

  &__questionare{
    background-color: white;
    padding: 15px;
    margin: 30px 0;
    border: solid $light-silver 1px;
    margin-bottom: 21px;



    &--inputs{
      margin-bottom: 7px;
    }

    &--label-mobile {
      label {
        margin-bottom: 0;
      }

      span {
        font-size: 12px;
      }
    }

    &--submit{
        text-align: right;
        margin-top: 20px;
    }

    &-mobilenumber-calling-code {
      float: left;
      width: 70px;

      button {
        height: 26px;
        padding: 2px 0 3px 6px;
        width: 70px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }
    }

    &-mobilenumber-number {
      padding-left: 80px;
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  &__display{
    display: none;

    i{
      color: $green;
    }


  }

  &__summary{
    margin-top: 10px;

  }

  &__creditcardimages{
    width: 174px;
    margin-bottom: 7px;
    height: 25px;
  }

  &__CVV{
    width: 92px;
  }

  &__inputs{
    width: 100%;
    margin-bottom: 7px;
    height: 25px;

    &--dropdown {
      padding-top: 0;
    }

  }

  &__vouchercode{
    display: none;
    padding-top: 30px;
  }

  &__terms{
    text-align: center;
    padding-left: 137px;
  }

  &--supportingtext{
    font-size: 12px;
    font-size: 1.2rem;
    
    &__padding {
      padding: 0 15px;
    }

  }

  &__paymentdetails {
    &-button {
      padding: 10px;
    }
  }
}

.cancellations{
  margin-top: 50px;
  border: 1px solid #CCC;
  padding: 10px;
  font-size: 13px;

}


#voucher-code-toggler{
  h2{
    text-decoration: none;

    a{
      text-decoration: underline;
    }
  }
}

@media (max-width: 480px) {
	.col-480-12 {
		width: 100% !important;
		/*text-align: center !important;*/
	}
}

@media (max-width: 1200px){
  /*set width of website*/
}

@media (max-width: 1199px) {
}

@media (max-width: 991px){

  .liveavailability__inputs {
    height: 40px;
  }

  .new-booking__form-width{
    height: 40px;
  }

  .new-booking__payment-cc-month {
    height: 40px;
    width: 55px;
  }

  .booking__payment-cc-year {
    height: 40px;

  }

  .new-booking__payment-cc-year{
    width: 55px;
    height: 40px;
  }

  .clientCCType__select{
    height: 40px;
  }
}

@media (max-width: 767px) {
  .liveavailability--reservation__textarea{
    width: 100%;
  }

  .liveavailability__eft--padding-datainputs{
    padding-left: 0px;
  }

  .liveavailability__giftcardvoucher{
    width: 83%;
    height: 40px;
  }

  .applyBtn{
    position: absolute;
    top: -57px;
    right: 4px;
  }

  .btn-width{
    width: 100%;
  }

  .liveavailability__creditcard{
    height: 40px;
    display: inline-block;
    width: 48%;

  }

  .liveavailability__terms{
    padding-left: 0;

  }
}

@media (max-width: 320px){

  .liveavailability__giftcardvoucher{
    width: 80%;
    height: 40px;
  }
}