.carousel-header {
  margin-left: 15px;
  margin-top: 25px;
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: bold;
  margin-top: 56px;
  color: $black;
  display: block;
}



#location_heading{
  color: $black !important;
  font-size: 22px !important;
}

.voucherville_info_cape_union_mart {
  max-width: 794px;
  color: #606060;
  font-size: 14px;
  font-family: arial, sans-serif;
}

.voucherville_info_cape_union_mart .business {
  padding: 25px;
  padding-top: 20px;
  padding-bottom: 0px;
}

.voucherville_info_cape_union_mart .business .name {
  color: #0080A0;
  font-size: 60px;
  font-weight: bold;
  font-style: italic;
  line-height: 100%;
  margin-bottom: 2px;
}

.voucherville_info_cape_union_mart .business .tag_line {
  color: #0080a0;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}

.voucherville_info_cape_union_mart .business .location {
  color: #606060;
  text-transform: uppercase;
  font-weight: bold;
}

.voucherville_info_cape_union_mart .business .dashed_line {
  text-align: center;
  /*padding-top: 15px;*/
  height: 25px;

}

.voucherville_info_cape_union_mart .voucher {
  padding: 0px 25px 0px 25px;
}


.voucherville_info_cape_union_mart .voucher .name {
  color: #606060;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
}

.voucherville_info_cape_union_mart .voucher .valid_dates {
  color: #cccbcb;
}

.voucherville_info_cape_union_mart .voucher .valid_dates time{
  font-weight: bold;
}

.voucherville_info_cape_union_mart .voucher .terms_and_conditions span {
  font-weight: bold;
}

.voucherville_info_cape_union_mart .voucher .custom_text {
  color: #0080a0;
  font-size: 12px;
  font-weight: bold;
  display: block;
  margin-top: 25px;
}

.voucherville_info_cape_union_mart .voucher .logo {
  float: right;
}