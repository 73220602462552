.floating-header{
  padding: 0px;
  background-color: #f2f2f2;
  outline: solid 1px #e7e7e7;
  z-index: 9999;
  position: fixed;
  top: 1px;
  width: 100%;


  &--container{
    z-index: 9999;
    padding: 5px 0px;

    img{
      width: 35px !important;
      height: 35px !important;
      margin-top: 4px;
    }

    &__links{

      padding-top: 11px;
      color: #dedede;

      &-border{
        border-bottom: solid 1px #b9b9b9;
      }

      div{
        color: $black;
        text-decoration: none;
        font-size: 16px;
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
        padding-bottom: 10px;
      }
      div:hover{
        color: $black;
        text-decoration: none;
        font-size: 16px;
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
      }
      div:visited{
        color: $black;
        text-decoration: none;
        font-size: 16px;
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
      }
    }
  }
  .col-sm-12{
    padding: 0;
  }
  .col-sm-2{
    padding: 0;
  }

  &--buttons{
    padding: 5px 0px;
    outline: solid 1px #E0E0E0;
    background-color: #e7e7e7;

    &__padding{
      padding: 0px 5px;
    }
  }
}

@media (max-width: 991px) {
  .floating-nav__mobile{
    position: fixed;
    bottom: 10px;
    z-index: 9999;
    width: 100%;
    left: 12px;
  }


  .floating-header--container__links{
    padding-top: 18px;
    color: #bababa;
    padding: 18px 0px 5px;
    position: relative;
    left: -12px;
    top: -2px;
  }

  .floating-header--container__tablet-padding{
    padding: 0px;
  }

  .floating-header--container__links div{
    font-size: 13px;
    padding-left: 3px;
    padding-right: 3px;
  }

  .floating-header--buttons__padding a{
    font-size: 11px;
    &:hover{font-size: 11px;}
    &:visited{font-size: 11px;}
  }

  .floating-header--container__links div:hover{
    font-size: 13px;
    
  }

  .floating-header--buttons{
    padding: 5px 5px;
    outline: solid 1px #E0E0E0;
    background-color: #e7e7e7;
    margin-left: -11px;
    margin-top: 7px;
  }

  .floating-header--buttons {
      padding: 5px 5px;
      outline: solid 1px #E0E0E0;
      background-color: #e7e7e7;
      margin-left: -11px;
      margin-top: 7px;
  }
}


@media (max-width: 767px) {
  .floating-nav__mobile {
    position: fixed;
    bottom: 50px;
    z-index: 9999;
    width: 100%;
    left: 12px;
  }
}