.btnbooknow{
  color: #ffffff !important;
  background-color: #6c6c6c;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0;
  padding: 4px 5px 5px 5px;
  margin-top: 0px;
}


.btnbooknow:hover, .btnbooknow:focus, .btnbooknow.focus, .btnbooknow:active, .btnbooknow.active{
  color: $darkgray !important;
  background-color: $white !important;
  font-size: 15px;
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: bolder;
  padding: 4px 5px 5px 5px;
}

.btnenquire{
  color: $black !important;
  background-color: $green;
  padding: 5px 15px;
  font-size: 15px;
  border-radius: 0;
  border: none;
  text-transform: uppercase;
  font-weight: bolder;
}

.btnenquire:hover, .btnenquire:focus, .btnenquire.focus, .btnenquire:active, .btnenquire.active{
  color: $black !important;
  background-color: #a0c330 !important;
  font-size: 15px;
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: bolder;
}


.accomodation--btn{
  background: $light-silver;
  color: $black;
  font-size: 15px;
  font-weight: bold;
  border-radius: 0px;
  text-transform: uppercase;
  font-weight: bolder;
  padding-left: 0;
  padding-right: 0;
}


.accomodation--btn:hover, .accomodation--btn:focus, .accomodation--btn.focus, .accomodation--btn:active, .accomodation--btn.active{

  font-size: 15px;

}


@media (max-width: 768px) {
  .btnenquire{
    font-size: 17px;
  }

  .btnenquire:hover, .btnenquire:focus, .btnenquire.focus, .btnenquire:active, .btnenquire.active{
    font-size: 17px;
  }

  .btnbooknow{
    font-size: 17px;
  }

  .btnbooknow:hover, .btnbooknow:focus, .btnbooknow.focus, .btnbooknow:active, .btnbooknow.active{
    font-size: 17px;
  }

  .accomodation--btn{
    font-size: 15px;
  }

  .accomodation--btn:hover, .accomodation--btn:focus, .accomodation--btn.focus, .accomodation--btn:active, .accomodation--btn.active{
    font-size: 15px;
  }
}

