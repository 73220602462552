.searchmaps {


  h2{
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
  }

  h1{
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    color: black;
  }

  p{
    font-size: 12px;
  }

  &--suburbs{
    padding-left: 20px;
    ul{
      list-style-type: none;
      padding: 0;

    }

    li{
      span{
        font-size: 12px;
        font-size: 1.2rem;
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
      }
    }
  }
}
.search_assist_box_map_box{
  display: block;
  border: solid #C7BBAF 1px;
  width: 97%;
  margin-top: 20px;
  margin-bottom: 29px;

  h4{
    padding: 9px;
    font-size: 1.2rem;
    font-size: 12px;
    margin-bottom: 0px;
    pointer-events: all;
    margin-top: 0;
    display: block;
    font-weight: bold;
    background-color: #A0C080;
    font-weight: bold;
  }


}


.map-toggler{
  li{
    font-size: 1.3rem;
    font-size: 13px;
    a{
      text-decoration: underline;
      font-size: 1.3rem;
      font-size: 13px;
    }
  }
}

.toggle-map-suburbs{
  i{
    float: right;
    margin-top: -20px;
  }
}

@media (min-width: 1200px){
  /*set width of website*/
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {

}

@media (max-width: 767px) {

  .map--padding{
    padding-left: 15px;
    padding-right: 15px;

  }

  .mobile--maps{
    height: 367px;
    overflow-x: scroll
  }
  .mobile--maps::-webkit-scrollbar {
    width: 15px;
  }

  .mobile--maps::-webkit-scrollbar-track {
    background: none;
  }

  .mobile--maps::-webkit-scrollbar-thumb {
    background: #E9E3D3;
    border: 1px solid #653305;
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  }

  .toggle-map-suburbs{
    padding: 9px;
    border: solid #C7BBAF 1px;
    margin-bottom: 5px;
    pointer-events: all;
    margin-top: 0;
    display: block;
    cursor: pointer;

  }

  .map-toggler{
    display: none;
    padding-left: 10px !important;
    margin: 10px 0px;
  }
}

