.tags .tag-item{
  background: transparent !important;
}

.tags .input{
  width: 100% !important;
}


.referafriend{

  text-align: center;

  &--terms{
    padding: 10px 0px 0px 15px;

    a{
      float: right;
      font-size: 13px;
      font-size: 1.3rem;
      color: $bodygray;
      text-decoration: underline;
    }

    a:hover{
      float: right;
      font-size: 13px;
      font-size: 1.3rem;
      color: $bodygray;
      text-decoration: none;
    }

    a:visited{
      float: right;
      font-size: 13px;
      font-size: 1.3rem;
      color: $bodygray;
      text-decoration: underline;
    }


  }


  img{
    width: 100%;
    margin-top: 10px;
  }
  h2{
    margin: 30px 0px;
  }
  &__header-image{
    background-image: url('/img/newlook/refer-a-friend.png');
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;


    div{
      color: $white;
      width: 100%;
      position: absolute;
      bottom: 0px;
      background-color: rgba(000, 000, 000,0.7);
      text-align: center;
      font-size: 20px;
      font-size: 2.0rem;

      h2{
        font-size: 45px;
        font-size: 4.5rem;
        color: $white;
      }

    }
  }
  &--textbox-area{
    background-color: #eaeaea;
    margin-top: 40px;
    text-align: left;
    padding-bottom: 20px;

    &-email-input{
      display: inline-block;
      width: 100%;
    }

    h2{
      margin: 15px 0px;
    }

    input[type=text]{
      width: 100%;
      height: 40px;
      outline: none;
      padding-left: 10px;
    }

    input[type=submit]{
      width: 100%;
      height: 31px;
      color: #ffffff;
      background-color: #b1d247;
      text-transform: uppercase;
      font-weight: bold;
      outline: none;
      border: none;
      margin-left: 10px;
      font-size: 17px;
      font-size: 1.7rem;
      position: relative;
      top: 1px;
      display: inline-block;
      cursor: pointer;
      margin: auto;
    }
  }
  &__alert{
    background-color: transparent;
    border: solid 1px #e7e7e7;
    color: $black;
    position: relative;
    top: -73px;
    left: 188px;
    padding: 2px 5px;

    float: right;
    font-size: 17px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    opacity: 0.5;
    filter: alpha(opacity=20);
    position: absolute;
    top: -3px;
    right: -9px;

    &--incorrect-email{
      border-bottom: dashed red 1px;
    }
  }
}

@-moz-document url-prefix() {
  .referafriend--textbox-area input[type="submit"]{
    top: -13px;
    height: 38px;
  }
}

@media screen and (max-width: 991px){
  .referafriend{


    &__header-image{


      div{
        color: $white;
        width: 100%;
        position: absolute;
        bottom: 0px;
        background-color: rgba(000, 000, 000,0.7);
        text-align: center;
        font-size: 16px;
        font-size: 1.6rem;

        h2{
          font-size: 20px;
          font-size: 2.0rem;
          color: $white;
        }

      }
    }

    &--textbox-area{
      background-color: #eaeaea;
      margin-top: 40px;
      text-align: left;
      padding-bottom: 20px;

      h2{
        margin: 15px 0px;
      }

      input[type=text]{
        width: 85%;
        height: 40px;
        outline: none;
        padding-left: 10px;
      }

      input[type=submit]{
        width: 13%;
        height: 40px;
        color: $white;
        background-color: $green;
        text-transform: uppercase;
        font-weight: bold;
        outline: none;
        border: none;
        margin-left: 10px;
        font-size: 17px;
        font-size: 1.7rem;
        position: relative;
        top: 1px;
      }
    }


  }
}


@media screen and (max-width: 725px){
  .referafriend{



    &--textbox-area{

      input[type=text]{
        width: 80%;
      }

      input[type=submit]{
        width: 19%;
        font-size: 12px;
        font-size: 1.2rem;
        margin-left: -3px;
        top: -1px;
      }
    }


  }
}
