.slimmed--dates--calendar-page {
  width: 100% !important;
}

.date-picker-wrapper{
  background-color: #ffffff !important;
  border-color: $linksblue !important;
}

.date-picker-wrapper .drp_top-bar .apply-btn{
  border-radius: 0px !important;
  border-color: $linksblue !important;
}

.date-picker-wrapper .drp_top-bar .apply-btn.disabled{
  border-radius: 0px !important;
  border-color: $linksblue !important;
  background: none !important;
}

.date-picker-wrapper .month-wrapper{
  border: 1px solid $linksblue !important;
  border-radius: 0px !important;
}

.date-picker-wrapper .drp_top-bar .apply-btn{
  background: none !important;
  color: #726f68 !important;
}

.gap-lines{
  display: none !important;
}

.date-picker-wrapper .gap{
  background-color: #ffffff !important;
}
.date-picker-wrapper{
  z-index: z-index(mobile-date-picker) !important;
}

.month-name{
  text-transform: capitalize !important;
  color: #000000 !important;
}

//.date-picker-wrapper{
//  top: 228px !important;
//}

@media screen and (max-width: 767px){
  //.date-picker-wrapper{
  //  top: 107px !important;
  //  left: 16px !important;
  //  user-select: none;
  //}

  .normal-top > span:first-child {
    display: none;
  }

  .new--date--picker__pos{
    left: 74px !important
  }

  .normal-top .selected-days{
    display: none !important;
  }

  .date-picker-wrapper .drp_top-bar .apply-btn{
    display: none;
  }
}

@media screen and (max-width: 320px){
  //.date-picker-wrapper {
  //    top: 107px !important;
  //    left: 16px !important;
  //    user-select: none;
  //    width: 204px;
  //}

  .normal-top > span:first-child {
    display: none;
  }
  .new--date--picker__pos{
    left: 74px !important
  }
}


