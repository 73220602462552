.mobile__number{
  width: 231px !important;
  position: absolute;
  left: 93px;
  height: 24px;
}

.contact-explanation{
  position: absolute;
  right: 92px;
  top: 5px;
}

.mobile-explanation {
  position: absolute;
  right: 46px;
  top: 5px;
}

.button-padding__height{
  height: 24px;
  border-radius: 0px;
  padding: 0px 0px 6px 6px;
  width: 70px !important;

  &-edit {
    @extend .button-padding__height;
    padding: 3px 0 3px 6px;
  }
}

.pageText{
  width: 310px;
  padding: 0px;
  height: 24px;
  margin-bottom: 7px;


}

@media (max-width: 767px) {

  .pageText{
    width: 100%;
    height: 40px;
  }

  .mobile__number{
    width: 58% !important;
  }

  .contact-explanation {
    position: absolute;
    right: 27px;
    top: -17px;
  }

}

.input__email-enquiry {
  width: 100%;
  padding-left: 5px;
  margin-bottom: 7px;
}

.input-half__name {
  width: 150px;
  float: left;
}

.input-half__left-spacing {
  margin-left: 5px;
}

.input-half__right-spacing {
  margin-right: 5px;
}


@media (max-width: 767px) {
  .input-half__name {
    width: 49%;
  }

  .col-sm-9__width-74{
    padding-right: 25px;
  }

  .questions--mobile__margin{
    margin-top: 0px;
  }

  .input-half__left-spacing {
    margin-left: 1%;
  }
  .input-half__right-spacing {
    margin-right: 1%;
  }
}

// S.G Insert - Remaking bootstrap

@media (min-width: 768px) {
  .col-sm-3__width-26 {
    width: 30%;
    float: left;
    padding-left: 30px;
    padding-right: 15px;


  }

  .col-sm-9__width-74 {
    width: 70%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;

    input [type=text]{
      margin-bottom: 7px;
    }
  }
}
