$z-index: (
        mobile-filters-confirm-dialog : 9000,
        mobile-date-picker            : 7000,
        mobile-filters-menu           : 5000,
        header                        : 2000,
        footer                        : 2000,
        room-type-popup               : 60,
        angular-results               : 50,
        map-container                 : 10
);

@function z-index($key) {
  @return map-get($z-index, $key);
}


