
.email-success--alternative-establishments__mobile-padding a{
  text-transform: none!important;
}


.email-success--alternative-establishments__mobile-padding a:hover{
  text-transform: none!important;
}


.email-success--alternative-establishments__mobile-padding a:visited{
  text-transform: none!important;
}


.successMessage{
  text-align: center;
  color: $bodygray;
  font-size: 1.3rem;
  font-size: 13px;

  h3{
    display: inline-block;
    color: #b1d247;
    font-size: 25px;
    font-size: 2.5rem;
    margin: 0 0 10px 0;
  }

  i{
    display: inline-block;
    color: $black;
    font-size: 25px;
    font-size: 2.5rem;
  }
}

.email-success{
  &__reference-number{

    text-align: right;
    padding:  10px 15px 20px 15px;
  }
  &--referAFriend{
    text-align: center;
    margin-top: 20px;

    &__image{

      padding: 20px 15px;

      img{
        width: 100%;
      }
    }
  }
  &--alternative-establishments{
    text-align: center;
    margin-top: 20px;
  }
}

.similar-establishments {
  &__header {
    text-align: left;
  }
}

.font--right__arrow{
  font-size: 40px !important;
  font-size: 4.0rem !important;
  color: rgb(142, 133, 116)
}

.ctn-button{
  width: 265px;
  font-size: 13px;
}

@media (max-width: 768px) {
  .ctn-button{
    width: 100%;
    font-size: 12px;
  }



  .email-success{
    &--alternative-establishments{
      &__mobile-padding{
        padding: 0;
      }
    }
  }


}
