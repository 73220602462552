.claim-establishment {

  a {
    color: red;
  }

  &--logos {
    margin-bottom: 20px;
    text-align: center;
  }

  &--lists {
    ul {
      padding-left: 20px;
      list-style: none;
    }

    li:before {
      font-family: 'FontAwesome';
      content: '\f00c';
      margin: 0 5px 0 -15px;
      color: #b1d247;
    }

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }

  &--buttons {
    .btn {
      padding: 15px;
    }

    .btn:hover {
      padding: 15px;
    }
  }

  &--links {
    a {
      color: grey;
      text-decoration: underline
    }

    a:hover {
      color: grey;
      text-decoration: none
    }

    a:visited {
      color: grey;
      text-decoration: underline
    }
  }

  &--headings {
    span {
      font-size: 19px;
    }
  }

}

.alternative-establishments {
}

.alternative-establishments h3 {
  font-size: 17px;
  font-size: 1.7rem;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.alternative-establishments a {
  text-decoration: none;
}

.alternative-establishments a:hover {
  text-decoration: none;
}

.alternative-establishments a:visited {
  text-decoration: none;
}

.alternative-establishments--container {
  background-color: #f2f2f2;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  padding-bottom: 15px;
}

.alternative-establishments--container b {
  color: #E32039;
  font-weight: bold;
}

.alternative-establishments--container i {
  color: #b1d247;
  font-size: 16px;
}

.alternative-establishments--container > span {
  margin: 5px 0;
}

.alternative-establishments__reviews {
  color: $darkgray;
  font-size: 12px;
  font-size: 1.2rem;
}

.alternative-establishments__cta span {
  width: 100px;
  margin: auto;
}

.alternative-establishments__cta {
  margin-top: 10px;
}

.alternative-establishments__pricing {
  color: $darkgray !important;
  font-weight: normal !important;
}

.alternative-establishments_marker {
  color: #948383 !important;
  font-weight: normal !important;
}

/***************Collage***************/
.collage {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;

  &--row__margin {
    margin-bottom: 30px;
  }

  &--images {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center center;
  }

}

.explore--breadcrumbs {

}

/***************Pano Small Images Controls*.**************/
.heroImage__pano-controls {
  background: url("/img/vt_icons.png") no-repeat center 85%;

  width: 251px;
  height: 40px;
  position: absolute;
  z-index: 9999;
  top: 70%;
  left: -7px;

  &--small {
    margin: 0 20px;
    background-size: contain;
    z-index: 5;
  }
}

.establishment-heroimage__pano-controls {
  background: url("/img/vt_icons.png") no-repeat center 85%;
  height: 50px;
  width: 432px;
  z-index: 100;
  position: absolute;
  top: 68%;
  left: 28%;

}

.establishment-heroimage__pano-icon {
  background: url("/img/vt_icon_main.png") no-repeat center center;
  position: absolute;
  width: 106px;
  z-index: 100;

  height: 74px;
  left: 45%;
  top: 26%;
}

.establishment-description__room-price {
  text-align: right;
}

.establishment {
  /**********ESTABLISHMENT HEADINGS*****************/
  &-heading {
    h1 {
      margin: 5px 0px 3px;
      display: inline;
      color: black;
      font-weight: bold;
    }

    h5 {
      display: inline;
    }

    h2 {
      color: $pink;
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
    }

    h6 {
      display: inline;
    }

    &__stars {
      display: inline-block;
      cursor: pointer;
    }

    span {
      display: inline-block;
    }

    &__reviewstars {
      text-align: right;
    }

    &__row-padding {
      padding-bottom: 5px;
    }
  }

  .numbering {
    display: inline-block;
    background-color: #92000A;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: bolder;
    font-size: 14px;
    border: solid 1px #ffffff;

  }

  .question {
    font-weight: bolder;
    font-size: 17px;
    color: #92000A;
    display: inline-block;
    padding-left: 5px;
  }

  /***************HERO IMAGES***************/
  &-heroimage {
    &__single {
      height: 400px;
    }

    &__multiple {
      height: 100%;
    }

    &__banner {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }

    &__container {
      padding: 0 15px;
    }

    &__border {
      border: 5px solid white;
      width: 100%;
    }

    &__pano-icon {
    }

    &__pano-controls {
    }

    &__pricing-overlay {
      position: absolute;
      bottom: 1px;
      right: -2px;
      cursor: pointer;
      padding-left: 7px;
    }

    &__pricing-overlay > div {
      padding: 10px 0px;
      margin-right: 2px;
      text-align: center;
      background-color: rgba(61, 61, 60, 0.8);
    }

    &__pricing-overlay span {
      color: white !important;
      display: inline;
      vertical-align: top;
    }

    &__single {
      height: 400px;
      overflow: hidden;
      position: relative;
    }

    &__multiple {
      height: 220px;
      overflow: hidden;
      position: relative;
      padding: 0;
      background-size: cover;
    }

    &__next-arrow {
      right: 20px;
      width: 32px;
      height: 56px;
      position: absolute;
      display: block;
      top: 50%;
      margin-top: -28px;
      background: url('/img/arrow_next.png') no-repeat;
    }

    &__prev-arrow {
      left: 20px;
      width: 32px;
      height: 56px;
      position: absolute;
      display: block;
      top: 50%;
      margin-top: -28px;
      background: url('/img/arrow_prev.png') no-repeat;
    }

    &__target-control {
      position: absolute;
      height: 100%;
      cursor: pointer;
      top: 0;
    }

    &__target-control--prev {
      width: 25%;
      left: 0;
    }

    &__target-control--next {
      width: 25%;
      right: 0;
    }
  }

  /*********ESTABLISHMENT DESC***********/
  &-description {

    padding-top: 10px;
    padding-bottom: 10px;

    &__noimage-padding {
      text-align: left;
      padding-left: 65px;
    }

    &__bookbox {
      text-align: center;
      background-color: #f2f2f2;
      border: solid 1px #E0E0E0;
      padding: 10px;

    }

    &__accentbody {
      color: #4F4E4E;
      font-size: 14px;
      font-size: 1.4rem;

    }

    &__room-type {
      margin-top: 20px;
      margin-bottom: 20px;

      h3 {
        display: inline;
      }

      h4 {
        display: inline;
      }

      h5 {
        display: inline;
      }
    }

    &__roomimagery {
      max-width: 310px;

      img {
        min-width: 280px;
        min-width: 260px;
        height: auto;
      }
    }

    &__roomdetails {
      padding-top: 15px;

      i {
        float: right;
      }
    }

    &__facilities {
      padding-top: 10px;

      ul {
        display: inline;
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: inline
      }

      li::after {
        content: ", ";
      }

      li:last-child::after {
        content: "";
      }
    }

    &__room-price {

      .establishment-heroimage__pricesupport {
        font-size: 13px;
        font-size: 1.3rem;
      }
    }

  }

  /******reviews*******/
  &-reviews {

    &--desc {
      padding-left: 48px;
    }

    .reviews__displayed-review-stars {
      display: inline-block;
    }

    h2 {
      display: inline-block
    }

    h4 {
      display: inline-block;
      font-weight: bold;
      font-style: italic;
      font-size: 17px;
      font-size: 1.7rem;
      position: relative;
      top: 3px;
    }
  }
}

.content-section {
  &__layout-spacing {
    padding: 20px 0 0 0;
  }

  &__header-layout {
    margin-top: 0;
    margin-bottom: 0;
    font-style: normal;
  }
}

.hr__section-divider {
  height: 0px;
  display: block;
  border-top: 1px solid #EFEFEF;
  border-bottom: 1px solid white;
  margin: 5px 0px 0px 0px;
}

// Map & Location Section +
// Old map code
.mapOuter {
  margin-bottom: 10px;
  margin-top: 20px;
}

#map, #mapbox, #openstreetmap {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
}

.location-map {

  &__default-container {
    margin: 10px -15px;

    h1, h2, h3, h4, h5, h6 {
      line-height: 20px;
    }
  }

  &__address-heading {
    margin-bottom: 5px;
  }

}

// Facilities +
.facilities {
  &__default-container {
    margin: 10px -15px;

    h1, h2, h3, h4, h5, h6 {
      line-height: 20px;
    }
  }

  &__list-appearance {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline;

      &:after {
        content: ", ";
      }

      &:last-child:after {
        content: "";
      }
    }
  }

}

// Policy +
.policy {
  &__default-container {
    margin: 10px -15px;

    h1, h2, h3, h4, h5, h6 {
      line-height: 20px;
    }
  }

}

// Call to Action +
.call-action {
  &__booking {
    text-align: center;
    padding: 10px;

    &-horizontal {
      float: left;
      width: 50%;

    }

    &-single {
      margin-left: auto;
      margin-right: auto;
      float: none;
    }
  }
}

// Voucher +-
.voucher {
  &__heading-spacing {
    margin-left: 15px;
    margin-top: 25px;
    margin-bottom: 0px;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
  }

  &__description-small {
    margin-left: 15px;
    font-size: 12px;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.target-control {
  position: absolute;
  height: 100%;
  cursor: pointer;
  top: 0;
}

.target-control--next {
  width: 25%;
  right: 0;
}

.roomPano, .roomPhoto {
  position: relative;
  overflow: hidden;
}

.deal {
  &__headline {
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: $black;
  }

  &__limited {
    display: block;
    margin-bottom: 15px;
  }

  &__terms {
    display: block;
    font-style: italic;
  }
}

.establishment--specials {
  border: solid 1px #E0E0E0;
  padding: 5px 20px;
  margin-bottom: 20px;

  &__triangle {
    width: 0;
    height: 0;
    border-bottom: 13px solid #CA1D35;
    border-left: 17px solid transparent;
    position: relative;
    left: -53px;

    &--last-minute {
      border-bottom-color: $linksblue;
    }

    &--edgars {
      border-bottom-color: $black;
    }
  }

  &__heading {
    width: 330px;
    height: 33px;
    background-color: #E5203C;
    position: relative;
    left: -53px;
    color: #fff;
    padding: 5px 10px 5px 54px;

    h2 {
      margin: 0;
      color: #fff;
      font-size: 20px;
      font-size: 2rem;
      display: inline-block;
    }

    &--last-minute {
      background-color: $linksblue;
    }

    &--edgars {
      background-color: $black;
    }

    &-club {
      position: absolute;
      top: 8px;
      right: 20px;
      display: inline-block;
      margin-left: 10px;
      font-size: 13px;
      font-size: 1.3rem;

      .edgars-club-logo-block {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__description {
    padding: 10px 0px 10px 0px;

    b {
      font-size: 18px;
      color: $black;
    }
  }

  &__bookbtn {
    margin-top: 13px
  }

}

.links--faux-cover {
  width: 310px;
  height: 303px;
}

.articles {

  &--content {
    margin-top: 20px;
    margin-bottom: 40px;

    h3 {
      font-style: italic;
    }

    img {
      width: 100%;
    }
  }

  &--related {
    margin-bottom: 40px;
  }
}

footer h4 {
  margin: 0;
}

footer h5 {
  margin: 0;
}

.click-through {
  pointer-events: none;
}

.links--position {
  padding: 0 !important;
}

.links--full-width {
  width: 100%;
}

.establishment-heading {
  padding-top: 10px;
}

.establishment-heading h1 {
  margin: 5px 0px 3px;
  display: inline-block;
  color: black;
  font-weight: bold;
  margin-bottom: 8px;
}

.establishment-heading h2 {
  color: #e5203c;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 5px;
}

.establishment-heading span {
  display: inline-block;
  margin-top: 15px;
}

.header--gradient__homepage {
  margin-bottom: 0px !important;
}

.establishment-heroimage__pricing-overlay {
  position: absolute;
  bottom: 1px;
  right: -2px;
  cursor: pointer;
  padding-left: 12px;
}

.establishment-heroimage__pano-icon {

  background-image: url('/img/newlook/360-white-new.png');
  position: initial;
  width: 276px;
  z-index: 100;
  height: 363px;
  margin: auto;
}

.establishment-description {
  padding: 50px 0 0 0;
}

.establishment-description__bookbox {
  margin-bottom: 40px;
}

.establishment-bookbox__deal-sticker {
  position: absolute;
  top: 3px;
  right: 32px;
  width: 52px;
  font-size: 1.1rem;
  font-size: 11px;
  background-color: $linksblue;
  color: white;

  i {
    font-size: 1.3rem;
    font-size: 13px;
    margin: 1px 3px 3px 2px;
  }

  &--floating {
    top: 1px;
    right: 141px;
    font-size: 1.0rem;
    font-size: 10px;

    i {
      font-size: 1.2rem;
      font-size: 12px;
      margin: 1px 3px 3px 5px;
    }

    &-single {
      right: 20px;
    }
  }

  &--edgars {
    width: 65px;
  }
}

.establishment-heroimage__pano {
  height: 390px;
}

.establishment-heading span {
  margin-top: 0px;
}

#reviewsScrollToDiv {
  position: relative;
  top: -50px;
}

// -----------------------------------
//
// MEDIA QUERIES START HERE
//
// -----------------------------------

@media (max-width: 991px) {
  .establishment-description__mobile__margin {
    margin-top: 179px;
  }

  .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    width: 30px;
    height: 30px;
  }

  .establishment-heroimage__single {
    height: 400px;
  }
}

@media (max-width: 767px) {

  .initSlick__appearance {
    height: 280px;
    position: relative;
  }

  .draggable {
    height: 230px;
  }

  .accomodation-padding {
    padding: 0px !important;

  }

  .slick-prev {
    z-index: 999 !important;
  }

  .slick-next {
    z-index: 999 !important;
  }

  .establishment-heroimage__container {
    padding: 0;
    margin-left: 0px;
    margin-right: 2px;
  }

  .establishment-heroimage__pricing-overlay {
    position: absolute;
    bottom: 30px;
    right: -2px;
    cursor: pointer;
    padding-left: 7px;
  }

  .content-section__layout-spacing {
    padding-bottom: 20px !important;

  }

  .establishment-desc-toggle {
    display: none;
  }

  .establishment-extras {
    i {
      float: right;
      position: relative;
      top: -24px;
    }
  }

  .establishment-description__mobile__margin {
    margin-top: 683px;
  }

  .establishment-heading {
    h1 {
      font-size: 24px;
      font-size: 2.4rem;

    }
  }

  .establishment-heroimage__single {
    height: 200px !important;
  }

  .establishment-heading__reviewstars {
    text-align: left;
  }

  #map, #mapbox, #openstreetmap {
    height: 200px !important;
  }

  .carousel-header {

    font-size: 18px !important;
    font-size: 1.8rem !important;
  }

  .establishment-description__room-type {
    margin: 10px 0 0 0;
  }

  .establishment-description__room-type {
    margin: 10px 0 10px 0;
  }

  .establishment-bookbox__deal-sticker--floating {
    right: 208px;
  }

  .establishment-bookbox__deal-sticker--floating-single {
    right: 95px;
  }

}

// This is for flex-slider navigation icons being clipped off
.flex-direction-nav a {
  height: 50px !important;
  color: #FFF !important;
}

.initSlick__container {
  .slick-prev:before, .slick-next:before {
    color: #FFF;
    opacity: 1;
  }

  .slick-prev, .slick-next {
    height: initial;
  }
}

@media screen and (max-width: 480px) {
  #initSlick, .initSlick-multiple {
    .slick-prev:before, .slick-next:before {
      font-size: 60px !important;
      color: #FFF;
      opacity: 1;
    }

    .slick-prev, .slick-next {
      margin-top: -30px !important;
    }

    .slick-prev:before, .slick-next:before {
      font-size: 50px !important;
    }
  }

  #voucherville_container {

    .slick-next {
      right: -25px !important;
    }

    .slick-prev {
      left: -25px !important;
    }

  }
}

#initSlick, .initSlick-multiple {
  .slick-slider {
    margin-bottom: 15px !important;
  }

  .slick-prev:before {
    content: '\f104';
  }

  .slick-next:before {
    content: '\f105';
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .initSlick__container {

    .slick-prev {
      left: 5px !important;
    }

    .slick-next {
      right: 20px !important;
    }

    .slick-prev:before, .slick-next:before {
      font-size: 80px !important;
      color: #FFF;
      opacity: 1;
    }

    .slick-prev, .slick-next {
      margin-top: -40px !important;
    }
  }

  .slick-prev {
    z-index: 999;
  }

  .slick-next {
    z-index: 999;
  }
}

@media (max-width: 1025px) {
  .initSlick__container {
    .establishment-heroimage__container {
      padding: 0 0px;
      margin: 0;
    }
  }

  .establishment-heroimage__container {
    padding: 0 0px;
    margin: 0;
  }
}

@media (max-width: 991px) {
  .initSlick__container {
    .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
      width: 20px;

    }
  }

  .establishment-heroimage__container {
    padding: 0 0px;
    margin: 0;
  }
}

.slick-initialized,
.slick-slide:first-child {
  display: block;
}

.rest-of-vouchers {
  display: none;
}

.results__search {
  border: none;
  background-color: #B1D247;
  padding: 4px 7px;
  margin-top: -7px;
  left: 2px;
  position: relative;
  top: 0px;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .links--faux-cover {
    width: 355px;
  }
}

@media screen and (min-width: 992px) {
  .links--max-position {
    margin-top: -6%;
  }
}