@media screen and (-webkit-min-device-pixel-ratio:0) {
  /* Safari 5+ ONLY */

}
.result--details{
  background-color: $white !important;

}


.hide--initialSlickImage{
  overflow: hidden;
  position: absolute;
}


.review--stars{
  vertical-align:top;
  height: 13px !important;
  margin-top: 2px;
}



.image--ios{
  width: 187px !important;
}
#myAlternativeContentDefault{
  height: 361px;
}
.result--details:hover{
  background-color: rgb(251,249,250) !important;
}
#outer_content_div{

  height: 107px !important;
  overflow: hidden !important;
}
.room-name-liveavailability{
  width: 117px;
  margin-bottom: 3px;
  text-transform: capitalize;
  position: relative;
  top: -4px;
}



.slick-townspage{
  &--container{
    position: relative;


    &__left{
      position: absolute;
      top: 50px;
      z-index: 99;
      color: white;
      left: 10px;
      font-size: 50px;
      cursor: pointer;
    }

    &__right{
      position: absolute;
      top: 50px;
      z-index: 99;
      color: white;
      right: 10px;
      font-size: 50px;
      cursor: pointer;
    }
  }
}



/****************************RESULTS.JSP PAGE BROKEN UP INTO SECTIONS***********************************/
.breadcrumbs__location{
  h1{
    text-align: left;
    @include base-font(14px, 1.4rem);
    line-height: 0.8em;
    color: $pink;
    font-weight: bold;
    text-transform: uppercase;
    margin: 25px 0px 5px 0px;

  }

  h2{
    font-weight: bold;
    color: black;
    text-align: left;
    margin: 0px;
  }
}
/***************TABS****************/
.tabs--gradient{
  background-color: #F1EDE4;
  background-image: url("/img/tab_top_grad.png");
  background-repeat: repeat-x;
  background-position: bottom;
  border: none;
  border-bottom: solid 1px #C7BBAF;
  color: $dark-brown;
  height: 53px;

  &__bottom{
    height: 33px;
    //background-image: url("/img/tab_bottom_grad.png");
    //position: relative;
    //background-repeat: repeat-x;
  }

  &__border{
    //border-left: solid 1px #C7BBAF;
    //border-right: solid 1px #C7BBAF;
  }
}
.nav-tabs{
  border-bottom: solid 1px #C7BBAF;
}

/*******Bootstrap override tabs**************/
.nav-tabs>li {
  float: left;
  margin-bottom: -3px;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
  background-color: transparent;
  border-top: 2px solid $pink;
  border-left: 1px solid $silver;
  border-right: 1px solid $silver;
  color: $pink;
  border-bottom-color: #fff;
  cursor: default;
  font-weight: bold;
  @include base-font(14px, 1.4rem);
  position: RELATIVE;
  top: -1px;
}
.nav-tabs > li > a{
  border-radius: 0px;
  border: solid 1px #C7BBAF;
  border-bottom: none;
  background-color: #fbf9fa;
  padding: 4px 20px 5px 20px;
  @include base-font(14px, 1.4rem);
  margin-top: 25px;
  color: $darkgray;
  position: relative;
}

/***************SUMMARY CONTENT***************/
.travel--guide{
  padding-left: 30px;
  padding-right: 5px;

  &__link{
    text-align: right;
    padding-bottom: 0px;
  }


  &__summary{
    margin: -13px 0px 10px 0px;
    @include base-font(13px, 1.3rem);
    padding: 10px 13px 15px 12px;

    span{
      font-weight: bold;
      @include base-font(13px, 1.3rem);
      line-height: 200%;
    }
  }
}
.travel--video{
  padding-right: 30px;
  padding-left: 0px;

  img{
    max-width: 100%;
  }
  &__image{
    padding: 0px;
    padding-right: 15px;

  }

  &__text{
    padding-right: 0;
    padding-left: 25px;
  }
}

/*****1.Filtering-section******/
.filtering{


  &--datesbackground{
    background-color: #fcfafb !important;
  }



  &--background__margin{
    margin-top: 10px;
    margin-bottom: 20px;
  }


  &--headings__background{
    background: repeat-x bottom $light-silver;
    font-size: 14px;
    font-size: 1.4rem;
    padding: 3px 0px 4px 10px;
    width: 100%;
    margin-top: 5px;
    font-weight: bold;
  }


  &--background__colour{
    background-color: #ffffff;
    padding: 15px;
    border: solid 1px $light-silver;
    ul{
      padding-left: 30px;

      li{
        a{
          color: #6c6c6c;
        }
        a:hover{
          color: #3f97ff;
        }

        b{
          color: $pink;
        }
      }
    }
  }

  input[type=text]{
    height: 25px;
    width: 120px;
    text-align: left;
    @include base-font(13px, 1.3rem);
    padding-left: 5px;
  }

  &--calendar__headings{
    @include base-font(14px, 1.4rem);
    font-weight: bold;
  }

  input[type=submit]{
    -webkit-appearance: button;
    cursor: pointer;
    line-height: 0;
    height: 19px;
    background-color: $green;
    float: right;
    padding: 15px !important;
    color: $white;
    border-radius: 0;
    font-weight: bold;
    width: 219px;
  }

  input[type=submit]:hover{
    background-color: $green;

  }

}

/*****1.Map-section******/
.map{
  &--container{
    height: 660px;
    margin-top: 10px;

    &__width{
      width: 374px;
    }
  }
}

/******************RESULTS SECTION**************************/
.result{

  &--images{
    padding: 0px;
    z-index: 9;

    &__jstl{
      padding-right: 15px;
      padding-left: 15px;
    }

    a{
      img{

      }
    }
  }

  &--ordering{
    padding: 0px 15px 9px;
    position: relative;
    margin-top: -26px;
    z-index: 2;

    select{

      -webkit-appearance: none;
      -moz-appearance: none;
      width: 47%;
      height: 29px;
      outline: none !important;
      position: relative;
      top: 44px;
      z-index: 99;
      padding-left: 7px;
      float: right;
      height: 23px;
      right: 12px;
      font-size: 11px;
      text-transform: uppercase;
      border-radius: 0;
      background-color: transparent;
      border: none;

      &::-ms-expand {
        display: none;
      }

    }

    i{
      position: absolute;
      right: 38px;
      top: 48px;
      z-index: 1;
    }
  }

  &--minus__zindex{
    z-index: -99;
  }

  &--bar{
    background-color: #ffffff;
    height: 30px;
    padding: 6px;
    font-size: 13px;
    font-size: 1.3rem;
    margin-top: -2px;
    color: #000;
    border-bottom: solid 1px #ebebeb;
    margin-bottom: 10px;

    b{
      font-size: 12px;
    }
  }

  select{
    color: #000000;
    position: absolute;
    right: 19px;
    top: 9px;
  }


  &--details{
    margin: 0px 0px 30px;
    padding: 10px 0px;

    img{
      display: block;
      padding: 4px;
      margin-bottom: 20px;
      line-height: 1.42857143;
      max-width: 100%;
    }


    &__zindex{
        z-index: 99;
      overflow: hidden;
    }
  }

  &--pager{
    padding: 8px;
    margin: 10px 0 10px 0;
    text-align: center;
    font-size: 15px;
    font-size: 1.5rem;

    span{
      position: relative;
      top: 8px;
    }
    b{
      position: relative;
      top: 8px;
    }
  }

  &--type{
    margin-top: 5px;

    span{
      font-weight: bold;
    }
  }

  &--price {
    padding-right: 5px;

    .price{
      padding-top: 15px;

      .pricesupport {
        font-size:12px;
        font-size:1.2rem;
      }

      .pricelarge {
        font-size:20px;
        font-size:2.0rem;
      }

    }
  }
}

/*******FLEX SLIDER START*********/
.flex-slider {
  margin-bottom: 10px;
}
.flex-box {
  position: relative;
  width: 100%;
}
.flex-box:before {
  content: "";
  display: block;
  padding-top: 68%;
}
.flex-box__content {
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &--loading {
    background-image: url('/img/newlook/small-grey-R.png');
    background-size: auto;
  }
}
.flex-box__slide-box {
  position: relative;
  width: 100%;
}
.flex-box__slide-box:before {
  content: "";
  display: block;
  padding-top: 68%;
}
.flex-box__slide-content {
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  padding: 0;
}
.center-cropped {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*******FLEX SLIDER END*********/


.location--information{

  &__headings{
    z-index: 350;


    a{
      @include base-font(14px, 1.4rem);
      font-weight: bold;
      text-decoration: none;
    }


    a:visited{
      @include base-font(14px, 1.4rem);
      font-weight: bold;
      text-decoration: none;
    }


    a:hover{
      @include base-font(14px, 1.4rem);
      font-weight: bold;
      text-decoration: underline;
    }

    a:focus{
      @include base-font(14px, 1.4rem);
      font-weight: bold;
      text-decoration: none;
    }
  }


  &__text {

    @include base-font(12px, 1.2rem);
    padding-right: 0;


    h4{
      color: #717171;
      font-style: italic;
      font-weight: bold;
      @include base-font(13px, 1.3rem);
      padding-top: 5px;
      margin-top: 0px;
    }

    img{
      display: inline;
      padding: 0px;
      margin-bottom: 0;
      line-height: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      height: 13px;
      margin-top: 2px;
    }

    span{
    }
  }

  &__reviews {
    margin-top: 5px;
  }
}
.special--container{
  padding-left: 20px;
  margin-top: -56px;
  position: absolute;
  top: 170px;
  right: -12px;

  &__information{
    background-color: $pink;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    width: 100%;
    color: white;
    //@include base-font(10px 1.0rem);
    font-weight: bold;
    padding: 5px 13px 5px 0;
    text-align: right;
    @include base-font(12px, 1.2rem);
  }

  &__arrow-right{
    width: 0px;
    height: 0px;
    border-top: 5px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 12px solid $pink;
    position: absolute;
    top: -5px;
    right: 15px;
  }
}

/*******************Suburbs**********************/
.suburbs{

  background-color: #FBF9FA;
  padding-left: 25px;
  margin-top: 50px;

  &--top__gradient{
    background-color: #F1EDE4;
    color: $dark-brown;
    padding: 19px 15px 0px 15px;
    border-top: solid 1px #AB864F;
    margin-top: 20px;
    background-image: url("/img/bottom_content_section_grad.jpg");
    background-repeat: repeat-x;
    background-position: top;
  }

  &--heading{

    h4{
      @include base-font(16px, 1.6rem);
      color: $black;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 25px;
      font-style: normal;
    }
  }
}
.small_heading{
  pointer-events: none;
  cursor: default;

  h5{
    pointer-events: none;
    padding: 0px 0px 6px 0px;
    margin-top: 20px;
    font-weight: bolder;
    color: #717171;
    font-style: normal;

  }

  &--first {
    h5 {
      margin-top: 10px;
    }
  }
}
.suburbs-toggle{
  @include base-font(13px, 1.3rem);

  &--links{
    padding-left: 40px;
  }
  a{
    @include base-font(13px, 1.3rem);
  }

  a:visited{
    @include base-font(13px, 1.3rem);
  }

  a:focus{
    @include base-font(13px, 1.3rem);
    text-decoration: underline;
  }

  a:hover{
    @include base-font(13px, 1.3rem);
    text-decoration: underline;
  }
}
.live--availability__message-enquiry-available i{
  color: #b1d247;
}
.bold-weight{
  font-weight: bold;
  color: #cccccc;
}

.live--availability{
  padding: 0px 15px;
  @include base-font(13px, 1.3rem);
  z-index: 1000;

  &__message{
    text-align: center;
    margin-top: 5px;

    &-available{
      i{
        color: $bodygray;
      }
    }

    &-enquiry{
      i {
        color: $bodygray;
      }
    }
  }

  &__background{
    background-color: #EAE5DA;
    @include base-font(13px, 1.3rem);
  }

  &__adultsandchildren{
    position: relative;
  }

  &__images{
    display: inline-block;
    font-size: 12px;
    font-size: 1.2rem;
    padding: 0px;

    img{
      display: inline-block;
      padding: 0px;
      margin-bottom: 0px;
      line-height: 0;
      background-color: transparent;
      border: none;
      border-radius: 0px;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }

  &__price{
    text-align: right;
    @include base-font(13px, 1.3rem);
    padding: 0px;

    b{
      color: $pink;
    }

    img{
      display: inline-block;
      padding: 0px;
      margin-bottom: 0px;
      line-height: 0;
      background-color: transparent;
      border: none;
      border-radius: 0px;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }

  &__padding{
    padding: 10px;
  }

  &__rowpadding{
    padding-bottom: 30px;
  }
}
/********tool tip height*******/
.a_for_pagination{
  outline: solid 1px #3f97ff;
  padding: 0px 11px;
  font-size: 24px;
  background-color: #3f97ff;
  color: white;
  font-weight: bolder;
  display: inline-block;
  line-height: 1.2em;
  position: relative;
  top: 4px;
}
.a_for_pagination:hover{
  outline: none;
  color: $white;
}
.clear-dates{
  float: right;
  top: 37px;
  position: relative;
  right: -92px;
}
/***JAVASCRIPT DONT UPDATE**/
.rooms--units
{
  &--initially-hidden{
  display: none;
}
  &__background{
    border-bottom: dashed 1px #efefef;
    margin-bottom: 5px !important;
  }
}


/******Shepard training*******/
.shepherd-element{
  z-index: 9999;
}
.shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons li .shepherd-button{
  background: #b1d247 !important;
  text-decoration: none !important;

}
.shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons li .shepherd-button:hover{
  background: #B1D248 !important;
  text-decoration: none !important;
}
.shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons li .shepherd-button.shepherd-button-secondary{
  background: #eee !important;
  color: #888;
  text-decoration: none !important;
}


/*******Advanced Filtering********/
.advanced-filtering{
  min-height: 74px;

  &__zindex{
    z-index: 9;
  }

  background-color: #fefefe;
  outline: solid #ebebeb 1px;
  margin-top:  30px;
  margin-bottom: 10px;
  font-size: 12px;
  color: $filtering-gray;

  &__icon{
    position: relative;
    top: -4px;
    cursor: pointer;
  }
  &__popover{
    display: none;
    position: absolute;
    width: 104px;
    top: -10px;
    font-size: 12PX;
    background-color: #e6e6e6;
    text-align: center;
    border-radius: 4px;
    left: 32px;
    padding: 3px;
    color: #000000;
    z-index: 99;



    &-roomtype{
      width: 200px;
      background-color: #e6e6e6;
      color: #000000;
      border-radius: 4px;
      position: absolute;
      z-index: 1;
      left: 135px;
      padding: 5px 6px;
      display: none;

      .arrow-left {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid #e6e6e6;
        position: absolute;
        left: -8px;
        top: 14px;
      }

    }
    .arrow-left {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #e6e6e6;
      position: absolute;
      top: 11px;
      left: -7px;
    }

  }
  &--button__padding{
    padding-right: 25px;
  }
  &--chev__dropdown{
    width: 80px;
    height: 35px;
    position: absolute;
    left: -49px;
    z-index: 9999;
  }
  &__clear-dates{
    border: solid 1px #ccc;
    width: 100%;
    display: block;
    margin-left: -13px;
    padding: 4px 5px;
    margin-top: 3px;
    text-align: left;
    cursor: pointer;
    height: auto !important;
    text-transform: uppercase;
    font-size: 11px;
    font-size: 1.1rem;

    span{
      float: right;
      font-size: 16px;
      margin-top: -2px;
      font-size: 13px;
      font-size: 1.3rem;
      margin-right: 3px;

    }
  }
  &--spinner{
    width: 94.6%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.9);

    img{
      left: 47%;
      position: relative;
      top: 60px;
      width: 80px;
    }

    i{
      left: 47%;
      position: relative;
      top: 60px;
      width: 80px;
      color: $pink;
    }
  }
  &__hr-padding{
    padding: 0px 30px;
  }
  &__show-overflow{
    overflow: visible !important;
  }
  &__scrolling{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &__radio-showmore-trigger{

    i{
      margin-left: -47px;
      margin-top: 14px;
      cursor: pointer;
      font-size: 17px;
    }

  }
  &__accomodation--btn{
    font-size: 11px;
    font-weight: 100;
    margin: 12px 0px 10px -10px;


    i{
      float: right;
      font-size: 13px;
      padding-right: 6px;
      margin-top: 0px;
    }

    @-moz-document url-prefix() {
      i{
        float: right;
        font-size: 13px;
        font-size: 1.3rem;
        padding-right: 6px;
      }
    }
  }
  &__accomodation--btn:hover{
    font-size: 11px;
    font-weight: 100;
    margin: 12px 0px 10px -10px;
  }
  &__radio-showmore-content{
    .advanced-filtering__input{
      padding-top: 15px;
    }
  }
  &__headings{
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 500;
    padding-left: 30px;
    margin-top: 13px;

    &-dates{
      margin-top: 8px;
    }
  }
  &__row--spacing{
      padding-top: 10px;
    }
  &__input{
      padding-left: 20px;
      padding-right: 5px;
      position: relative;
      margin-top: 4px;


      ::-webkit-input-placeholder {
        font-size: 12px;
      }

      :-moz-placeholder { /* Firefox 18- */
        color: $filtering-gray;
        font-size: 12px;
      }

      ::-moz-placeholder {  /* Firefox 19+ */
        color: $filtering-gray;
        font-size: 12px;
      }

      :-ms-input-placeholder {
        color: $filtering-gray;
        font-size: 12px;
      }

      input[type="text"]{
        width: 100%;
        padding: 3px;
      }

      input[type="checkbox"]{
        width: 19px;
        height: 19px;
        margin-top: 0px;
        margin-right: 8px;
      }


      &--specials{
      input[type="checkbox"]{
        float: right;
      }

      &--images img{
        position: initial;
      }
    }

      select{
        width: 100%;
        padding: 3px;
        color: #717171;
        font-size: 12px;
        margin-top: -1px;
      }

       img{
         position: absolute;
         right: 8px;
         top: 5px;
         width: 23px;
       }

      span{

        position: relative;
        top: -4px;
        img{
          position: inherit;
        }
      }


    a{
      position: absolute;
      margin-left: 5px;
      margin-top: 1px;
      color: #726f68;

      &:hover{color: #726f68;}
      &:visited{color: #726f68;}


      i{
      }
    }


    &-dropdown{
      float: right;
      color: #3f97ff;
      font-size: 17px;
      cursor: pointer;
      margin-top: -3px;
    }


    }
  &__price{
    span{
      border: solid 1px;
      padding: 0px 3px;
      font-size: 11px;
      text-align: center;
      border: none;
    }

    &--left{
      float: left;
      padding: 0px 3px;
      font-size: 11px;
      text-align: center;
      color: #c2c2c2;

    }

    &--right{
      float: right;
      padding: 0px 3px;
      font-size: 11px;
      text-align: center;
      color: #c2c2c2;
    }
  }
  &__accommodation-type{
    background-color: #fcfafb;
    outline: solid #b4abae 1px;
    position: absolute;
    width: 100%;
    z-index: 999;
    padding: 5px 0px;

    &--padding{
      padding-left: 5px;
    }
  }
  &__span--reset{
    top: 0px !important;
  }
  &__alerts{
    float: left;
    margin-right: 10px;
    padding: 1px 17px;
    color: #726f68;
    background-color: transparent;
    border-color: #726f68;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.8;

    .close{
      right: -13px;
      top: 0px;
      opacity: 1;
    }

    &--dates{
      color: #726f68;
      background-color: transparent;
      border-color: #726f68;
      width: 200px;
      margin: auto;
      margin-top: 10px;
      padding: 5px;
      text-align: center;
      font-size: 11px;

      .close{
        position: relative;
        right: -2px;
        top: -2px;
        opacity: 1;
        display: block;
        color: #726f68;
      }

    }
  }


  &--mobile-specials{
    padding: 3px;
    outline: solid 1px #cccccc;
    width: 109px;
    float: right;
    padding-right: 30px;
    margin-right: 16px;
    margin-top: 0;
    position: relative;
    top: 30px;
    z-index: 999;
    color: $black;
    text-align: center;
    display: block;
    font-weight: bold;
    background-color: #ebebeb;
    cursor: pointer;

    i{
      font-size: 18px;
      font-size: 1.8rem;
      text-align: right;
      float: right;
      position: absolute;
      right: 9px;
      top: 5px;
      color: $pink;
    }
  }
}
@-moz-document url-prefix() {
  .advanced-filtering__clear-dates{
    margin-left: -20px;
  }
}
#advanced-filtering__showmore-content{
  display: none;
  height: 175px;
}
.advanced-filtering__input--images img{
  position: initial;
  position: relative;
  top: -8px;
  right: 7px;
  margin-right: -5px;
  width: 19px;
}
/*******Razor Slider*****Price****/
#showExtraPropsHideShow{
  z-index: 99;
}
.rzslider{
  margin: 14px 0 15px 0 !important;

}
.rzslider .rz-pointer{

  top: -12px !important;
  z-index: 3 !important;
  width: 15px !important;
  height: 15px !important;
  cursor: pointer !important;
  background-color: #c2c2c2 !important;
  -webkit-border-radius: 16px !important;
  -moz-border-radius: 16px !important;
  border-radius: 16px !important;


}
.rzslider .rz-pointer:after{
  position: absolute !important;
  top: 2px !important;
  left: 2px !important;
  width: 11px !important;
  height: 11px !important;
  background: #ffffff !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 16px !important;
  content: '' !important;


}
.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 2px !important;
  background: #c2c2c2 !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  top: 10px;
}
.rzslider .rz-bubble.rz-limit{
  display: none !important;
}
.rzslider .rz-bubble{
  display: none !important;
}
.result-set__min-height{
  min-height: 600px;
}
.fa-question-circle{
  color: #b5b5b5;

  &__position{
    position: relative;
    top: 15px;
  }
}
.mCSB_inside > .mCSB_container{
  margin-right: 0px !important;
}
/********Helful links********/
.helpful-links {


  &__hover-state{

    &:hover{
      color: $pink;
      i{
        color: $pink;
      }
    }

    &--maps:hover{
      color: $pink;
    }

    &--travel-guide:hover{
      color: $pink;
    }
  }



  text-align: center;
  margin-top: 20px;
  i{
    font-size: 24px;
    color: #726f6c;
    margin-bottom: 5px;
  }

  a{
    color: $grey;
    text-decoration: none;


    &:visited{
      color: $grey;
      text-decoration: none;
    }


    &:hover{
      color: $pink;
      text-decoration: none;
    }
  }

  &--specials__normal {
    background-image: url('/img/tab-icons/special-normal.png');
    background-repeat: no-repeat;
    width: 36px;
    height: 15px;
    background-size: contain;
    margin: auto;
    background-position: center center;
  }

  &--attractions__normal {
       background-image: url('/img/tab-icons/attraction-normal.png');
       background-repeat: no-repeat;
       width: 29px;
       height: 30px;
       background-size: contain;
       margin: auto;
      background-position: center center;

     }

  &--travelguide__normal {
    background-image: url('/img/tab-icons/tour-guide-normal.png');
    background-repeat: no-repeat;
    width: 29px;
    height: 30px;
    background-size: contain;
    margin: auto;
    background-position: center center;
  }



}
.rzslider .rz-bar.rz-selection{
  background: $linksblue !important;
}
/*slimmed filtering*/
.slimmed-filtering{
  padding-top: 10px;
  padding-left: 15px;
  font-size: 14px;
  font-size: 1.4rem;

  &--divider{
    padding-left: 30px;

  }


  &--select{
    position: relative;
    select{
      width: 100%;
      margin-left: 25px;
      border: none !important;
      outline: none !important;
      height: 30px;
      font-size: 14px;
      position: relative;
      top: -6px;
      -webkit-appearance: none;
      background-color: transparent;
      z-index: 5;
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: '';
      &::-ms-expand{
        display: none;
      }
    }
    i{
      position: absolute;
      top: 3px;
      right: 4px;
      z-index: 1;

    }
  }


  &__padding--reset{
    padding-left: 0px !important;

    i{

      position: absolute;
      right: 20px;
      top: 4px;
      font-size: 20px;
      color: $linksblue;
    }
  }



  &--divider hr{
    margin: 10px 0px 10px 0px;
  }

  &__bottomBorder{
    border-top: solid 1px #eee;
    padding-top: 20px;
  }
  &--border{
    border-right: solid 1px #ebebeb;
  }
  &--calenders{

    &__ie{
      position: absolute;
      top: 3px;
      z-index: 0;
      left: 17px;
      width: 90%;
      border: none !important;
      outline: none !important;
      &-div{
        outline: none !important;
        border: none !important;
      }
    }






    &__imputs{
      position: relative;
      div{
        outline: solid #ebebeb 1px;
      }

      i{
        position: absolute;
        top: 5px;
        left: 45%;
        font-size: 20px;
        color: #3f97ff;
      }
    }


    &__close{
      position: absolute;
      right: 34px;
      top: 2px;
      z-index: 9999;

      i{
        color: #726f68;
        font-size: 15px;
      }

      &:hover{
        color: $linksblue;
      }
    }

    position: relative;
    ::-webkit-input-placeholder {
       color: #717171;
       padding-left: 5px;
      font-size: 14px;
      font-size: 1.4rem;
    }
    :-moz-placeholder { /* Firefox 18- */
       color: #717171;
       padding-left: 5px;
      font-size: 14px;
      font-size: 1.4rem;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
       color: #717171;
       padding-left: 5px;
      font-size: 14px;
      font-size: 1.4rem;
    }
    :-ms-input-placeholder {
       color: #717171;
       padding-left: 5px;
      font-size: 14px;
      font-size: 1.4rem;
    }
    img{
      position: absolute;
      right: 18px;
      top: 4px;
    }
    input{
      height: 28px;
      padding-left: 20px;
      width: 48%;
      border: none;
      outline: none;
      display: inline-block;
      background-color: transparent;
      z-index: 9;
      cursor: pointer;
    }
    &__border-right{
      border-right: solid 1px #ebebeb;
      padding-left: 6px;

      img{
        right: 18px;
      }
    }
  }
  .advanced-filtering__accomodation--btn {
      font-size: 11px;
      font-weight: 100;
      margin-top: 0px 0px 10px -10px;
      background-color: #F8F8F8;
  }
  &--number-guests{
    height: 30px;
    padding-top: 5px;
    padding-left: 10px;
    position: relative;
    cursor: pointer;
    padding-right: 5px;

    span{
      float: right;
    }
    &__popup{
      background-color: #ffffff;
      padding: 13px 0px 13px 0px;
      z-index: 9;
      position: absolute;
      top: 39px;
      left: 4px;
      border: solid 1px #ccc;
      display: none;
      width: 150px;
      text-align: center;

      .arrow-up {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ccc;
        position: absolute;
        left: 123px;
        top: -10px;


        &__inner{
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #ffffff;
          position: relative;
          left: -8px;
          top: 2px;
        }
      }



    }
    &__buttons{

      div{
        display: inline-block;
        margin-top: -6px;
        margin-left: 3px;
      }

      &-extra{
        border: solid $linksblue 1px;
        padding: 5px;
        width: 23px;
        height: 23px;
        text-align: center;
        vertical-align: middle;
        position: relative;
        top: 1px;
        color: $linksblue;

        i{
          position: absolute;
          top: 5px;
          right: 4px;
        }

        span{
          position: absolute;
          top: -1px !important;
          right: -5px !important;
          font-weight: normal;
          font-size: 16px;
        }

        &-minus{
          font-size: 22px;
          top: -10px !important;
          right: -1px !important;
          margin-right: 5px;
        }

      }
    }

  }
  &--roomType{
    height: 30px;
    padding-top: 5px;
    padding-left: 10px;
    position: relative;
    cursor: pointer;

    &__popup{
      background-color: #ffffff;
      padding: 13px 0px 13px 10px;
      z-index: z-index(room-type-popup);
      position: absolute;
      top: 39px;
      left: 6px;
      border: solid 1px #ccc;
      display: none;
      width: 195px;
      text-align: left;

      label {
        font-weight: normal;
      }

      span{
        float: none;
        position: relative;
        top: -2px;
        font-size: 13px;
        font-size: 1.3rem;
      }

      .arrow-up {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ccc;
        position: absolute;
        left: 123px;
        top: -10px;


        &__inner{
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #ffffff;
          position: relative;
          left: -8px;
          top: 2px;
        }
      }



    }
  }
  &--selfCatering{
    height: 30px;
    padding-top: 5px;
    padding-left: 10px;
    position: relative;
    cursor: pointer;

    input{
      float: right;
      width: 19px;
      height: 19px;
      margin-top: 0px;
    }
  }
  &--showMore{
    padding-left: 29px;
    padding-right: 20px;
  }
  &--showLess{
    position: absolute;
    right: 9px;
    bottom: 2px;
    width: 105px;


    &:hover{
      margin-top: 173px !important;
      margin-left: -3px;
    }
  }

}
.date-picker-wrapper .month-wrapper table .day.real-today{
  background-color: transparent !important;
}
@-moz-document url-prefix() {
  .ff-bg-color {
    background-color: #fcfafb;
  }

  .advanced-filtering__accommodation-type{
    padding: 0;
  }
}


/***********************MEDIA QUERIES**********************/
@media (max-width: 991px) {

  .slimmed-filtering--roomType span {
      float: right;
      position: relative;
      top: -20px;
      left: 10px;
  }

  .advanced-filtering__clear-dates{
    font-size: 10px;
    font-size: 1.0rem;
  }

  .advanced-filtering__clear-dates{
    width: 105%;
    display: block;
    margin-left: -24px;
  }

  .result--ordering select{
    width: 55%;
  }

  .nav-tabs > li > a{
    padding: 4px 11px 5px 10px;
  }

  /*****maps****/
  .map{
    &--container{
      height: 361px;
      margin-top: 10px;

      &__width{
        width: 281px;
      }
    }
  }

  .live--availability__images{
    text-align: left;
  }

  .room-name-liveavailability{
    width: 95%;
  }

  .live--availability__adultsandchildren{
    position: relative;
    top: -12px;
    padding-left: 15px;
  }

  .live--availability__roomsleft{
    position: relative;
    top: -12px;
    padding-left: 15px;
    display: inline-block;
    font-size: 12px;
    font-size: 1.2rem;
    padding: 0px;
  }

  .live--availability__price-textalign{
    padding-left: 15px;
  }

  .rooms--units__background{
    border-bottom: 2px dashed #efefef;
  }
  .clear-dates{
    position: inherit;
  }

  .special--container {
    top: 210px;
  }
}
@media (max-width: 767px) {
  body{
    overflow-x: hidden;
  }

  .page{
    overflow-x: hidden;
  }

  .mobile-slim-filter-toggle-trigger{
    padding: 20px 15px 0px 15px;

    i{
      font-size: 15px;
      font-size: 1.5rem;
    }
  }


  .result {
    &--ordering {
      padding: 0px 15px 9px;
      position: relative;
      margin-top: initial;
      height: 0px;
      select {
        -webkit-appearance: none;
        width: 100%;
        outline: none !important;
        position: relative;
        top: initial;
        z-index: 99;
        padding-left: 7px;
        float: right;
        height: 29px;
        font-size: 13px;
        text-transform: capitalize;
        top: 18px;
        background-color: transparent;

      }

      i {
        position: absolute;
        right: 36px;
        top: 27px;
        z-index: 999;
      }
    }

    &--price {
      .price {
        padding-top: 0px;
      }
    }
  }


  .result--bar{
    height: 20px;
    .float-left{
      margin-top: -11px;
    }
  }


  .mobile--filtering--overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
  }



  .advanced-filtering--spinner i{
    left: 37%;
  }

  .slimmed-filtering--calenders__mobile{
    input {
      width: 100%;
      height: 35px;
      margin-top: 15px;
      border: solid 1px $black;
      outline: none !important;
      -webkit-appearance: none;
      border-radius: 0px;
    }

    &-right{
      padding-right: 0px;

      input{
        border-right: none !important;
      }
    }

    &-left{
      padding-left: 0px;

      input{
        border-left: none !important;
      }

    }

  }


  .advanced-filtering__input--extra span{
    position: relative;
    top: -5px;
    margin-top: -3px;
    display: inline-block;
    font-size: 15px;
  }

  .advanced-filtering__input--extra input[type="checkbox"]{
    width: 25px !important;
    height: 25px !important;
    margin-top: 10px !important;
  }


  .advanced-filtering__input span {
    position: relative;
    top: -5px;
    margin-top: -3px;
    display: inline-block;
    font-size: 15px;
  }

  .advanced-filtering__buttons-tiers-padding{
    padding-bottom: 10px;
  }

  .advanced-filtering__input input[type="checkbox"]{
    width: 25px;
    height: 25px;
    margin-top: 10px;
  }

  .cbp-spmenu-vertical{
    z-index: z-index(mobile-filters-menu) !important;
  }
  .advanced-filtering__row--spacing{
    margin: 0;
  }
  .advanced-filtering__headings{
    padding-left: 5px;
    padding-bottom: 10px;
  }
  .advanced-filtering__input input[type="text"] {
    height: 35px;
    margin-bottom: 5px;
  }
  .advanced-filtering__input select{
    height: 35px;
  }
  .advanced-filtering__input img {
    position: absolute;
    right: 11px;
    top: 6px;
  }
  .advanced-filtering__accomodation--btn{
    margin: 0;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }
  .advanced-filtering__input--specials{
    padding-top: 30px;
  }
  .live--availability__price-textalign{
    padding-left: 0px;
  }
  .rooms--units__background{
    margin: 0;
    border: none;
  }
  .a_for_pagination {
    outline: solid 1px #3f97ff;
    padding: 1px 11px;
    font-size: 18px;
  }
  .filtering__submit{
    text-align: left;
  }
  .live--availability__adultsandchildren{
    position: relative;
    top: 0px;
    padding-left: 15px;
  }
  .live--availability__price-textalign{
    text-align: left;
    border-bottom: solid 1px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .live--availability__price{
    text-align: right;
  }
  .live--availability__images {
    text-align: left;
    padding-left: 15px;
    height: auto !important;
    img{
    }
  }
  .live--availability__adultsandchildren{
    padding-left: 0;
  }
  .location--information__headings{
    a{
      font-size: 18px;
      font-size: 1.8rem;
    }

    a:visited{
      font-size: 18px;
      font-size: 1.8rem;
    }

    a:hover{
      font-size: 18px;
      font-size: 1.8rem;
    }
  }
  .live--availability{
    padding: 0;
  }
  .clear-dates {
    float: right;
    top: 12px;
    position: relative;
    right: 3px;
  }
  .room-name-liveavailability{
    width: 100%;
    margin-top: 5px;
    text-transform: capitalize;
    position: relative;
    top: 0px;
    font-weight: bold;
  }
  /*****nav pills****/
  .nav-pills > li > a {
    border-radius: 0px;
    border: solid 1px #C7BBAF;
    margin: 10px 0 0 0;
    width: 150px;
    text-align: center;
    margin-right: 5px;
    height: 33px;
    font-size: 11px;
    font-weight: bold;
  }
  .nav-pills > li > a{
    background-color: $pink;
    color: #ffffff;
  }
  .nav>li>a:hover, .nav>li>a:focus{
    background-color: $pink;
  }
  .suburbs-toggle{
    display: none;

    &--links{
      padding-left: 40px;
    }
  }
  .small_heading{
    margin-bottom: 5px;
    pointer-events: all;
    margin-top: 0;

    span{
      margin-top: -33px;
    }
  }
  .suburbs{
    padding-left: 0px;
    background-color: transparent;
    margin-top: 30px;
  }
  #tabs--more__mobi{
    display: none;
  }
  #room-toggle{
    display: none;
  }
  .special--container__information{
    display: none;
  }
  .result--details{
  }
  .result--details:hover{
    background-color: rgba(235,235,235,0.7);
  }
  .result--type{
    img{width: 65px;}
  }
  .tab--anchor-readmore {
    float: right;
    margin-bottom: 10px;
    z-index: 200;
    position: absolute;
    right: 5px;
    width: 31px;
    top: 29px;
  }
  .mobile-ribbon {
    position: absolute;
    right: -12px;
    top: 36px;
    background-color: $pink;
    color: #ffffff;
    padding: 8px 18px 8px 0px;
    width: 50%;
    text-align: right;
    z-index: 1;


    span{
      font-size: 14px;
      font-size: 1.4rem;
      margin-top: -20px;
    }

    &__arrow-right{
      width: 0px;
      height: 0px;
      border-top: 5px solid transparent;
      border-bottom: 0px solid transparent;
      border-left: 12px solid $pink;
      position: absolute;
      top: -5px;
      right: 0px;
    }
  }
  .advanced-filtering{
    display: none;
    margin-top: 0px;
    padding: 15px;
    margin-top: 20px;


    &__mobile-button{
      margin: auto;
      background-color: $green;
      width: 150px;
      font-weight: bold;
      font-size: 14px;
      padding: 6px 10px;
      border-radius: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #ffffff;
      text-transform: uppercase;

      span{
        float: right;
      }

      &--fullwidth{
        width: 100%;
        margin: 0;
        background-color: #ebebeb;
        color: $black;
        border: #ccc solid 1px;
      }
    }



    &__price{
      background-color: #fcfafb;
      outline: solid #b4abae 1px;
      position: initial;
      padding: 10px 15px 20px 15px;

      &--padding{
        padding-left: 16px;
        padding-right: 16px;

      }
    }


    &__buttons{
      margin-top: 5px;


      &--morefilters{
        float: right;
        font-size: 12px;
        color: $linksblue;
        padding-top: 10px;
        cursor: pointer;
      }

      .btnenquire{
        font-size: 14px;
      }

      &--showMoreSecondTier{
        font-size: 25px;
        color: $linksblue;
        margin: auto;
        width: 160px;
        text-align: center;
        margin-top: 10px;
      }

    }


    &__property-type{
      border: solid 1px;
      padding: 10px;
      margin-top: 12px;

      span{
        float: right;
      }
    }




    &__input{
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      margin-top: 4px;


      img {
        top: 8px !important;
        right: 20px;
      }

      span{
      }

      &--left{
        padding-right: 5px;
      }


      &--right{
        padding-left: 5px;
      }


    }


    &__accomodation--btn{
      margin-top: 20px;
      display: none;
    }

    &__accommodation-type{
      background-color: #fcfafb;
      outline: solid #b4abae 1px;
      position: initial;
      padding: 5px 15px;

    }


    &__headings{
      padding: 0 15px;
    }

  }

  .advanced-filtering__input--extra{
    padding: 0px;
    input[type="checkbox"] {
      width: 19px;
      height: 19px;
      margin-top: 0px;
      margin-right: 8px;
    }

    span{
      display: inline-block;
      position: relative;
      top: -5px;
    }
  }

  .advanced-filtering__input-dropdown{
    width: 100%;
    margin-top: 18px;
    margin-bottom: 20px;
    z-index: 9999;
    position: absolute !important;


    i{
      float: right;
    }
  }

  .advanced-filtering__input-dropdown-mobile{
    position: relative;
    outline: solid #b4abae 1px;
    padding: 4px;
  }

  .advanced-filtering__input span{
    position: relative;
    top: -5px;
    margin-top: 12px;
    display: inline-block;
  }

  .advanced-filtering--remove-mobile-padding{
    padding: 0px 1px;
  }



  .showExtraProperties{
    padding: 0px 15px;
  }


  .cbp-spmenu-right-width{
    right: -100% !important;
  }

  .cbp-spmenu-s3-width{
    width: 100% !important;
  }

  .cbp-spmenu-open3{
    right: 0px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    position:relative;
    z-index: z-index(mobile-filters-menu);
  }

  .scrollOnBody{
    overflow-y: scroll !important;
  }

  .bodyNoScroll{
    overflow: hidden !important;
    position: fixed;
  }


  .cbp-spmenu-s3{

    &__trigger{

      &_pos{
        position: fixed;
        bottom: 48px;
        z-index: 9998;
        position: fixed;
        bottom: 20px;
        left: 50%;
        width: 120px;
        margin-left: -60px; /* Negative half of width. */



        .btnenquire{
          width: 100%;
          font-size: 13px;
          padding: 10px 10px 9px 10px;
          margin-top: 0px;
          position: relative;
          top: 1px;
        }
      }
    }

    &--calender{

      position: relative;

      input[type="text"] {
        width: 100%;
        height: 35px;
        margin-top: 15px;

      }

      .ui-datepicker-trigger{
        position: absolute;
        right: 22px;
        top: 22px;

      }
    }
    &__numGuests{
        width: 100%;
        height: 35px;
        margin-top: 15px;
        border: solid 1px $black;
    }
    &__input{
      position: relative;
      input[type="checkbox"]{
            width: 25px;
            height: 25px;
            margin-top: 0px;
            margin-right: 1px;
      }
      span{
        top: 4px;
        position: absolute;
      }


      &--padding{padding-top: 15px;}
    }

  }

  #facilitiesContent{
    padding: 10px 0px;
    margin-bottom: 15px;
  }

  #generalContent{
    padding: 10px 0px;
    margin-bottom: 15px;
  }

  .slide-menu__close3{
    background-color: transparent;
    width: 100%;
    padding: 15px;
    outline: none;
  }

  .close-cpb-spmenu-s3{
    position: absolute;
    top: 1px;
    right: 16px;
    z-index: 9999;
    font-size: 17px;
    font-weight: bolder;
    width: 60px;
    height: 48px;

    i{
      position: relative;
      left: 23px;
      top: 9px;
    }
  }

  .advanced-filtering__alerts{
      float: left;
      margin-right: 5px;
      padding: 5px 25px;
      color: #726f68;
      background-color: transparent;
      border-color: #726f68;

      a{
        opacity: 1;
      }

      &--dates{
        margin-bottom: 10px;
        margin-top: 10px;
        color: #726f68;
        background-color: transparent;
        border-color: #726f68;
      }
  }

}
@media (max-width: 322px) {

  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
    font-size: 1.2rem;
    top: 0px;
  }

  .nav-tabs > li > a{
    font-size: 1.2rem;
  }
}
