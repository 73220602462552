/****************nav boostrap overrides*****************/
.navbar{min-height: 32px; margin-bottom: 0px}
.navbar-default{
  background-image: none;
}
.navbar-nav>li>a{
  padding: 6px  10px 6px 10px !important;
  background-color: transparent;
  font-size: 13px;
  font-size: 1.3rem;
  color: #bababa;
}


.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus{
  color: #fff;
  text-decoration: none;
  outline: 0;
  padding: 10px 7px;
  position: relative;
  left: 0px;
  background-color: #ffffff;
  width: 100%;
}

.nav-tabs>li{
  &.active{
    background-color: transparent;
    border-bottom: solid 1px;
  }

}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover{
  background-color: #A67B51 !important;
  background-image: none;
  color: #ffffff;
  font-size: 13px;
  font-size: 1.3rem;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover{
  color: $silver;
  font-size: 13px;
  font-size: 1.3rem;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form{
  border-bottom-color: #93785D !important;
  color: $silver;
  height: 34px !important;
  font-size: 13px;
  font-size: 1.3rem;
  background-color: $white;
}

.navbar-default .navbar-nav>li>a{
  color: $silver;
  @include base-font(13px, 1.3rem);
}
.dropdown-menu{
  border: 1px solid $black;
  border-radius: 1px;
  padding-top: 0px;
  font-size: 12px;
  font-size: 1.2rem;
}

a.dropdown-toggle{
  background-color: #A67B51;
  padding: 10px;
}

.navbar-nav>li>.dropdown-menu{
  width: 470px;
}


.dropdown:hover .dropdown-menu {
  display: block;
}


.nav-tabs>li>a:hover{
  border-radius: 0px;
  background-color: #ffffff;
  padding: 4px 20px 5px 20px;
  font-size: 12px;
  font-size: 1.2rem;
  margin-top: 25px;
  color: $silver;
  @include base-font(14px, 1.4rem);
  position: relative;
  top: 2px;
}


/**********CUSTOM OVERRIDES***********/
.header--menu{
  &__subheading{
    padding: 0 0 8px 0;
    color: #3b439f;

    a{
      color: #3b439f;
    }

    a: hover{
      color: #3b439f;
    }

    a:visited{
      color: #3b439f;
    }
  }

  &__headings{
    background-color: #EFF2D7;
    @include base-font(14px, 1.4rem);
    padding-left: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &__border-left{
      border-left: solid 1px #C7BBAF;
  }

  &__border-right{
    border-right: solid 1px #C7BBAF;
  }

  &__gradientNav{
  }

}

.dropdown--headings--padding{
  padding-left: 10px;
}

/***** Mobile Media query*********/
@media (max-width: 767px) {
  /*********CUSTOM OVERRIDES MOBILE****/
  .header--mobile{
    position: relative;

    img{
      width: 167px;
      padding: 10px 5px;

    }

    &--search{
      position: absolute;
      right: -9px;
      top: 16px;
      z-index: 99;

      i{
        font-size: 21px;
      }

    }
  }
  .mobile--search{
    display: none;
    z-index: 100 !important;
    background-color: #FBF9FA !important;
    border: none !important;
    padding: 0 9px !important;
    top: 50px;


    button{
      background-color: $green;
      border: none;
      top: -13px;
    }

    input[type=text]{
      width: 85%;
    }
  }
  .button{
    &--search{
      display: inline-block;
      img{
        padding: 0;
        width: 23px;
        margin-top: -4px;
        height: 23px;
      }

    }
  }


  /*************NAV BOOTSTRAP***************/
  .navbar-default{
    background-image: none;
    background-color: transparent;
    border: none;
  }


  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form{
    height:auto !important;
    border-bottom-color: #AB864F !important;
    color: #004b91;
    font-size: 1.3px;
    font-size: 1.3rem;
    background-color: #EFF2D7;
    background-color: rgba(239,242,215,0.6);
    background-position: center bottom;
    padding: 0px;
    background-image: none;
  }


  .navbar-default .navbar-nav .open .dropdown-menu>li>a{
    color: #004b91;
  }


  .navbar-header{
    background-color: transparent;
    border-top: none;
    border-bottom: none;

  }

  .navbar-default .navbar-toggle{
    border: none;
    position: relative;
    top: -94px;
    left: 20px;
  }



  .navbar-default .navbar-toggle .icon-bar{
    background-color: $silver;
  }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{
    background-color: transparent;
    position: relative;
    top: -93px;
    left: 20px;

  }
  .nav>li.divider{
    display: none;
  }

  .navbar-default .navbar-nav>li>a, .navbar-default .navbar-text{
    text-align: left;
  }

  .navbar-nav{
    margin: 0;
  }
  .navbar-fixed-top {
    position: relative;
  }

  .navbar-nav .open .dropdown-menu {
    background-color: #f2f2f2;
  }

  .navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu>li>a {
    padding: 10px;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #ddd;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover{
    display: block;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a{
    background-color: #ffffff;
    position: relative;
    top: -9px
  }

  .dropdown:hover .dropdown-menu {
    display: inline;
    z-index: -999999;
    height: 0px;
    overflow: hidden;
  }

  .nav{
    overflow: hidden;
  }
}