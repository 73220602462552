footer{
  padding-top: 60px;
}

#quick-links{
  display: none;
}

.mobile--phone {
  font-size: 21px !important;
  position: relative;
  top: 2px;
}

.footer--feedbackbar{
  background-color: #f2f2f2;
  line-height: 3rem;
  margin: 0;
  border-top: solid 1px #e7e7e7;
  border-bottom: solid 1px #e7e7e7;

  input{
    line-height: 0px;
  }

  input[type=text]{
    color: #C0C0C0;
    height: 20px;
    position: relative;
    background: #fff url(/img/input_background.png) repeat-x top left;
    border: $green solid 1px;
    border-width: 1px;
    padding-left: 7px;
    margin-top: 7px;

  }

  input[type=submit]{
    height: 20px;
    position: absolute;
    padding: 0px 3px;
    font-weight: normal;
    margin-top: 7px;
    margin-left: 5px;


  }



  &__text{
    font-weight: bold;
    padding: 8px 0px 10px 10px;
  }

  &__padding{
    padding-top: 6px;
    padding-bottom: 5px;
    padding-left: 0px;
  }

  &_sendfeedback{
    text-align: right;
    padding: 10px 0px;
  }
}

.footer--textsection{
  @include base-font(13px,1.3rem);
  background-color: #fbf9fa;
  padding-top: 30px;
  margin: 0;
  color: $black;

  a{
    color: $black;
    text-decoration: none;
    font-size: 1.3rem;
    font-size: 13px;
    line-height: 23px;
  }
  a:hover{

    color: $pink;
    font-size: 1.3rem;
    font-size: 13px;
    line-height: 23px;
  }
  a:visited{
    font-size: 1.3rem;
    font-size: 13px;
    line-height: 23px;
  }
  a:focus{
    color: $pink;
    font-size: 1.4rem;
    font-size: 14px;
    line-height: 23px;
  }

  &__col1{
    padding: 0px 0 10px 65px;

    h4{
      padding: 0px 0 15px 35px;
      color: $black;
      @include base-font(15px, 1.5rem);
      font-weight: bold;
    }

    ul{
      font-size: 1.3rem;
      font-size: 13px;
      line-height: 23px;
      color: $black;
    }
  }

  &__col2{
    padding: 0px 0 10px 111px;
    font-size: 1.3rem;
    font-size: 13px;
    color: $black;;

    ul{
      padding: 0;
      li{list-style: none;}
    }

    h4{
      color: $black;
      @include base-font(15px, 1.5rem);
      font-weight: bold;

    }

    h5{
      font-weight: bold;
      @include base-font(12px, 1.2rem);
      font-style: normal;
      margin: 10px 0px 10px 0px;
    }
  }

  &__col3{
    ul{
      padding: 0;
      li{list-style: none;}
    }

    h4{
      color: $black;
      @include base-font(15px, 1.5rem);
      font-weight: bold;

    }


    h5{
      font-weight: bold;
      @include base-font(12px, 1.2rem);
      color: #FFFFFF;
      font-style: normal;
      margin: 13px 0px 10px 0px;
    }
  }

}

.copyright{
  text-align: center;
  font-size: 12px;
  color: $black;

}

.footer--feedbackbar_sendfeedback{
  a{
    text-decoration: none;
    font-size: 13px;
    line-height: 23px;
  }
  a:hover{
    text-decoration: underline;
    font-size: 13px;
    line-height: 23px;
  }
  a:visited{
    text-decoration: none;
    font-size: 13px;
    line-height: 23px;
  }
  a:focus{
    text-decoration: none;
    font-size: 13px;
    line-height: 23px;
  }
}



/***************media queries************/
@media (min-width: 1200px){
  /*set width of website*/
}

@media (max-width: 1199px) {
}

@media (max-width: 991px){

  .footer--feedbackbar{
    input[type=text] {
      width: 180px;
      height: 20px;
      font-weight: normal;
    }

    &__text{
      font-weight: bold;
      font-size: 11px;
      padding-top: 5px;
    }
  }

  .footer--textsection__col1 {
    padding: 0px;
  }

  .footer--textsection__col1-new {
    padding: 35px 0 0 20px;
    border-top: solid 2px $white;
    margin-top: 30px;
  }

  .footer--textsection__col3{
    padding-left: 118px;
    margin-top: -10px;

    h5{
      margin: 21px 0px 10px 0px;
    }
  }
}

@media (max-width: 767px) {

  .footer--textsection{
    padding-top: 0px;
  }

  .footer--textsection__col1{
    clear: both;
    height: 209px;
    padding: 0px;

    h4{
      padding: 0px 0 0px 10px;
    }
  }

  .lightSpeedIn{
    a{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
    }
    a:hover{
      text-decoration: underline;
      font-size: 12px;
      line-height: 23px;
    }
    a:visited{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
    }
    a:focus{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
    }
  }

  .footer--feedbackbar{
    a{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
      font-weight: bold;
    }
    a:hover{
      text-decoration: underline;
      font-size: 12px;
      line-height: 23px;
      font-weight: bold;
    }
    a:visited{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
      font-weight: bold;
    }
    a:focus{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
      font-weight: bold;
    }

    &__padding{
      padding-top: 10px;
    }
  }

  .footer--feedbackbar__gradient{
    a{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
      font-weight: bold;
    }
    a:hover{
      text-decoration: underline;
      font-size: 12px;
      line-height: 23px;
      font-weight: bold;
    }
    a:visited{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
      font-weight: bold;
    }
    a:focus{
      text-decoration: none;
      font-size: 12px;
      line-height: 23px;
      font-weight: bold;
    }
  }
}
