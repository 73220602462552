.eft-success {

	margin: 15px;
	margin-bottom: 10px;

	&__container {
		background-color: #FFF;
		padding-bottom: 15px;
	}

	&__success {
		margin-top: 5px;
		i {
			font-size: 2.5rem !important;
			color: #839D43;
		}
		span {
			font-size: 19px;
			font-size: 1.9rem;
			font-weight: bold;
		}
	}

	&__details {
		font-size: 12px;
		font-size: 1.2rem;
	}

	&__booking {

		&-deposit {
			padding: 10px;
			background-color: #dff0d8;
			border-color: #d6e9c6;
			color: #3c763d;
			border-radius: 4px;
			margin-bottom: 10px;
			font-size: 12px;
			font-size: 1.2rem;
			font-weight: bold;
		}


		&-summary {
			background-color: #F4F4F4;
			padding: 17px 10px;
			border-radius: 4px;
			padding-left: 30px;
		}

		&-steps {
			padding: 0;
			margin: 0;
		}

		&-bank {
			padding-left: 40px;
		}

		&-confirm {
			padding-top: 10px;
		}

	}

	&__voucher {
		padding: 0;
		margin-top: -15px;
	}

	&__next {
		padding: 0;
		border-bottom: 1px solid $light-silver;
		margin-top: 20px;
		margin-bottom: 30px;
		font-weight: bold;
		padding-bottom: 5px;
		float: left;
	  	width: 100%;
	}
}

/* Unique CSS */

.green-text {
  color: #839d44;
}

.underline {
	text-decoration: underline;
}

.bold {
	font-weight: bold;
}

/*            */


@media screen and (min-width: 0px) and (max-width: 480px) {
	.eft-success {
	    margin-left: 0px;
	    margin-right: 0px;

	    &__booking-bank {
	    	padding-left: 5px;
	    }
	}
}
