
.na-width-position {
  display: inline-block;
  width: 50px;
  text-align: center;
}

.success-booking{
  font-size: 18px;
  font-size: 1.8rem;

  a{
    font-size: 18px;
    font-size: 1.8rem;
    color: $black;
  }

  a:hover{
    color: $pink;
  }
}


/******VALIDATION ERROR MESSAGES****/
.errors {
  color: #ff0000;
  font-size: 13px;

  &__custom{
    margin-top: -10px;
    margin-bottom: 15px;
    display: block;
  }

  &__width{
    width: 100%;
  }

  &__liveavailbilty{
    padding-left: 15px;
  }


  &__bottom-margin{
    margin-bottom: 15px;
    display: block;
  }

  &__arrive {
    position: relative;
    top: -18px;
  }

  &__depart {
    padding: 0 0 0 33px;
    height: 0px;
    position: relative;
    top: -18px;
  }

  &__rooms-required-error {
  }

  &__details{
    color: #ff0000;
    font-size: 13px;


  }

  &__roomtype-scrollTo{
    width: 100%;
    margin-top: 6px;
    position: absolute;
    top: 31px;
  }
}
/*******error message and search*********/
.message {
  &--text {
    text-align: center;

    &__error {
    }
  }
}



.enquiry-calender{
  &__padding{
    padding: 0px;
  }

  &__inputs{
    position: relative;
    
    i{
      position: absolute;
      color: $linksblue;
      top: 26px;
      left: 4px;
      font-size: 17px;
    }

    input{
      padding-left: 30px;
      border: solid 1px #3f97ff;
      outline: none;
      height: 30px !important;
    }
  }

  &__arrow{

    padding: 0px;

    i{
      position: relative;
      top: 16px;
      color: #3f97ff;
      font-size: 26px;
      left: 26px;
    }

    span{
      position: relative;
      top: 7px;
      left: 9px;
    }
  }
}



.left-content-margin{
  margin-top: -205px;
}

.left-content-margin{
  margin-top: -205px;
}



/******establishment headings*******/
.headings {
  margin-bottom: 10px;
  &--text {
    span {
      font-weight: bolder;
      @include base-font(24px, 2.4rem);
      color: $black;
    }
    a {
      text-decoration: none !important;
    }
  }
  &--availability__text {
    font-size: 12px;

    a {
      @include base-font(12px, 1.2rem);
      font-weight: bold;
      text-decoration: none;
    }

    a:hover {
      @include base-font(12px, 1.2rem);
      font-weight: bold;
      text-decoration: underline;
    }

    a:visited {
      @include base-font(18px, 1.8rem);
      font-weight: bold;
      text-decoration: none;
    }
  }
  &__numbering {
    display: inline-block;
    color: #3f97ff;
    padding: 5px 10px;
    font-weight: bolder;
    font-size: 14px;
    border: solid 1px #3f97ff;
    margin-right: 9px;
  }
  &__question {
    font-weight: bolder;
    font-size: 20px;
    font-size: 2.0rem;
    color: $black;
    display: inline-block;
    margin-top: 50px;
    padding-bottom: 16px;
    position: relative;
    top: 3px;
  }
  &__question-chose{
    padding-bottom: 0px;
  }
  &--font{
    a {
      @include base-font(24px, 2.40rem);
      font-weight: bold;
      text-decoration: underline;
      color: $black;
    }

    a:hover {

      text-decoration: none;
      color: $pink;
    }

    a:visited {
      @include base-font(24px, 2.4Rem);
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
/******Calendar*******/
.calender {
  &--container {

    &__display {
      display: inline-block;
    }

    &__numofnights{
      margin-left: 27px;
    }

    label {
      @include base-font(14px, 1.4rem);
    }

    input[type="text"] {
      height: 25px;
      width: 166px;
      text-align: left;
      font-size: 13px;
      padding-left: 5px;
    }

    img {
      padding-left: 5px;
      width: 24px;
      margin-left: -27px;
      margin-top: -1px;

    }

    &__padding {
      padding-left: 30px;
    }

    &__tablet-image {
      width: 200px;
      position: absolute;
      right: 20px;
      top: -143px;
    }
  }

  .arrival {
    height: 25px;
    width: 120px;
    text-align: left;
    font-size: 13px;
    padding-left: 5px;
  }

  .departure {
    height: 25px;
    width: 120px;
    text-align: left;
    font-size: 13px;
    padding-left: 5px;
  }
}
/******units*******/
.units {
  &--headings {

    margin-bottom: 10px;

    span {
      @include base-font(12px, 1.2rem);
    }

    &__roomtypes {
      font-weight: bold;
      padding: 0px 0px 6px 0px;
      margin-top: 20px;
      font-size: 16px;
      font-size: 1.6rem;
    }
  }
}
/******room headings*******/
.rooms--heading {
  font-weight: bold;
}
/******room types*******/
.roomtypes {
  position: relative;
  &--padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &--font {
    color: #666;
    @include base-font(12px, 1.2rem);

    a {
      @include base-font(16px, 1.6rem);
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $bodygray;
    }

    a:hover {
      color: $pink;
    }

    &__padding {
      padding: 5px 0px 2px 0px;
    }
  }

  &__images {
    padding: 0px 0px;
    text-align: right;
  }

  &--maxguest__font {
    text-align: right;
    span {
      font-weight: bold;
    }
  }

  &--pricing {
    text-align: right;
    @include base-font(14px, 1.4rem);
    span {
      font-weight: bold;
      @include base-font(15px, 1.5rem);
    }
  }

  &--roomnumber {
    select {
      margin-bottom: 10px;
      width: 149px;
      height: 30px;
    }
    text-align:  right;
  }

  &--guesttypes {
    padding: 0px 0px 5px 0px;
    font-size: 12px;
    position: relative;

    select {
      width: 50px;
      display: inline-block;
    }

    &__adult-position {
      position: absolute;
      top: 18px;
      right: 67px;
    }
  }

  &--roomorunit {
    span {
      position: absolute;
      right: 125px;
      top: 8px;
      width: 50px;
    }


  }

  hr {
    border-bottom: 1px solid #cecece;
    border-top: none;
  }
}
/******establishment headings*******/
.traveller {

  &__padding{
    padding-right: 15px;

  }


  &__btn {
    width: 89%;
  }

  &--specialrequest {


    textarea {
      width: 100%;
      height: 100px
    }

  }
}
/******summary left content*******/
.summary {
  @include base-font(12px, 1.2rem);
  font-style: normal;




  &--nomargin {
    border: 1px solid $silver;
    margin-right: 0px;
    margin-top: 30px;
  }

  &--margin {
    margin: -193px 0px 0px 0px;
    border: 1px solid #cecece;



    img {
      width: 100%;
    }

    &--country__flag{

      position: relative;

      span{
        font-weight: bold;
      }
    }
  }


  &--margin-b {
    margin: -193px 0px 0px 0px;
    border: 1px solid #cecece;

    img {
      width: 100%;
    }
  }

  a {
    @include base-font(14px, 1.4rem);
    color: $links-normal;
    text-decoration: underline;
    font-weight: bold;
    font-style: normal;
  }

  a:visited {
    @include base-font(14px, 1.4rem);
    color: $links-normal;
    text-decoration: underline;
    font-weight: bold;
    font-style: normal;
  }

  a:focus {
    @include base-font(14px, 1.4rem);
    color: $links-normal;
    text-decoration: underline;
    font-weight: bold;
    font-style: normal;
  }

  a:focus {
    @include base-font(14px, 1.4rem);
    color: $links-hover;
    text-decoration: underline;
    font-weight: bold;
    font-style: normal;
  }

  #hiddenReview {
    display: none;
  }

  &--information {
    img {
      width: 38px;
    }

    &__padding {
      padding: 0px 5px 10px;
      border-bottom: 1px solid #cecece;
      margin-top: -6px;

    }

    a {
      @include base-font(14px, 1.4rem);
      color: #726f68;
      text-decoration: underline;
      font-weight: bold;
      font-style: normal;
    }

    a:visited {
      @include base-font(14px, 1.4rem);
      color: #726f68;
      text-decoration: underline;
      font-weight: bold;
      font-style: normal;
    }

    a:focus {
      @include base-font(14px, 1.4rem);
      color: #726f68;
      text-decoration: underline;
      font-weight: bold;
      font-style: normal;
    }

    a:hover {
      @include base-font(14px, 1.4rem);
      color: #726f68;
      text-decoration: underline;
      font-weight: bold;
      font-style: normal;
    }

    &__section-padding {
      padding: 10px 5px;
      border-bottom: solid 1px #cecece;
      img {
        width: 65px;
      }
    }

    &__section-stars{
      position: relative;
      position: relative;
      top: 3px;
    }


  }

  &--country__flag {
    position: relative;
    margin: 5px 0;

    img {
      width: 20px;
      padding-bottom: 15px;
    }
  }
}
/******specials*******/
.specials {
  border: 1px solid $silver;
  margin-top: 20px;
  color: $pink;
  @include base-font(12px, 1.2rem);
  padding-bottom: 20px;

  h3 {
    width: 235px;
    height: 33px;
    background-color: #E5203C;
    position: relative;
    left: -19px;
    color: #fff;
    padding: 5px 10px 5px 54px;
    top: -8px;
    padding-top: 9px;
  }

  &--margin{
    div{
      margin-top: 10px;
      color: #726f68;
    }

    i{
    }

    b{
      color: #000;
      font-size: 15px;
      font-size: 1.5rem;
    }



  }

  &--request{
    text-align: center;
    padding-top: 15px;
  }

  &--checkboxes{
    text-align: center;
    padding-top: 25px;

  }

  &--ribbon{
    position: relative;
    left: -53px;
    color: #fff;
    padding: 2px 10px 0px 56px;

    h2{

      margin: 0;
      color: $pink;
    }
  }

}
.establishment--specials__triangle__emailenquiry {
  width: 0;
  height: 0;
  border-bottom: 13px solid #CA1D35;
  border-left: 17px solid transparent;
  position: relative;
  left: -18px;
  top: 12px;
}





.button--request{
  margin-left: 24px;
  margin-top: 5px;
  span{
    padding-left: 20px;
  }
}
.select--width{
  width: 50px !important;
}
.pricing {
  @include base-font(14px, 1.4rem);
  text-align: right;

  b {
    @include base-font(15px, 1.5rem);
    color: $pink;
  }
}
.select {

  &__float{
    float: right;
    font-size: 12px;
  }


  &--adult{
    width: 50px !important;


    &__message{

      text-align: center;
      width: 50px;
      display: inline-block;

      span {
        color: #666;
      }
    }
  }

  &--children{

    width: 50px !important;
    &__message {
      text-align: center;
      width: 50px;
      display: inline-block;

      span {
        color: #666;

      }
    }
  }
}
#showReviewLink {
  @include base-font(12px, 1.2rem);
  color: $links-normal;
  text-decoration: underline;
  font-weight: normal;
  font-style: italic;
  text-decoration: none !important;
}
.scroll-height{
  height: 300px;
  overflow-x: hidden;
  width: 236px;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}
.flags{
  &__display{
    display: inline-block !important;
    padding: 3px 6px !important;

  }

  &__list{
    padding: 3px 0px;
  }
}
.fancybox-width{
  max-width: 500px;
}
.numbering-heading{
  padding-left: 0;
}
.not--applicable{
  width: 46px;
  display: inline-block;
  text-align: center;
}
.popup{
  &--roomname{
    font-weight: bold;
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.roomtypes--padding ul{
  list-style: none;
  text-align: right;
  margin-bottom: 5px;
  margin-right: 17px;
}
.roomtypes--padding ul li select{
  width: 110px;
  margin-bottom: 5px;
}
.country-select__width{
  margin-bottom: 6px;
  width: 63%;
}
.mobile-number--noscript__pos{
  margin-top: -23px;
}


@media (max-width: 1199px) {
  .calender--container {
    input[type=text] {
      width: 50%;
    }

    &__padding {
      padding-left: 0px;
    }
  }
}
@media (max-width: 991px) {

  .calender--container__numofnights{
    margin-left: 0px;
  }

  .tablet-top-padding{
    padding-top: 20px;
  }


  .errors__depart{
    padding-left: 0px;
  }

  .fancybox-wrap {
    width: auto !important;
  }

  .fancybox-inner {
    width: auto;
  }

  .roomtypes--roomnumber select{
    margin-bottom: 20px;
  }

  .traveller select{
    width: 62%;
  }

  .calender--container__display{
    display: block;
  }

  .tablet--padding{
    padding-right: 12px;
  }

  .calender--container__tablet-image{
    width: 200px;
    position: absolute;
    right: 14px;
    top: -52px;
  }

  .header--input input[type=text] {
    position: absolute;
    right: 34px;
    top: 2px;
    height: 19px;
    font-size: 11px;
  }

  .calender--container{
    padding-bottom: 0px;
  }

  .calender--container input[type=text]{
    width: 26%;
  }

}
@media (max-width: 767px) {

  .enquiry-calender__arrow span {
    position: relative;
    top: -10px;
    left: 9px;
  }


  .headings--text span{
    font-size: 18px;
    font-size: 1.8rem;
  }

  .headings__question{
    font-size: 18px;
    font-size: 1.8rem;
  }



  .not--applicable{
    width: 47%;
    display: inline-block;
    text-align: center;
  }

  .tablet-top-padding{
    padding-top: 0px;
  }

  .errors__arrive{
    top: 0px;
  }

  .errors__depart{
    padding-left: 0;
    top: 0px;
  }

  .room-details {
    display: none;
  }
  /************************************/

  .mobile--nomargin{
    margin-top: 0px !important;
  }

  .mobile--information {
    hr {
      border-bottom: solid 1px #EFEFEF;
      border-top: none;
    }
  }

  .mobile-explanation{
    position: absolute !important;
    right: 29px !important;
    top: -17px !important;
  }

  .headings {
    margin-bottom: 0px;
    &--font {
      margin-top: 20px;

      span{
        font-size: 18px;
        font-size: 1.8rem;
      }

      a{
        font-size: 18px;
        font-size: 1.8rem;
      }

      a:hover{
        font-size: 18px;
        font-size: 1.8rem;
      }

      a:visited{
        font-size: 18px;
        font-size: 1.8rem;
      }

      a:focus{
        font-size: 18px;
        font-size: 1.8rem;
      }
    }

    &--availability__text {
      margin-bottom: 0px;
    }
  }

  .roomtypes {
    &--roomnumber {
      select {
        width: 100%;
        height: 40px;
      }
    }
    &--guesttypes {
      select {
        display: inline-block;
        width: 49%;
      }

      &__adult-position {
        display: none;
      }
    }
    &--roomorunit {
      span {
        position: absolute;
        right: auto;
        top: 0px;
        left: 0px;
        padding-top: 5px
      }
    }
  }

  .mobile {
    &--selects {
      width: 100%;
      position: relative;
      padding: 5px 0px;
      display: inline;

      &__kids-adults{
        width: 49%;
      }
    }

    &--children__display {
      display: none;
    }

    &--adult__position {
      text-align: left;
      font-weight: bold;
      padding: 30px 18px 10px 18px;
    }

    &--children__position {
      text-align: right;
      font-weight: bold;
      padding: 30px 18px 10px 18px;
    }

    &--details {

      h5 {
        display: inline-block;
        text-align: left;
      }

      i {
        display: inline-block;
      }

      &__price {
        @include base-font(12px, 1.2rem);
        text-align: right;


        b{
          color: $pink;
        }
      }
    }
  }

  .calender {
    padding-bottom: 0px;
    &--container {
      input[type="text"] {
        width: 85%;
        height: 35px;
      }

      &__padding {
        padding-left: 0px;
      }
    }
  }

  .units {
    &--headings {
      margin-bottom: 0px;
      &__roomtypes {
        margin-top: 0px;
      }
    }
  }

  .traveller {
    input[type=text] {
      width: 100%;
      height: 35px;
    }

    select {
      width: 100%;
      height: 35px;
    }

    .btn {
      width: 100%;
      height: 35px;
    }
  }

  .mobile--details {
    @include base-font(14px, 1.4rem);
    margin-bottom: 10px;
  }

  /***********JAVASCRIPT NOT BEMMED*****/
  .details-toggler {
    height: 34px;
    position: absolute;
    width: 62px;
    top: 43px;
    cursor: pointer;
  }

  .information--toggle {
    display: none;
  }

  .select{

    &__float{
      float: none;

    }
    &--adult{
      width: 49% !important;
      display: inline-block;

      &__message{
        width: 49%;
        padding-top: 30px;
      }
    }

    &--width{
      width: 49% !important;
    }

    &--children{
      width: 49% !important;
      display: inline-block;

      &__message{
        width: 49%;
        padding: 30px 0px 0px 0px;
      }
    }
  }

  .button--request{
    margin-left: 0px;
    padding-right: 12px;
    padding-left: 0;
    padding-top: 5px;
    text-align: center;
  }

  .calender--container label{
    margin-top: 20px;
  }

  .calender--container__numofnights{
    margin-left: 0px;
  }

  .traveller__padding{
    padding-left: 15px;
  }


  .noscript--callingCode__width{
    width: 25% !important;
  }
  .mobile-number--noscript__pos {
    margin-top: -35px;
    width: 63% !important;
  }
  .roomtypes--padding ul {
    list-style: none;
    text-align: right;
    margin-bottom: 5px;
    margin-right: 13px;
    margin-left: -25px;
    text-align: left;
  }
  .roomtypes--padding ul li select {
    width: 100%;
    margin-bottom: 5px;
    height: 40px;
  }
  .paxHeader-335{
    font-size: 19px;
    text-align: center;
    font-weight: bolder;
  }
}
@media (max-width: 320px) {
  .mobile__number {
    width: 54% !important;
  }

  .button--request{
    margin-left: 0px;
    padding-right: 12px;
    padding-left: 0;
    text-align: center;
  }

  .ui-widget-content{
    width: 68%;
  }

  .fancybox-wrap{
    max-width: 278px !important;
  }
}


// S.G Insert - Remaking bootstrap
.button-padding__height{
  height: 24px;
  border-radius: 0px;
  padding: 0px 0px 6px 6px;
  &-edit {
    @extend .button-padding__height;
    padding: 3px 0 3px 6px;
  }
}
.pageText{
  width: 310px;
  padding: 0px;
  height: 24px;
}
@media (max-width: 767px) {

  .pageText{
    width: 100%;
    height: 40px;
  }
}





.input__email-enquiry {
  width: 100%;
  padding-left: 5px;
}
.input-half__name {
  width: 150px;
  float: left;
}
.input-half__left-spacing {
  margin-left: 5px;
}
.input-half__right-spacing {
  margin-right: 5px;
}
@media (max-width: 767px) {

  .header--input input[type=text]{
    position: absolute;
    left: -7px;
    top: -11px;
    height: 19px;
    font-size: 11px;
    width: 94% !important;
  }


  .input-half__name {
    width: 49%;
  }

  .questions--mobile__margin{
    margin-top: 0px;
  }

  .input-half__left-spacing {
    margin-left: 1%;
  }
  .input-half__right-spacing {
    margin-right: 1%;
  }
}
@media (min-width: 768px) {
  .col-sm-3__width-26 {
    width: 26%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
  }

  .col-sm-9__width-74 {
    width: 74%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
  }

  .mobile--number__mobile{
    width: 68%;
  }

}