.modal__video{
  padding-left: 15px;
  padding-right: 15px;
  height: 400px;
}



.estdetails--padding{
  padding-left: 20px;
  padding-right: 20px;
}


.left--travel__guide-arrow{
  padding-top: 64px;
  padding-left: 47px;
}


.travel--attractions--right__arrow{
  margin-top: -37px;
  left: 771px;
}


.estdetails--summary{
  &__anchor{
    img{
      border: 5px solid #ffffff;
      border-radius: 4px;
      max-width: 100%;
    }
  }

  h4{
    margin-top: 10px;
    font-weight: bold;
    border-bottom: solid 1px #93785D;
    font-size: 15px;
    font-size: 1.5rem;
    text-align: left;
    line-height: 2;
  }

  ol{
    list-style-type: none;
    padding-left: 0px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 2;
  }

  &__readmore{
    text-align: right;
    padding-bottom: 20px;
  }
}


.estdetails--location{

  h2{
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 0.8em;
    color: #92000A;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
  }

  h1{
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    color: black;
  }
}


.content{
  padding: 15px;
  font-size: 14px;
  font-size: 1.4rem;
  position: relative;

  &--video{
    padding-top: 10px;
  }
}


.estdetails--gallery{
  border: solid 1px;
  padding: 10px;
  height: 200px;
  background-color: transparent !important;
  border: solid 1px $black !important;



  &__headings{
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__padding{
    padding: 0;
    margin: 0 !important;
    i{
      font-size: 30px;
      color: $black;
    }

    &-margin{
      margin-left: 7px;
      background-color: transparent;
    }
  }

  &__large{
    border: solid 1px;
    padding: 10px;
    height: 215px;
  }
}


.estdetails--video__gallery{
  border: solid 1px;
  padding: 10px;
  height: 202px;
  margin:30px 0px 20px 0px;
  position: relative;


  &-arrowsleft{

    margin-top: 30px !important;
    padding-left: 20px !important;
    display: block;
    i{
      font-size: 30px;
      color: $black;
    }

  }

  &-arrowsright{
    margin-top: 30px !important;
    position: relative;
    right: -66px;
    z-index: 9999;

    i{
      font-size: 30px;
      color: $black;
    }

  }

  &--right-arrow{
    top: 58px;
    right: -23px;
    position: relative;

    i{
      font-size: 30px;
      color: $black;
    }
  }
}




.estdetails--map{
  &__headings{
    margin-top: 20px;
    margin-bottom: 10px;
  }
}


.external_slide__anchor{
  position: absolute;
  right: 1%;
  top: 167px;
  font-size: 11px;
}


.ribbonNav{
  left: 0;
  width: 228px;
  margin-left: 31%;
}
.bigRibbonNav {
  top: 201px;
}
#slider1_container{
  height: 198px !important;
}
#slider2_container{
  width: 100% !important;
  height: 195px !important;
}
#slider3_container{
  display: none;
  position: relative;
  margin: 0 auto;
  top: 0px;
  left: 0px;
  width: 92%;
  height: 209px !important;
  overflow: hidden;
}
.attractions--right__arrow{
  top: 47px;
}
.magnifying_glass{
  display: inline-block !important;
  padding-left: 3px;
}
#tinyRibbon{
  margin-left: -53px;
}
#tinyRibbon .sli{
  padding-left: 2px;
  width: 181%;
}


.dojoxLightboxFooter {
  padding-bottom: 5px;
  margin-top: -9px !important;
  color: #333;
  z-index: 1000;
  font-size: 10pt;
  padding: 11px;
  padding: 13px 22px 0 0;
}

.slider {
  width: 100%;
  margin: 0;
}
.slick-slide {
  margin: 0px 20px;
  width: 200px;
}
.slick-slide img {
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: black;
}
.slick-track{
  height: 153px;
}
.slick-list{
  height: 153px;
}
.sli {
  visibility: visible;
}
.FirstImageMargin{
  margin-left: 15px !important;
}
.slick-next {
  right: -8px !important;
}
.slick-prev {
  left: -8px !important;
}

@media (min-width: 1200px){
  /*set width of website*/
}
@media (max-width: 1199px) {
}
@media (max-width: 991px){
  /*set width of website*/

  div.external_slide {
    border-top-width: 1px;
    overflow: hidden;
    height: 172px;
    width: 70%;
    padding: 0px;
    margin: 0px 15px 5px 45px;
  }

  #tinyRibbon{
    margin-left: 0;
  }

  #bigRibbon {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 190px;
    padding-left: 39px;
  }

  .magnifying_glass {
    display: inline-block;
    padding-left: 1px;
  }

  .external_slide__anchor {
    position: absolute;
    right: 9px;
    top: 127px;
    font-size: 11px;
  }

  .external_slide__bottom-gallery{
    width: 83% !important;
  }

  .ribbonNav {
    left: 0;
    width: 175px;
    margin-left: 25%;
  }

  .estdetails--video__gallery--right-arrow {
    top: 50px;
    right: -2px;
  }
}
@media (max-width: 767px) {
  .estdetails--gallery__large{
    border: none;
    background-color: transparent;
    padding: 0;
    height: auto;
  }

  .content{
    padding: 0;
  }

  .modal__video{
    padding-left: 15px;
    padding-right: 15px;
    height: 191px;
  }

  .slick-track a{
    width: auto !important;
  }

  #initSlick .slick-slider, .initSlick-multiple .slick-slider{
    margin-bottom: 0px !important;
  }

  .slick-prev, .slick-next{
    top: 37%;
  }

  .slick-slide {
    float: left;
    height: 300px;
    min-height: 1px;
    margin: 0px;
    padding-left: 0px;
    margin: 0px;
  }

  .slick-list {
    height: 230px;
  }

  .slick-prev {
    left: 25px !important;
    /* outline: solid red 1px; */
    position: absolute;
  }

  .slick-next {
    right: 25px !important;
    /* outline: solid red 1px; */
    position: absolute;
  }
}



@media (max-width: 320px) {
  .modal__video {
    padding-left: 15px;
    padding-right: 15px;
    height: 217px;
  }
}
