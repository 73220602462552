.underline {
  text-decoration: underline;
}

.sidebar-container {
    padding: 20px 0 0 0;
}

.my-travel__header-logo-desk{
    padding-top: 10px;
    padding-left: 0;
    margin-left: -7px;
    padding-bottom: 10px;
}

.list-font {
  font-size: 13px;
  font-size: 1.3rem;
}

.hr-table {
  margin: 0px;
}

.container-modification {
    margin-top: 0px;
    padding-left: 0;
    padding-right: 0;
}

.mobile-help {
    padding: 0;

    .display-none {
        display: none;
    }

    &__dropdown {
        &-help {
            clear: both;
            overflow: hidden;
            color: #FFF;
            padding-bottom: 10px;
        }

        &-button {
            cursor: pointer;
            background-color: #999999;
            color: #FFF;
            padding: 10px;
            margin-top: 10px;

            i {
                padding-left: 5px;
            }
        }

        &-list {
            display: none;
            margin: 0 10px;

            h2 {
                font-size: 13px;
                font-size: 1.3rem;
                color: #607ea8;
                cursor: pointer;
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }

    }

    &__number {
        background-color: #B3B3B3;
        color: white;
        padding: 10px;
        margin-bottom: 10px;

        i {
            padding: 0 5px;
        }
    }

    &__rooms {
        padding: 10px;
        margin-bottom: 10px;
        background-color: #F4F4F4 !important;

        h2 {
            font-size: 13px;
            font-size: 1.3rem;
            color: $black;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    &__fancybox {
        font-size: 12px;
        font-size: 1.2rem;
    }

}

.desktop-help {
    padding: 0;

    &__container {
        margin-top: 20px;
        margin-bottom: 20px;
        float: left;
        padding: 10px 0 10px 10px;
        border: 2px solid #FFF;
        outline: 1px solid #E9EAE5;
    }

    &__more {
        font-size: 12px;
        font-size: 1.2rem;
        text-decoration: underline;
        float: left;
        margin: 0 10px;
    }

    &__links {
        margin: 0 10px;

        h2 {
            font-size: 10px;
            font-size: 1em;
            font-weight: bold;
            color: $black;
        }

        ul {
            font-size: 12px;
            font-size: 1.2rem;
            padding-left: 20px;
        }
    }

    &__rooms {
        float: left;
        background-color: #F4F4F4 !important;
        padding-left: 20px;
        padding-right: 20px;

        h2 {
            font-weight: bold;
            font-size: 10px;
            font-size: 1em;
            color: $black;
        }

        ul {
            font-size: 12px;
            font-size: 1.2rem;
            padding-left: 20px;
        }
    }

    &__fancybox {
        font-size: 12px;
        font-size: 1.2rem;
        display: none;
        width: 500px;
        padding: 15px;

        h3 {
            font-weight: bold;
        }

        p {
            margin-top: 10px;
        }
    }
}

.more-about {
    &__fancybox {
        font-size: 12px;
        font-size: 1.2rem;
        display: none;
        width: 500px;
        padding: 15px;

        a{
            color: $black;
            font-size: 12px;
            font-size: 12px;
            float: right;
            display: block;
            text-decoration: underline;
        }

        a{
            color: $black;
            font-size: 12px;
            font-size: 12px;
            float: right;
            display: block;
            text-decoration: underline;
        }
        
        h2 {
            font-weight: bold;
        }

        h3 {
            font-weight: bold;
            font-size: 12px;
            font-size: 1.2rem;
        }

    }
}

.est-summary {

    padding-bottom: 10px;
    font-size: 12px;
    font-size: 1.2rem;

    .orange-text {
        color: #FFD03C;
    }

    &__img {
        background-color: #999999;
        outline: 1px solid #f1ede4;
        margin: auto;

        img {
            width: 100%;
        }
    }

    &__name {

        h4 {

            padding: 13px 10px;
            color: #607ea8;
            margin-top: 0;
            outline: 1px solid #f1ede4;
        }

        div {
            color: #653305;
        }

        a {
            text-decoration: underline;
        }
    }

    &__reviews {
        padding: 10px;
        outline: 1px solid #f1ede4;

        h3 {
            float: left;
            padding-right: 5px;
            font-size: 10px;
            font-size: 1em;
        }
        &-short {
            font-style: italic;
        }

        &-expand {
            display: block;
            text-align: right;
            text-decoration: underline;
        }

        &-hidden {
            font-style: italic;
        }

        &-comment {
            font-style: italic;
        }

        &-stars {
            i {
                color: #FFD03C;
            }
        }
    }

    &__review-total {
        padding: 10px;
        outline: 1px solid #f1ede4;
        margin: 0 0 1px;

        img {
            padding-bottom: 4px;
        }
    }

    &__creation-date {
        font-size: 9px;
        font-size: 0.9rem;

        span {
            padding: 0px;
            margin-top: 5px;
        }
    }
    
    &__reviewer {
        font-size: 10px;
        font-size: 1rem;

        div {
            float: left;
            margin-right: 5px;
        }

        span {
            font-style: italic;
        }
    }

    &__title {
        font-size: 10px;
        font-size: 1rem;
        padding-top: 3px;
    }

}

.enquiry-or-booking-title {
    margin: 20px 0 10px 0;

    &__title {
        padding: 0;

        h1 {
            font-weight: bold;
            font-size: 24px;
            font-size: 2.4rem;
            color: $black;
            margin: 0;
        }
    }

    &__reference {
        text-align: right;
        margin-top: 8px;
    }
}

.my-travel-tabs {

    padding-left: 0;
    margin-bottom: 8px;
    border-bottom: solid 1px $light-silver;
    padding-bottom: 9px;

    &__selected {
        border-top: 2px solid $pink;
        border-left: solid 1px $light-silver;
        border-right: solid 1px $light-silver;
        border-bottom: none;
        color: $pink;
        padding: 10px 10px;
        font-weight: bold;
        margin: 0;
        border-bottom: solid white;

        span {
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 5px;
            background-color: #F2F2F2;
            color: $darkgray;
            position: initial;
        }
    }

    &__unselected {
        text-decoration: inherit;
        color: $silver;
        padding: 10px 10px;
        font-weight: bold;
        margin: 0;
        border: solid 1px $light-silver;

        &:hover {
            color: inherit;
            border-top: 2px solid $pink;

        }

        span {
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 5px;
            background-color: #F2F2F2;
            color: $darkgray;
            position: initial;
        }
    }

}

.footer-slim {
    padding-left: 0;
    padding-right: 0;
    background-color: #F1EDE4;
    border: none;
    padding-bottom: 5px;
    padding-top: 0;
    text-align: center;
    font-size: 12px;
    font-size: 1.2rem;
    height: 35px;

    background: #f1ede4; /* Old browsers */
    background: -moz-linear-gradient(top,  #f1ede4 52%, #cec19f 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(52%,#f1ede4), color-stop(100%,#cec19f)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #f1ede4 52%,#cec19f 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #f1ede4 52%,#cec19f 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #f1ede4 52%,#cec19f 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #f1ede4 52%,#cec19f 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1ede4', endColorstr='#cec19f',GradientType=0 ); /* IE6-9 */

    &__block-bold {
        font-weight: bold;
        display: inline-block;
    }

    &__block-unbold {
        font-weight: bold;
        display: inline-block;
    }


}

.remove-menu-padding{
    padding-left: 0;
}


.my-travel__header {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    padding: 0 14px 0px 0;
    display: block;
    position: relative;
    background-color: #fbf9fa;
    border-bottom: 1px solid #EBEBEB;
}
.my-travel__header-logo-mobi {
    display: inline-block;
    float: none;
}
.my-travel__header-desk-pad {
    float: right;
    padding-top: 16px;
    span{
        color: $pink;
    }
}

.my-travel__search {
    display: inline-block;
    float: right;
    margin-top: 50px;
    position: relative;
    bottom: 20px;
    right: -207px;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
    .my-travel__header-logo-mobi {
        display: block;
    }
    .my-travel__header-logo-desk {
        display: none;
    }
    .my-travel__header-desk-pad {
        display: none;
    }

    .my-travel__search {
        bottom: 17px;
    }

    .mobile-help__number {
        font-size: 13px;
        font-size: 1.3rem;

        i {
            padding: 0px;
        }
    }

    .mobile-help__dropdown-button {
        font-size: 13px;
        font-size: 1.3rem;
    }
}
@media screen and (min-width: 480px) {
    .my-travel__header-logo-mobi {
        display: none;
    }
    .my-travel__header-logo-desk {
        display: block;
    }
}
@media screen and (max-width: 767px) {
    .my-travel__header-logo-mobi {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .my-travel__header-desk-pad {
        float: none !important;
    }

    #searchBar{
        right: 0px;
        margin-top: 10px;
    }

    .my-travel__border-dropdown{
        border-top: solid 1px #ebebeb;
    }
}

@media screen and (min-width: 0px) and (max-width: 480px) {

   .desktop-help {
        &__fancybox {
            width: initial;
        }
   }

   .more-about {
        &__fancybox {
            width: initial;
        }
   }

}

@media screen and (max-width: 515px) {
    .my-travel__search {
        font-size: 0.92em;
    }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
    .my-travel__search {
        bottom: 35px;
    }
}
//@media (max-width: 767px) {
//    .mobile-review {
//        h1 {
//            font-size: 14px;
//            font-size: 1.4rem;
//        }
//    }
//
//}