.information {

  &--pages {
    min-height: 602px;

    input[type=text] {
      width: 310px;
      margin-top: 2px;
    }

    input.input-half__name[type=text] {
      width: 150px;
    }

    select {
      width: 310px;
      margin-top: 10px;
      height: 26px;
    }

    textarea {
      width: 350px;
      height: 90px;
      margin-top: 10px;
    }

    h2 {
      font-size: 17px;
      font-size: 1.7rem;
      font-weight: bold;
    }

    ol {
      padding-left: 20px;
    }

    ul {
      padding-left: 20px;
    }

    &__aboutus {
      h3 {
        color: black;
      }

      &-image {
        background-image: url(/img/newlook/team.jpg);
        width: 100%;
        background-size: contain;
        height: 551px;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: 30px;
      }

      &__icons {
        text-align: center;
        margin: 20px 0;

        i {
          font-size: 45px;
          font-size: 4.5rem;
        }
      }
    }
  }

  h1 {
    font-weight: bold;
    color: black;
    font-size: 1.8rem;
    font-size: 18px;
    text-transform: uppercase;
  }
}

.login-control {
  display: none;
}

.contact {

  &__header-image {
    background-image: url(/img/newlook/contactus_header.jpg);
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    background-position: 0px;

    div {
      color: #ffffff;
      width: 100%;
      position: absolute;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-size: 20px;
      font-size: 2.0rem;

      h2 {
        font-size: 45px;
        font-size: 4.5rem;
        color: #ffffff;
      }
    }
  }

  &__minheight {
    min-height: 517px;
  }

  &-toggler {
    border: solid 1px;
    padding: 8px;

    &-details {
      display: none;
      margin-top: 10px;
    }
  }

  &__mobile {
    width: 49%;
    display: inline-block;
    text-align: left;

    &__arrow {
      width: 49%;
      display: inline-block;
      text-align: right;
    }

    &__padding {
      padding-left: 15px;
      padding-right: 15px;

    }
  }


  &--errors {
    color: #ff0000;
    font-size: 12px;
  }

  &--heading {
    font-weight: bold;
  }

  &--margin__top {
    margin-top: 7px;
  }

  &--required__text {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 13px;
    text-align: right;
  }
}

.pano--secondary_descriptions {
  border: dashed 1px #726f68;
  padding: 40px
}

#justcarhire_plugin {
  width: 100% !important;
  background-color: white !important;
  margin-left: 0 !important;
}

.signup_success_height {
  min-height: 500px;

  div {
    margin: auto;
    width: 100%;

    a {
      margin: auto;
      float: none;
      display: table;
    }

  }
}


.banking {
  &__headings {
    h4 {
      font-size: 17px;
      font-size: 1.7rem;
      font-weight: bold;
    }
  }

  p {
    margin: 0;
  }

  &--image {
    margin-top: 20px;

    &__section {
      border: solid 1px;
      background-color: #f2f2f2;
      padding-top: 20px;
    }

    &__margin {
      margin-top: -21px;
    }

    &__fnb {
      width: 73px;
    }

    &__headings {
      div {
        margin: auto;
        text-align: center;
        margin-top: 45px;
        font-weight: bold;
        color: $black;
        font-size: 16px;
        font-size: 1.6rem;

        p {
          font-weight: 400;
        }
      }
    }
  }
}


.voucher-retrieval {
  &--image {
    background-image: url('/img/newlook/voucher-hero.jpg');
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    background-position: 0px;


    div {
      color: #ffffff;
      width: 100%;
      position: absolute;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-size: 20px;
      font-size: 2.0rem;

      h2 {
        font-size: 45px;
        font-size: 4.5rem;
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {

  .banking--image__fnb {
    width: 100%;
  }

  .information {

    &--pages {
      input[type=text] {
        width: 100%;
        margin-top: 10px;
        height: 40px;
        margin-bottom: 5px;
      }

      select {
        width: 100%;
        margin-top: 10px;
        height: 40px;
        margin-bottom: 5px;
      }

      &__aboutus-image {
        background-image: url(/img/newlook/team.jpg);
        width: 100%;
        background-size: contain;
        height: 196px;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: 30px;
      }

      textarea {
        width: 100%;
        height: 90px;
        margin-top: 10px;
        margin-bottom: 5px;
      }

      .btn {

        margin-left: 19%;
      }
    }
  }
}

@media (max-width: 320px) {
}