.estdetails--padding{
  padding-left: 20px;
  padding-right: 20px;
}

.estdetails--summary{
  &__anchor{
    img{
      border: 5px solid #ffffff;
      border-radius: 4px;
      max-width: 100%;
    }
  }

  h4{
    margin-top: 10px;
    font-weight: bold;
    border-bottom: solid 1px #C3C3C3;
    font-size: 15px;
    font-size: 1.5rem;
    text-align: left;
    line-height: 2;
  }

  ol{
    list-style-type: none;
    padding-left: 0px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 2;
  }

  &__readmore{
    text-align: right;
    padding-bottom: 20px;
  }

  &__rightarrow{
    position: absolute;
    right: 27px;
    top: 35px;
  }

  &__leftarrow{
    position: absolute;
    left: 23px;
    top: 33px;
  }
}

.estdetails--location{

  h2{
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 0.8em;
    color: #92000A;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
  }

  h1{
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    color: black;
  }
}

.content{
  font-size: 14px;
  font-size: 1.4rem;
  outline: none !important;
}

.content--video{
  padding-top: 10px;

  img{
    width: 100%;
  }
}

.estdetails--gallery{
  border: solid 1px;
  padding: 10px;
  height: 165px;

  &__headings{
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__padding{
    padding: 0;
    margin: 0 !important;
  }
}

.estdetails--map{
  &__headings{
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.magnifying_glass{
  display: inline-block;
}

.estdetails--map{
  &__headings{
    margin-top: 20px;
    margin-bottom: 10px;
  }
}


/***overides for plugins****/
.magnifying_glass{
  display: inline-block;
}

.pictureRibbonNav{
  top: 134px;
}


.day_calendar_container {
  font-size: 10px;
  float: left;
  margin-right: 13px;
  margin-top: 4px;
}

.day_calendar_container .day_calendar {
  display: block;
  width: 50px;
  height: 60px;
  border: solid 1px black;
  font-weight: normal;
  text-align: center;
  line-height: 1.2;
  background-color: white;
  font-weight: bold;
  float: left;
  background-color: #E9E3D3;
  background-color: #E9E3D3;
  background-color: #fff;
}

.day_calendar_separater {
  float: left;
  margin: 22px 5px 0 5px;
  font-size: 13px;
  font-weight: bold;
}


.day_calendar_container .day_calendar {
  display: block;
  width: 50px;
  height: 60px;
  border: solid 1px black;
  font-weight: normal;
  text-align: center;
  line-height: 1.2;
  background-color: white;
  font-weight: bold;
  float: left;
  background-color: #E9E3D3;
  background-color: #E9E3D3;
  background-color: #fff;
}

.day_calendar_container .week_day {
  display: block;
  border-bottom: solid 1px black;
  margin-bottom: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #9F2E34;
  color: #fff;
  background-color: #F1EDE4;
  background-color: #fff;
  background-color: #ccc;
  text-transform: uppercase;
}

.slides {
  padding-left: 0;
}