// Font weights
$regular: 400;
$bold: 700;

// Base font
$base-font-family: 'Open Sans', sans-serif;
$base-font-weight: $regular;
$base-font-size: 14px;
$base-font-size-rem: 1.4rem;
$base-line-height: 1.4;

@import "../modules/all";