///*****************MY TRAVEL*********************/
.my-travel-header {
  padding: 0 14px 13px 0;
  display: block;
  position: relative;

  @include gradient-two-color-mirror(#ede4ce, #FFFFFF, 11%, 94%);
}

.my-travel-header--logo {
  display: inline-block;
  float: left;
  margin-left: 15px;
}

#logOutTrigger {
  height: 39px;
}

/*****************RFA MAIN SITE HEADER AND FOOTER*********************/
.header {
  &--gradient {
    @include gradient-two-color-mirror(#ede4ce, #ffffff, 11%, 94%);
    padding: 14px 14px 25px 0px;
    display: block;
    position: relative;

    img {
    }
  }

  &--logo {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &-text {
    @include base-font(12px, 1.2rem);
    text-align: left;
    color: $black;
    margin-top: 49px;
    padding-left: 90px;
  }

  &--input {
    position: relative;
    margin-top: 14px;
    text-align: right;

    input[type=text] {
      height: 25px;
      font-size: 14px;
      width: 250px;
    }

    button {
      background-color: #726F68;
      color: #ffffff;
      padding: 0;
      width: 25px;
      height: 25px;
      position: relative;
      border: none;

      i {
        font-size: 14px;
        position: relative;
        top: -1px;
      }

    }

    a {
      margin-top: 14px;
      float: left;
      padding-right: 0px;
      font-size: 1.1rem;
      font-size: 11px;
      text-decoration: underline;
    }

    ul {
      width: 250px;
    }
  }

  &--menu {
    &__padding {
      padding: 0;
    }

    &__row {
      padding: 0 10px;
    }

    &__seemore {
      padding: 10px 0px 10px 30px;
    }

    &__country-padding {
      padding: 0px 20px;
    }

    &__border-left {
      border-left: solid 1px #C7BBAF;
    }

    &__border-right {
      border-left: solid 1px #C7BBAF;
    }
  }

  &--search__textbox {
    width: 250px !important;
    height: 26px !important;
    border-radius: 0 !important;
    border: #a9a9a9 solid 1px !important;
    font-size: 14px !important;
    height: 25px !important;
    top: 0px !important;
  }

  &--search__button {
    background-color: #726F68;
    color: #ffffff;
    padding: 0;
    width: 25px;
    height: 25px;
    position: relative;
    border: none;

  }

  &--search__loading {
    position: absolute;
    top: 2px;
    left: 309px;
    width: 37px;
    height: 15px;
  }

  &--align__searchbox {
    text-align: right;
    margin-top: 14px;
  }

  &-credits {
    &__information {
      position: absolute;
      width: 130px;
      font-weight: normal !important;
      font-size: 14px !important;
      top: 5px;
      left: 40px;
      color: #727272 !important;
    }
  }

  &__membership-login {
    display: inline-block;
    position: absolute;
    font-weight: normal !important;
    font-size: 14px !important;
    top: 5px;
    right: 80px;
    color: #727272 !important;
    white-space: nowrap;

    span {
      white-space: nowrap;
      display: inline-block;
    }

    &-type {
      color: $edgars-red;
    }

    &-link {
      top: 15px;
    }
  }

  &-mobile {
    &-login {
      top: 14px;
      position: relative;
    }
  }
}

.mobile--search {
  display: none;
}

#search-div {
  display: none;

  input[type=text] {
    width: 100%;
    border-radius: 0;
    border-bottom: solid 1px #AB864F;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  ul {
    width: 92%;
  }

  .dropdown-menu {
    border-right: none;
    border-left: none;
  }
}

.ng-isolate-scope {
  li {
    a {
      cursor: pointer;
      margin-top: 0;

      span {
        color: $black;
      }
    }
  }
}

/*****New Header With enquiries***/
.header--search__button {
  background-color: #726F68;
  color: #ffffff;
  padding: 0;
  width: 25px;
  height: 25px;
  position: relative;
  border: none;
  //top: -1px;
}

.user-icon {
  position: absolute !important;
  left: 5px;
  color: #726f68 !important;
}

.header--search__new {
  outline: none;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  padding-left: 6px;
  font-weight: normal;
  margin-right: -4px;
  height: 30px;
  display: inline-block;
  width: 370px;
  margin-top: -1px;
  position: relative;
  top: -1px;
  left: 1px;
  width: 250px;
  width: 250px !important;
  border-radius: 0 !important;
  border: #a9a9a9 solid 1px !important;
  font-size: 14px !important;
  height: 25px !important;
  top: 0px !important;
  margin-top: 14px;
}

.new--search__loader {
  position: absolute;
  top: 4px;
  left: 222px;
  width: 37px;
  height: 15px;
  display: block;
  z-index: 9;
}

.header--search__loading {
  position: absolute;
  top: 19px;
  left: 342px;
  width: 37px;
  height: 15px;
  display: block;
  z-index: 9;
}

.traveller-search__absolute {
  position: absolute;
}

.traveller-search__position {
  position: relative;
  left: 256px;
}

.traveller__information {
  position: absolute;
  right: -310px;
  top: 15px;

  a {
    color: #726f68;
    text-decoration: none;
  }

  a:hover {
    color: #726f68;
    text-decoration: none;
  }
}

.traveller__name {
  text-transform: capitalize;
  font-weight: bold;
}

.my-travel__header-desk-pad span {
  color: #000;
  font-weight: bolder;
  font-size: 17px;
  font-size: 1.7rem;
}

.my-travel__header-desk-pad i {
  color: #726f68 !important;
  font-size: 23px;
  font-size: 2.3rem;
  position: relative;
  top: 3px;
  float: right;

}

.bubble__position {
  position: relative;
}

.bubble {
  position: absolute;
  width: 134px;
  padding: 0px;
  background: #FFFFFF;
  -moz-border-radius: 5px;
  right: -11px;
  top: 38px;
  padding: 5px 6px;
  z-index: 99;
  border: solid 1px;
}

.bubble a {
  color: #000;
}

.bubble a:hover {
  color: #000;
}

.bubble a:visited {
  color: #000;
}

.bubble--items div {
  display: inline-block;
}

.bubble--items div {
  display: inline-block;
}

.bubble--items__icons {
  text-align: right;
  color: #e5203c;
  float: right;
  font-size: 15px;
}

.bubble--items__icons i {
  font-size: 18px;
}

.bubble--email {
  width: 215px;
  position: absolute;
  right: 21px;
  top: 3px;
  font-size: 21px;
  height: 52px;
  text-align: right;
  margin-top: -5px;
  cursor: pointer;
}

.my-travel__header-desk-pad {
  cursor: pointer;
  padding-top: 0;
  margin-top: -1px;
}

.badge {
  background-color: #c0c0c0;
  position: relative;
  top: -10px;
  left: -11px;
  padding: 1px 4px;
}

.bubble--email__content {
  width: 269px;
  top: 37px;
  z-index: 99;
  left: -289px;
  position: absolute;
  background: #FFFFFF;
  /* -webkit-border-radius: 5px; */
  -moz-border-radius: 5px;
  /* border-radius: 5px; */
  padding: 5px 6px;
  z-index: 999;
  border: solid 1px;
}

.bubble--email__content-establishment {
  font-size: 12px;
  padding-top: 1px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  color: #000;
}

.bubble--email__content-establishment span {
  font-weight: normal;
  color: #6c6c6c;
}

.bubble--email__content-date {
  padding: 0px;
  font-size: 11px;
  padding-top: 3px;
  color: #726f68;
}

.bubble--email__content-image {
  padding: 0;
  margin-top: 5px;
}

.bubble--email__content-image img {
  max-width: 100%;
}

.bubble--email__content .row {
  margin-left: 0;
  margin-right: 0;
}

.bubble--email__viewmore {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

}

.bubble--email__viewmore a {
  color: #E62043;
}

.bubble--email__viewmore a:hover {
  color: #000;
}

.bubble--email__viewmore a:visited {
  color: #E62043;
}

.my-travel__header-desk-pad span {
  cursor: pointer;
  padding-right: 5px;
}

.header--input a {
  margin-top: 14px;
  /* float: left; */
  padding-right: 0px;
  font-size: 1.1rem;
  font-size: 11px;
  text-decoration: underline;
  position: inherit;
  right: 246px;
  white-space: inherit;
  width: 100%;
}

.header--search__button {
  background-color: #726F68;
  color: #ffffff;
  padding: 0;
  width: 25px;
  height: 25px;
  position: relative;
  border: none;
  //top: -1px;
}

@-moz-document url-prefix() {
  .header--search__button {
    top: 0px;
  }
}

.dropdown-menu {
  border: 1px solid #93785D;
  border-radius: 1px;
  padding-top: 0px;
  font-size: 12px;
  font-size: 1.2rem;
  width: 198px;
  z-index: 9999;
}

#logOutTrigger {
}

.dropdown-menu > li > a {
  white-space: inherit !important;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #DCDCDB;
}

.header--mobile--search-user {
  position: relative;
  left: -5px;
  top: -16px;

}

#logOutTriggerMytravel {
  height: 56px;
  width: 34px;
  position: absolute;
  right: 5px;
}

.bubble__position-myTravel {
  position: relative;
  top: 15px;
}

.bubble--email--mytravel {
  width: 215px;
  position: absolute;
  right: 37px;
  top: 3px;
  font-size: 21px;
  height: 52px;
  text-align: right;
  margin-top: -5px;
  cursor: pointer;
  color: #726f68 !important;
}

.header--mobile--search-user-myTravel {
  position: relative;
  left: -18px;
  top: -16px;
}

.bubble--email__content-myTravel {
  width: 269px;
  top: 37px;
  z-index: 99;
  left: -14px;
  position: absolute;
  background: #FFFFFF;
  -moz-border-radius: 5px;
  padding: 5px 20px;
  z-index: 999;
  border: solid 1px;
}

.bubble--email__content-myTravel-image {
  padding: 0;
  margin-top: 5px;
}

.bubble--email__content-myTravel-image img {
  width: 100%;
}

.bubble--email__content-myTravel-establishment {
  font-size: 12px;
  padding-top: 1px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  color: #000;
}

.bubble--email__content-myTravel-establishment span {
  font-weight: normal;
  color: #6c6c6c;
}

.bubble--email__content-myTravel-date {
  padding: 0px;
  font-size: 11px;
  padding-top: 3px;
  color: #726f68;
}

.bubble--email__content-homepage {
  left: -70px;
}

.homepage-user {
  margin-top: -17px;
}

.bubble--items__homepage {
  text-align: left;
}

@media (max-width: 991px) {
  .header--gradient {
    img {
      width: 100%;
      max-width: inherit;
    }

    button {
      img {
        width: 25px;
        position: absolute;
        right: 10px;
        top: 1px;
      }
    }
  }
  .header--input {
    input[type=text] {
      width: 139px;
    }

    button {
      margin-top: 1px;
      right: 10px;
    }
  }
  .header--search__loading {
    position: absolute;
    top: 4px;
    left: 200px;
    width: 37px;
    height: 15px;
    z-index: 99;
  }
  .nav-tabs > li > a:hover {
    padding: 0;
  }
  .header--input input[type=text] {
    position: initial;
  }
  .traveller-search__position {
    position: relative;
    left: 364px;
  }
  .traveller__information {
    position: absolute;
    right: -245px;
    top: 15px;
  }
  .header--search__loading {
    position: absolute;
    top: 4px;
    left: 200px;
    width: 37px;
    height: 15px;
    z-index: 99;
  }
  .banner--search__establishment {
    width: 166px !important;
    font-size: 12px !important;
    top: -1px !important;
  }
  .bubble--email__content {
    left: -275px;
  }
  .banner--search__establishment-myTravel {
    width: 166px !important;
    font-size: 12px !important;
    top: -1px !important;
  }
  .bubble--email__content-myTravel {
    left: -86px;
  }

}

@media (max-width: 767px) {

  .header--mobile--search-user {
    position: initial;
  }
  .header--mobile__subpages {
    width: 70px !important;
  }
  .my-travel-header--logo {
    padding-top: 10px;
  }
  .header--mobile {
    &__position {
      position: relative;
    }

    &__padding {
      padding: 0px 20px;
    }
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    height: auto !important;
    border-bottom-color: $light-brown !important;
    color: $links-normal;
    font-size: 1.3px;
    font-size: 1.3rem;
    background-color: $menu-background;
    background-color: rgba(239, 242, 215, 0.6);
    background-position: center bottom;
    padding: 0px;
    background-image: none;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: $links-normal;
  }
  .navbar-header {
    background-color: transparent;
    border-top: none;
    border-bottom: none;
  }
  .navbar-default .navbar-toggle {
    border: none;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #A67B51;
  }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: transparent;
    position: relative;
    top: -9px
  }
  .nav > li.divider {
    display: none;
  }
  .navbar-default .navbar-nav > li > a, .navbar-default .navbar-text {
    text-align: left;
  }
  .navbar-nav {
    margin: 0;
  }
  .navbar-nav .open .dropdown-menu {
    background-color: #f2f2f2;
  }
  .navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu > li > a {
    padding: 10px;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #ddd;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    background-color: $menu-background;
    display: block;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    background-color: #ffffff;
    border-bottom: $menu-borders 1px solid;
  }
  .dropdown:hover .dropdown-menu {
    display: inline;
    z-index: -999999;
    height: 0px;
    overflow: hidden;
  }
  .nav {
    overflow: hidden;
  }
  .banner--search__loading {
    position: absolute !important;
    top: 5px !important;
    right: 16px !important;
    width: 61px !important;
    left: 0px !important;

  }
  .header--logo {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .header--logo {
    padding: 5px;
  }
  .header--mobile--search i {
    font-size: 29px;
    color: #E62043;
    margin-top: -2px;

  }
  .form__account-details {
    position: relative;
    left: -16px !important;
  }
  .header--search__loading {
    position: absolute;
    top: 6px;
    left: 127px;
    width: 37px;
    height: 15px;
    display: block;
    z-index: 9;
  }
  .banner--search__establishment {
    height: 23px !important;
    margin-top: 2px;
    width: 180px !important;
    margin-left: 10px;
  }
  .single--logo {
    width: 35px !important;
    height: 35px !important;
  }
  .header--mobile--search {
    position: relative;
    right: 7px;
    top: 17px;
    z-index: 99;
  }
  #showRight div, #openMobileMenu div {
    position: absolute;
    left: 1px;
    top: 0px;
  }
  .header--mobile--search button {
    background-color: transparent;
    border: none;
  }
  .header--mobile--search button i {
    font-size: 24px;
    color: #888683;
    margin-top: -2px;
  }
  .active {
    background-color: transparent;
  }
  .nav > li > a:hover, .nav > li > a:focus {
    background-color: transparent;
  }
  .navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #bababa;

  }
  .navbar-toggle {
    position: relative;
    float: none;
    margin-right: 15px;
    padding: 5px 2px;
    margin-top: 0px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .navbar-nav {
    background-color: #ffffff;
    padding: 0 15px;
  }
  .header--gradient__homepage {
    padding: 0px 0px 0px 0px;
  }
  .navbar-collapse {
    padding: 0px;
  }
  .navbar-default .navbar-toggle {
    border: none;
    position: absolute !important;
    top: -83px !important;
    left: 294px !important;

  }
  #searchBar {
    right: 0px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;
    height: 34px;
  }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    border: none;
    position: absolute !important;
    top: -83px !important;
    left: 294px !important;

  }
  .header--search__button {
    top: -25px;
    left: 30px;
  }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    top: -46px;
    left: 29px;
  }
  .header--align__searchbox {
    text-align: right;
    margin-top: 14px;
    position: relative;
    left: -6%;
  }
  .banner--search__establishment {
    width: 95% !important;
    top: 0 !important;
    font-size: 15px !important;
  }
  .my-travel__header-logo-mobi {
    padding-top: 3px;
    padding-bottom: 0px;
  }
  .navbar-toggle {
    float: right;
  }
  .slide-menu__close {
    position: absolute;
    right: 0;
    background-color: transparent;
    border: none !important;
    top: 4px !important;
  }
  .slide-menu__close i {
    font-size: 17px !important;
    color: #000 !important;
  }
  .header--search__button {
    left: 186px;
    position: absolute;
    top: -1px;
    height: 23px;
    z-index: 0;
  }
  .badge__mobile {
    position: absolute;
    left: 20px;
    top: -6px;
    z-index: 99;
  }
  .navbar-default .navbar-toggle {
    display: none;
  }
  .banner--search__establishment {
    margin-top: -1px !important;
    left: 0px !important;
  }

  .banner--search__establishment-myTravel {
    margin-top: 16px !important;
    left: -3px !important;
  }

  .header--search__button-myTravel {
    left: 186px;
    position: absolute;
    top: 16px;
    height: 23px;
    z-index: 0;
  }
}

@media (max-width: 320px) {
  .header--search__button {
    position: initial;
    float: right;
    margin-top: -24px;
    margin-left: 87px;
    z-index: 999;
    position: absolute;
    top: 25px;
    left: 81px;
  }
  .banner--search__establishment {
    height: 23px !important;
    margin-top: 2px;
    width: 180px !important;
    margin-left: 10px;
    height: 25px !important;
    margin-top: 1px;
  }
  .navbar-default .navbar-toggle {
    border: none;
    position: relative;
    top: -46px;
    left: 29px;
  }
  .header--search__button {
    left: 99px;
    position: absolute;
    top: 24px;
    height: 23px;
  }
  .header--align__searchbox {
    text-align: right;
    margin-top: 14px;
    position: initial;
    left: -15%;
  }
  .navbar-default .navbar-toggle {
    border: none;
    position: absolute;
    top: -83px !important;
    left: 247px !important;
  }
  .banner--search__establishment {
    float: left;
    position: initial;
    top: 15px;
    width: 97% !important;
  }
  .navbar-default .navbar-toggle {
    border: none;
    position: absolute;
    top: -83px !important;
    left: 247px !important;
  }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    border: none;
    position: absolute;
    top: -83px !important;
    left: 247px !important;
  }
  .my-travel__header-logo-mobi {
    padding: 3px 0 0 0;
  }
  .header--mobile--search {
    position: absolute;
    right: -5px;
    top: 16px;
    z-index: 99;
  }
  .navbar-default .navbar-toggle {
    border: none;
    position: relative;
    top: -46px;
    left: 29px;
  }
  .header--align__searchbox {
    text-align: right;
    margin-top: 14px;
    position: initial;
    left: -15%;
  }
  .header--search__button {
    top: 25px;
    left: 102px;
  }
  .navbar-default .navbar-toggle {
    border: none;
    position: absolute;
    top: -83px !important;
    left: 247px !important;
  }
  .banner--search__establishment {
    float: left;
    position: initial;
    top: 15px;
    width: 97% !important;
  }
  .navbar-default .navbar-toggle {
    border: none;
    position: absolute;
    top: -83px !important;
    left: 247px !important;

  }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    border: none;
    position: absolute;
    top: -83px !important;
    left: 247px !important;
  }
  .my-travel__header-logo-mobi {
    padding: 3px 0 0 0;
  }
  .header--mobile--search-myTravel {
    position: absolute !important;
    right: -9px !important;
    top: 1px !important;
    z-index: 99 !important;
  }
  .header--search__button-myTravel {
    top: 23px !important;
    left: 98px !important;
    height: 25px !important;
  }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    display: none !important;
  }
  .header--align__searchbox-myTravel {
    text-align: right !important;
    margin-top: 14px !important;
    position: relative !important;
    left: -6% !important;
  }
  .banner--search__establishment-myTravel {
    width: 95% !important;
    top: 0 !important;
    font-size: 15px !important;
  }
  .my-travel__header-logo-mobi {
    padding-top: 3px !important;
    padding-bottom: 0px !important;
  }
  .banner--search__establishment {
    margin-top: 0px !important;
    left: 3px !important;
  }

  .banner--search__establishment--mytravel {
    left: 0px !important;
  }

  .header--search__button-myTravel {
    left: 100px !important;
    position: absolute !important;
    top: 25px !important;
    height: 23px !important;
    z-index: 0 !important;
  }
}




