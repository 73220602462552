@import "../modules/colours";

.edgars-club-logo-block {
  width: 18px;
  height: 18px;
  background-color: $edgars-red;
  display: inline-block;
  top: 2px;
  position: relative;
}

