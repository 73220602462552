.tab_slide {
  float: left;
  clear: left;
  list-style: none;
  width: 629px;
  margin: 0 0 0 5px;
}

.tab_slide li:FIRST-CHILD {
  border-left: solid 1px $silver;
  margin-left: 2em;
  border-top: solid $pink 2px;
  border-right: solid 1px $silver;

}

.tab_slide li {
  padding: 1px 5px;
  float: left;
  border: solid 1px $silver;
  border-bottom: none;
  border-left: none;
  font-size: 11px;
  background-color: #F1EDE4;
  margin-bottom: -1px;
  position: relative;
  top: 1px;
  width: 80px;
  text-align: center;
}

.tab_slide li.selected {
  position: relative;
  top: 1px;
  padding-top: 2px;
  margin-bottom: 0;
  font-weight: bold;
}

.tab_slide li a {
  cursor: pointer;
}

.tab_slide li.selected a {
  cursor: default;
  font-weight: bold;
}

#panoRibbon {
  width: 566px;
}


img.panoThumb {
  width: 127px;
  background-color: white;
  padding: 3px;
}

.pano_tab .caption {
  font-size: 10px;
  font-weight: normal;
}

.pano_tab {
  height: 55px;
  position: relative;
  width: 560px;
}

.pano_tab .surround_shadow_dark {
  margin-left: 2px;
}

.pano_tab .sli {
  padding-left: 2px;
}

.tab0Nav, .tab1Nav, .tab2Nav, .tab3Nav {
  top: 93px;
  left: 200px;
  width: 150px;
}

#pano_dialog {
  background-color: #F1EDE4;
}

img.panoSelected {
  background-color: white;
  border: 1px solid black;
  padding: 3px;
}

.pano_tab .caption {
  font-size: 11px;
}

div.pano_border {
  border: 5px solid white;
  width: 640px;
  height: 430px;
  overflow: hidden;
}

.now_playing_hidden {
  display: none;
}

.now_playing_pano {
  background-color: black;
  bottom: 26px;
  color: white;
  font-size: 11px;
  font-weight: bold;
  height: 15px;
  margin: 0 0 0 4px;
  opacity: 0.6;
  text-align: center;
  width: 119px;
  background-image: none;
  position: absolute;
}

div.pano_heading {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

div.pano_container {
  background-color: #F1EDE4;
  width: 660px;
  padding: 0;
}

div.tab_slide {
  margin-top: 0px;
  width: 629px;
}

#pano_dialog{
  background-color: $white !important;
}

div.pano_container{
  background-color: $white !important;
}

//div.slide{
//  background-color: $white;
//}


div.mini_slide{
  border: solid 1px #bababa;
}

.tab_slide li{
  background-color: $white;
  border: $silver;
}

div.slide{
  //border: solid 1px $silver !important;
}
