@-moz-document url-prefix() {
  .banner--search__establishment {
    position: relative;
    top: -1px;
  }
}

.form {
  &__account-details {
    position: relative;
    top: 14px;
    left: 112px;
  }

  &__position {
    position: absolute;
    z-index: 99;
  }
}

/********placeholders*****/
::-webkit-input-placeholder {
  color: #c0c0c0;
}

:-moz-placeholder { /* Firefox 18- */
  color: #c0c0c0;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #c0c0c0;
}

:-ms-input-placeholder {
  color: #c0c0c0;
}

.articles--padding__left {
  padding-left: 15px;

}

.articles--padding__right {
  padding-right: 15px;

}

.articles--mobile__padding {
  a {
    padding-top: 10px;
    text-decoration: underline;
    display: block;
  }

  a:visited {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: underline;
  }
}

.reviewer--name {
  i {
    font-size: 14px !important;
    font-size: 1.4rem !important;
    font-style: italic !important;
    color: #4f4d4e !important;
    font-weight: bold;
  }
}

/***easing on image***/
.banner {
  padding: 0px;
  background-repeat: no-repeat;
  width: 100%;
  height: 508px;
  position: relative;
  margin-top: -7px;

  &__account-details {
    padding: 16px 5px;
    text-align: right;

    a {
      color: $black;
    }

    a:hover {
      color: $black;
      text-decoration: none;
    }

    &-login {
      a:hover {
        text-decoration: underline;
      }

      .edgars-club-logo-block {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__scrollTo {
    position: absolute;
    z-index: 99;
    left: 48%;
    top: 54%;
  }

  &--text {

    &__cta {
      font-size: 44px;
      font-size: 4.4rem;
      font-weight: bolder;
      text-align: center;
      color: #ffffff;
      padding-top: 312px;
    }

    &__supporting {
      font-size: 17px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      margin-bottom: 25px;
      margin-top: -12px;
    }
  }

  &--search {
    text-align: center;
    margin-left: -16px;
    position: relative;

    ul {
      .dropdown-menu {
        overflow: hidden;
        width: 320px;
        overflow-y: hidden; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;

        &.active {
        }
      }
    }

    &__loading {
      position: absolute;
      top: 5px;
      left: 49%;
      width: 61px;

    }

    &__establishment {
      border: solid 1px $green;
      outline: none;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
      padding-left: 6px;
      font-size: 1.4rem;
      font-size: 14px;
      font-weight: normal;
      margin-right: -4px;
      height: 30px;
      display: inline-block;
      width: 370px;
      font-family: arial, helvetica, sans;
      margin-top: -1px;
      position: relative;
      top: -1px;
      left: 1px;
    }

    @-moz-document url-prefix() {
      &__establishment {
        border: solid 1px $green;
        outline: none;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        padding-left: 6px;
        font-size: 1.4rem;
        font-size: 14px;
        font-weight: normal;
        margin-right: -4px;
        height: 30px;
        display: inline-block;
        width: 370px;
        font-family: arial, helvetica, sans;
      }
    }

    &__checkin {
      border-top: solid 1px $green;
      border-bottom: solid 1px $green;
      border-left: none;
      border-right: none;
      outline: none;
      padding-left: 6px;
      font-size: 1.5rem;
      font-size: 15px;
      font-weight: normal;
      margin-right: -2px;
      height: 30px;
      display: inline-block;
      width: 90px;
      border-left: solid 1px $green;

    }

    &__checkout {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      border: solid 1px $green;
      outline: none;
      padding-left: 6px;
      font-size: 1.5rem;
      font-size: 15px;
      font-weight: normal;
      margin-left: -3px;
      height: 30px;
      display: inline-block;
      width: 90px;

    }

    button {
      background-color: $green;
      color: #ffffff;
      font-weight: bold;
      padding: 3px 17px 2px 17px;
      border: none;
      border-radius: 0px;
      outline: none;
      position: relative;
      top: -2px;
      font-size: 17px;
      left: 10px;
    }

    .btn.focus, .btn:focus, .btn:hover {
      background-color: $green;
      color: #ffffff;
    }
  }

}

.search--box {

  a {
    padding: 6px 25px;
    display: block;
    cursor: pointer;
    color: #000000;
    text-decoration: none;
    width: 100%;
  }

  a:hover {
    background-color: #DCDCDB;
    color: #ffffff;
    text-decoration: none;
  }

  &__viewmore {
    a {
      font-size: 1.3rem;
      font-size: 13px;
      padding: 8px 73px;
      display: block;
      cursor: pointer;
      color: #000000;
      font-style: italic;
      text-align: center;
      margin-top: 5px;
    }

    a:hover {
      font-size: 1.3rem;
      font-size: 13px;
      padding: 8px 73px;
      display: block;
      cursor: pointer;
      color: #444242;
      font-style: italic;
      text-align: center;
      margin-top: 5px;
      text-decoration: none !important;
    }
  }
}

.search--box__magnifyingglass {
  width: 5px;
  display: inline-block;
  padding-right: 15px;
  color: #000000
}

.homeheadings {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 13px;

  h1, .sectionHeading {
    font-size: 28px;
    font-size: 2.8rem;
    font-weight: bold;
    color: black;
    text-align: center;
    margin: 0px;
    padding-bottom: 10px;
    margin-top: 30px;
  }

  span {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 10px;
    display: block;
  }

  &__topheading {
    padding-top: 100px;
  }
}

.typeahead-group-header {
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 15px;
  padding: 4px 9px;
  color: #000000;
  margin-top: 4px;
  background-color: #ffffff;
}

.active {
  background-color: #DCDCDB;
  color: #ffffff;
  text-decoration: none;
}

.homepage {
  &--reviewer {
    text-align: center;

    span {
      color: #e62043;
      font-weight: bold;
      font-size: 18px;
      font-size: 1.8rem;
    }

    i {
      color: #989590;
      font-size: 45px;
      font-size: 4.5rem;
    }

  }

  &--links {
    span {
      font-size: 14px;
      font-size: 1.4rem;
      color: #e62043;
      font-weight: bold;
      text-transform: uppercase;
    }

    a {
      text-decoration: underline;
      font-size: 13px;
      font-size: 1.3rem;
      color: $black;
    }

    a:hover {
      font-size: 13px;
      font-size: 1.3rem;
      color: $pink;
    }

    a:visited {
      font-size: 13px;
      font-size: 1.3rem;
      color: $black;
    }

  }
}

.articles__images {
  img {
    width: 100%;
  }
}

.video-placeholder {
  img {
    width: 100%;
  }
}

.ytp-button:not([aria-disabled=true]):not([disabled]):not([aria-hidden=true]) {
  cursor: pointer;
}

//
.ytp-large-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
  -moz-transition: opacity .25s cubic-bezier(0.0, 0.0, 0.2, 1);
  -webkit-transition: opacity .25s cubic-bezier(0.0, 0.0, 0.2, 1);
  transition: opacity .25s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.ytp-button {
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  text-align: inherit;
  font-size: 100%;
  font-family: inherit;
  cursor: default;
  line-height: inherit;
}

.ytp-large-play-button-bg {
  transition: fill .1s;

  &:hover {
    fill: #cc181e;
    transition: fill .1s
  }
}

#homepage-video {
  &:hover {
    .ytp-large-play-button-bg {
      fill: #cc181e;
      transition: fill .1s;
      z-index: -1;
    }
  }
}

.no--underline {
  a {
    text-decoration: none !important;
  }

  a:hover {
    text-decoration: none !important;
  }

  a:visited {
    text-decoration: none !important;
  }
}

.images {
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  font-size: 24px;
  font-size: 2.4rem;
  color: $background;
  padding: 15px;
  line-height: 0.8em;
  font-weight: $bold;

  &__content {
    margin-top: 10px;
    margin-bottom: 30px;

  }

  &--padding__left {
    padding-left: 30px;
  }

  &--padding__right {
    padding-right: 30px;
  }

  &__row {
    overflow: hidden;
    margin-bottom: 30px;

    a {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: underline;
    }

    a:visited {
      text-decoration: underline;
    }
  }

  &__padding--none {
    padding: 0;
  }

  &--padding__left15 {
    padding-left: 15px;
  }
}

.images__capetown {
  @extend .images;
  background-image: url('/img/homepage/capeTown.jpg');
  height: 283px;
}

.images__durban {
  @extend .images;
  background-image: url('/img/homepage/durban.jpg');
  height: 283px;
}

.images__johannesburg {
  @extend .images;
  background-image: url('/img/homepage/joburg.jpg');
  height: 283px;
}

.images__knysna {
  @extend .images;
  background-image: url('/img/homepage/knysna.jpg');
  height: 283px;
}

.images__pe {
  @extend .images;
  background-image: url('/img/homepage/portElizabeth.jpg');
  height: 283px;
}

.images__pretoria {
  @extend .images;
  background-image: url('/img/homepage/pretoria.jpg');
  height: 283px;

}

.images__hartbeespoort {
  @extend .images;
  background-image: url('/img/homepage/hartbeespoort.jpg');
  height: 283px;
}

.images__stlucia {
  @extend .images;
  background-image: url('/img/homepage/stLucia.jpg');
  height: 283px;
}

.images__bellabella {
  @extend .images;
  background-image: url('/img/homepage/belaBela.jpg');
  height: 283px;
}

.images__kruger {
  @extend .images;
  background-image: url('/img/homepage/kruger.jpg');
  height: 283px;
}

.images__drakensburg {
  @extend .images;
  background-image: url('/img/homepage/drakensburg.jpg');
  height: 283px;
}

.images__winelands {
  @extend .images;
  background-image: url('/img/homepage/winelands.jpg');
  height: 283px;
}

.images__beachbreaks {
  @extend .images;
  background-image: url('/img/homepage/beachBreaks.jpg');
  height: 283px;
}

.images__safaris {
  @extend .images;
  background-image: url('/img/homepage/safaris.jpg');
  height: 283px;
}

.images__gardenroute {
  @extend .images;
  background-image: url('/img/homepage/gardenRoute.jpg');
  height: 283px;

}

.images__golf {
  @extend .images;
  background-image: url('/img/homepage/golfingHolidays.jpg');
  height: 283px;

}

.images__detinations {
  @extend .images;
  background-image: url('/img/homepage/topExperiences.jpg');
  height: 283px;
}

.header--gradient__homepage {
  padding: 0px 14px 0px 0px;
  background-color: #fbf9fa;
  border-bottom: solid 1px #ebebeb;
  margin-bottom: 7px;
  z-index: z-index(header);
}

.footer--homepage__padding {
  padding-top: 30px;
}

.youtube--play {
  position: absolute;
  left: 44%;
  font-size: 93px !important;
  top: 28%;
  color: #000000;
}

.youtube--play:hover {
  color: #b9171c;
}

.banner--secondlogo__position {
  padding: 10px 41px 10px;
}

.banner--text__cta {
  padding: 0px;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
}

.banner--text__supporting {
  text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
}

.banner--bg {
  padding: 20px 0px;
}

.banner {
  height: 566px;
  margin-top: 0px;
  background-size: cover;
  background-position: 50% 50%;
}

.banner--secondlogo__position {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  padding: 10px 32px 10px;
}

.header--second__logo {
  margin-right: 7px;
}

.banner--text__cta-position {
  position: absolute;
  bottom: -50px;
  width: 100%;
}

.banner--text__cta-margin {
  margin: auto;
}

.banner__scrollTo {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 74%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(26%, rgba(255, 255, 255, 1)), color-stop(65%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(0, 0, 0, 1)));
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 74%);
  background: -o-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 74%);
  background: -ms-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 74%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0.1) 30%, rgba(255, 255, 255, 0) 75%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0);
}

.my-travel__header-desk-pad--homepage {
  position: relative;
}

#edgarsPopup {

  img {
    margin: 15px auto 0 auto;
    display: block;
  }

  h2 {
    line-height: 1.5em;
    margin-top: 0;
  }

  .modal-dialog {
    max-width: 475px !important;
  }

  .modal-content {
    border-width: 2px;
    border-color: $grey;
  }

  .modal-header {
    border-bottom: none;
    padding: 10px 15px 10px 15px;
  }

  .modal-body {
    padding: 0 30px;
  }

  .modal-footer {
    border-top: none;
    padding: 10px 30px;
    text-align: center;
  }

  .club-popup {
    &__membership-type {
      height: 120px;
      margin: 10px 10px 25px 10px;
      padding-top: 3px;
      background-color: $black;

      h2 {
        color: $edgars-red;
        margin-top: 5px;
      }

      h3 {
        color: $white;
        font-weight: 500;
        margin-top: 10px;
      }
    }

    &__create-account {
      padding: 10px 0 20px 0;
      font-size: 16px;
      font-size: 1.6rem;
    }
  }
}

@media (min-width: 768px) {

}

@media (max-width: 991px) {
  .banner {
    &--text {
      &__cta {
        padding-top: 263px;
      }
    }
  }

  .banner--search__establishment {
    width: 200px !important;
  }
}

@media (max-width: 767px) {
  .homepage--links__mobile-padding {
    padding-left: 30px;
  }
  .mobile--remove__padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .banner--text__supporting {
    display: none;
  }
  .banner {
    height: 204px;
    padding-left: 7px;
    margin-top: -7px;

    &--mobilepadding__images {
      padding-bottom: 15px;
    }

    &--search__establishment {
      width: 64%;
    }

    ul {
      .dropdown-menu {
        overflow: hidden;
        width: 80%;
        overflow-y: hidden; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;

        &.active {

        }
      }
    }
  }
  .banner__scrollTo {
    display: none;
  }
  .homepage--links__removepadding {
    padding-right: 0px;
    padding-right: 0px;
  }
  .homepage--links__removepadding {
    padding: 0;

  }
  .homeheadings {
    padding-top: 0 !important;

    span {
      display: none;

    }

    h1 {

    }
  }
  .images {

    &__content {
      margin-top: 10px;
    }

    &--padding__left {
      padding-left: 15px;
    }

    &--padding__right {
      padding-right: 15px;
    }

    &__row {
      overflow: hidden;
      margin-bottom: 15px;
    }

    &__padding--none {
      padding: 15px;

    }

    &--padding__left15 {
      padding-left: 15px;
    }
  }
  .banner--text__cta {
    font-size: 22px;
    padding-top: 96px;
  }
  .articles {

    &__images {
      img {
        width: 100%;
        padding-top: 30px;
      }
    }

    &--mobile__padding {
    }
  }
  .homepage--links {

    &__open {
      background-color: #f1ede4;
      font-weight: bold;
    }

    &__toggler {
      width: 100%;
      border-bottom: solid 1px #d1d2ca;
      display: block;
      background-color: $black;
      color: #4f4f4f;
      padding: 10px;
      position: relative;
      margin-top: 1px;
    }

    span {
      font-style: italic;
    }

    i {
      float: right;

    }

    span:after {
      border-bottom: solid 1px #ffffff;
    }

    a {
      padding-left: 30px;
    }

    &__toggle {
      display: none;
      background-color: #ffffff;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  #search-div input[type=text] {
    width: 87%;
    border-radius: 0;
    border-bottom: solid 1px #AB864F;
    border-top: none;
    border-left: none;
    border-right: solid 1px #AB864F;
  }
  .banner--search__loading {
    position: absolute !important;
    /* top: 5px !important; */
    right: 54px !important;
    width: 28px !important;
    left: initial !important;
  }
}

@media (max-width: 480px) {
  #edgarsPopup {

    .modal-body {
      padding: 0 10px;
    }

    img {
      margin-top: 10px;
    }

    .club-popup {

      .edgars-popup__edgars-logo {
        height: 45px;
      }

      .edgars-popup__rfa-logo {
        height: 35px;
      }

      &__membership-type {
        height: 100px;
      }

      &__cta {
        .btnenquire, .btnbooknow {
          padding: 5px;
          font-size: 13px;
          font-size: 1.3rem;
          line-height: 2rem;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .banner {
    height: 275px;
  }

  .homeheadings h1 {
    font-size: 23px;
    font-size: 2.3rem;
  }
  .banner--search__establishment {
    width: 56%;
  }

  .banner--text__cta {
    font-size: 22px;
    padding-top: 193px;
  }

  .footer--textsection__col1 {
    padding-left: 20px;
    height: auto !important;
    padding-bottom: 30px;
  }
}



