// Javascript actions to take note of
// remove-this
// display-view

.gradient__footer {
	height: 35px;
	background: #f1ede4; /* Old browsers */
	background: -moz-linear-gradient(top,  #f1ede4 52%, #cec19f 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(52%,#f1ede4), color-stop(100%,#cec19f)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #f1ede4 52%,#cec19f 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #f1ede4 52%,#cec19f 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #f1ede4 52%,#cec19f 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #f1ede4 52%,#cec19f 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1ede4', endColorstr='#cec19f',GradientType=0 ); /* IE6-9 */
}



.holdPrefix{
	font-size: 18px;
	font-size: 1.8rem;
}

.email-enquiry {


	&--savings{
  		outline: 1px solid #E9EAE5;
		margin-bottom: 10px;
		padding: 0;


		&__background{
			background-color: #F4F4F4 !important;
			padding: 0px 15px 15px 15px;
			color: $black;

			span{
				float: right;
			}

			h2{
				font-size: 15px;
				font-size: 1.5rem;
				font-weight: bold;
				color: $black;
			}

			a{
				float: right;
				font-size: 12px;
				font-size: 1.2rem;
				text-decoration: none;
			}

			a:visited{
				float: right;
				font-size: 12px;
				font-size: 1.2rem;
				text-decoration: none;
			}

			a:hover{
				float: right;
				font-size: 12px;
				font-size: 1.2rem;
				text-decoration: underline;
			}
		}


		&__costs{
			border-top: 1px dashed;
		   margin-top: 8px;
		   display: block;
		   padding-top: 8px;

			&-color{
				color: $pink;
				font-weight: bold;
				padding-left: 0px;
			}
		}
	}

	&__total-before-discount{
		color: $black;
		font-size: 15px;
		font-size: 1.5rem;
		//padding: 0px 19px;

		span{
			font-size: 15px;
			font-size: 1.5rem;
			color: #e5203c;
			font-weight: bold;
			margin-top: 5px;
		}

		&--border{
			border-top: solid 1px black;
		}

		&--value {
			padding-left: 0px;
		}
	}

	&__total-discount{
		font-size: 11px;
		font-size: 1.1rem;

		&-extra-info{
			color: $black;
			font-size: 12px;
			padding-top: 5px;
   			display: block;
			font-weight: bold;
		}
	}



	&__container {
	   margin: 15px;
	}
  	&__content {
  		margin: 0;
  		background-color: #FFF;
  		padding-bottom: 20px;
	}
	&__secondary-nav {
		padding: 0 15px 0 0;

	}
	&__inbox {
		margin: 0;

		&-button {
			padding: 3px;
			padding-right: 9px;
			padding-bottom: 0;
			margin-top: 10px;
			margin-bottom: 5px;
		  	background-color: $light-silver;
		  	color: $black;
		  	border: none;
		  	font-size: 0.9em;


		  	&:hover {
		  		background-color: $darkgray;
  				color: $white !important;
		  	}

		  	&:focus {
  			  	background-color: $darkgray;
  				color: $white !important;
		  	}

		}

		&-chevron {
			margin: 0;
			font-size: 11px;
			font-size: 1.1rem;
		  	height: 27px;
		  	padding-top: 6px;
		  	padding-bottom: 0px;
		  	width: 22px;
		}
		&-divider {
		  	margin-top: 5px;
			margin-bottom: 10px;
		}

		> div {
			padding: 0;
		}
	}
	&__error {
	    margin: 20px 20px 20px 20px;
	    padding: 10px;
	    border: 1px solid #92000A;
	    color: #92000A;
	    background-color: #FFF58F;
	    text-align: center;
	    font-size: 14px;
	}
	&__summary {
		padding: 10px;
		margin-top: 10px;
		margin-bottom: 10px;

		&-header {
			padding: 0;
			padding-bottom: 10px;
			h2 {
				font-weight: bold;
				font-size: 16px;
				font-size: 1.6rem;
			}

			&:visited {
				color: #5C350E;
			}
		}
		&-details {
			padding: 0;
			font-size: 12px;
			font-size: 1.2rem;
		}
		&-divider {
		  	font-weight: bold;
			padding: 0;
			font-size: 1.3rem !important;
			text-decoration: underline;
			margin-bottom: 5px;
			margin-top: 5px;
		}
		&-billing {
			padding: 0;
			float: left;
			font-size: 12px;
			font-size: 1.2rem;
		}

	}
	&__border {
	}
	&__map {
	    margin-bottom: 10px;
	    margin-top: 20px;
	    padding-bottom: 15px;
	}
	&__reply {
		padding: 0;
		margin: 0;

		&-header {
			float: left;
			margin: 0;
			padding: 0;
		    padding-top: 10px;
    		padding-right: 10px;

    		h2 {
    			font-size: 20px;
    			font-size: 2.2rem;
    			font-weight: bold;
    		}
		}

		&-clock {
			float: left;
			margin: 0;
			padding: 10px 0 0 0;

			h2 {
				font-size: 14px;
				font-size: 1.4rem;
				color: #999999;
				margin: 0;
			    font-weight: normal;
			}

			i {
				padding-right: 4px;
				font-size: 16px;
				font-size: 1.6rem;
			}
		}

	  	&-box {
			margin: 0;
	  		margin-bottom: 30px;
	  		margin-top: 5px;
	  		position: relative;
	  		padding: 0;
		  	border: 1px solid #CCC;
			background-color: #FBF9FA;

	  		&-bg {
				min-height: 250px;
				margin: 0;
	  		}

	  		&-content {
	  			background-color: #FBF9FA;
	  			padding: 10px;
	  			padding-right: 15px;
			  	color: $black;
			  	margin-right: 5px;
	  		}
		  	&-header {
				font-weight: bold;
				padding-bottom: 10px;
				float: left;
			}
		  	&-triangle {
				border-color: transparent #EBEBEB transparent transparent;
				border-style: solid;
				border-width: 85px 30px 85px 0;
				height: 0px;
				width: 0px;
				float: left;
			}


		  	&-price {
			  margin-bottom: 25px;
			  margin-top: 5px;
			  float: left;
			  background-color: #EBEBEB;
			  padding: 20px;
			  text-align: center;
			}
		  	&-tick {
			  padding-bottom: 5px;
			  img {
				margin-top: 10px;
			  }
			  i{
				font-size: 40px;
				color: $green;
			  }

			  span {
				font-weight: bold;
				color: $green;
			  }
			}
		  	&-hold {
			  padding-bottom: 10px;
			  padding-top: 10px;
				color: $black;
			}
		  	&-value {
			  padding-bottom: 5px;
			}
			&-total {
			  color: #4f4e4e;
			  font-size: 18px;
			  font-size: 1.8rem;
			}
			&-rand {
			  font-size: 1.5em;
			  color: $pink;
			  font-weight: bold;
			}
		  	&-button {
			  color: #ffffff !important;
			  background-color: $green;
			  padding: 6px 20px;
			  font-size: 15px;
			  font-size: 1.5rem;
			  border-radius: 0;
			  border: none;
			  text-transform: uppercase;
			  font-weight: bolder;
			  &:hover {
				color: #FFF;
				  font-size: 15px;
				  font-size: 1.5rem;
			  }
			  &:focus {
				color: #FFF;
				  font-size: 15px;
				  font-size: 1.5rem;
			  }

			}
		  	&-query {
			  font-weight: bold;
			  margin: 0;
			  font-size: 14px;
			  font-size: 1.4rem;
			}
		  	&-suggest {
			  text-align: left;
			  font-size: 13px;
			  font-size: 1.3rem;
			}
		}
	}
  	&__chat {
	  margin: 0;
	  margin-top: 10px;
	  padding-top: 1%;
	  clear: both;

	  &-cancel {
		text-align: left;
		clear: both;
		color: $black;
		font-weight: bold;
		margin-bottom: 10px;
		&-header {
		  font-size: 18px;
		  font-size: 1.8rem;
		  margin: 0;
		  font-weight: bold;
		  color: $black;

		}
	  }

	  &-user {
		width: 11%;
		padding: 0;
		float: left;
		display: block;
		margin-left: 10px;
	  	position: relative;
		min-height: 1px;


		&-tritop {
		  margin-top: 8px;
		  float: right;
		  border-color: transparent #F4F4F4 transparent transparent;
		  border-style: solid;
		  border-width: 16px 16px 16px 0;
		  height: 0px;
		  width: 0px;
		  z-index: 50;
		  position: absolute;
		  left: 53px;
		}

		&-tribot {
		  margin-top: 8px;
		  float: right;
		  border-color: transparent #BEBEBE transparent transparent;
		  border-style: solid;
		  border-width: 16px 16px 16px 0;
		  height: 0px;
		  width: 0px;
		}

		&-img {
		  float: right;
		  padding: 5px;
		  margin-right: 5px;
		  position: relative;
		  top: 5px;
		  left: -12px;
		  border: solid 1px lightgray;
		}

	  }

	  &-establishment {
		&-tritop {
		  margin-top: 8px;
		  float: left;
		  margin-right: -5px;

		  border-color: transparent transparent transparent $light-silver;
		  border-style: solid;
		  border-width: 15px 0 15px 15px;
		  height: 0px;
		  width: 0px;
		  right: 56px;
		  z-index: 50;
		  position: absolute;

		}

		&-tribot {
		  margin-top: 8px;
		  float: left;
		  margin-right: -5px;
		  border-color: transparent transparent transparent #CCC;
		  border-style: solid;
		  border-width: 15px 0 15px 15px;
		  height: 0px;
		  width: 0px;
		}

		&-img {
		  padding: 5px;
		  margin-right: 5px;
		  margin-left: 10px;
		}
	  }

	  &-box {
		width: 78%;
		padding: 0;
		margin-bottom: 15px;
		float: left;
		display: block;
		min-height: 1px;

		&-cancel {
		  clear: both;
		  text-align: right;
		  font-size: 11.6px;
		  font-size: 1.16rem;
		  margin-right: 10px;
		  margin-top: 30px;
		  a {
			cursor: pointer;
			color: $black;
			font-size: 12px;
			font-size: 1.2rem;
			text-decoration: underline;
		  }

		  a:hover {
			cursor: pointer;
			color: $pink;
			font-size: 12px;
			font-size: 1.2rem;
		  }



		}

		&-reply {
		  margin: 0;
		  margin-bottom: 15px;
		  background-color: rgb(244, 244, 244);
		  padding-left: 10px;
		  padding-right: 10px;
		  margin-right: 10px;
		  outline: 1px solid #CCC;

		  textarea {
			margin-top: 10px;
		  }
		}

		&-button {
		  margin-bottom: 10px;
		  float: right;
		  background-color: $bodygray;
		  color: #FFF;
		  margin-top: 10px;
		  font-weight: bold;
		  border-radius: 0;
		  &:hover {
			background-color: $light-silver;
			color: $darkgray;
		  }
		  &:focus {
			background-color: $light-silver;
			color: $darkgray;
		  }
		}
	  }

	}

	&__previous {
	  width: 100%;
	  float: left;
	  padding-left: 10px;
	  clear: both;
	  margin-bottom: 20px;

	  &-header {
		width: 78%;
		float: left;
		display: block;
		min-height: 1px;
	  	display: inline-block;
		font-size: 15px;
		font-size: 1.5rem;
		color: #999999;
		border-top: 1px dashed #DDDDDD;
		padding: 0;
		padding-top: 10px;
		margin: 10px 0 30px;
	  }

	  &-time {
		width: 78%;
		float: left;
		display: block;
		min-height: 1px;
		display: inline-block;
		font-size: 12px;
		font-size: 1.2rem;
		padding: 0;
		text-align: right;

		&-box {
		  margin-bottom: 5px;
		  padding: 0;
		}
	  }

	}

  	&__establishment {
	  margin: 0;
	  margin-bottom: 20px;
	  padding: 0;

	  &-box {
		padding: 10px;
		background-color: $light-silver;
		outline: 1px solid #CCC;
	  }

	  &-response {
		font-size: 13px;
		font-size: 1.3rem;
		float: left;
		font-weight: bold;
		padding: 0;
	  }

	  &-price {
		font-weight: bold;
		display: block;
		line-height: 1.8px;
		line-height: 1.8rem;
		color: $pink;
		float: right;
	  }

	  &-reserved {
		float: left;
		margin-top: 10px;
		border-bottom: 1px dashed;
		border-top: 1px dashed;
	  }

	  &-reply {
		font-size: 14px;
		font-size: 1.4rem;
		padding: 0;
		float: left;
		margin-top: 10px;
	  }
	}

  	&__user {
	  &-box {
		background-color: #F4F4F4;
		padding: 10px;
		outline: 1px solid #CCC;
		font-size: 11px;
		font-size: 1.1rem;
	  }
	}

  	&__initiated {
	  font-weight: bold;
	  color: $black;
	  font-size: 14px;
	  font-size: 1.4rem;
	  margin-bottom: 10px;

	  &-box {
		float: left;
		margin-bottom: 10px;
	  }

	  &-date {
		padding-bottom: 5px;
		float: left;
	  }

	  &-dash {
		float: left;
		padding-left: 10px;
		padding-right: 10px;
	  }

	  &-rooms {
		border-top: 1px dotted #5C350E;
		border-bottom: 1px dotted #5C350E;
		padding: 0;
	  }
	}

  	&__view-more {
	  font-size: 12px;
	  font-size: 1.2rem;
	  cursor: pointer;
	  overflow: hidden;
	  float: right;
	  clear: both;
	  text-decoration: underline;
	  margin: 0 10px;
	  font-weight: bold;
	  color: #607ea8;
	}

  	&__voucher-include {
	  padding: 0;
	  margin-top: -15px;
	}

  	&__value {
	  color: #000000 !important;

	  &-summary {
		text-align: left;
		font-size: 12px;
	  }

	  &-add {
		color: #b1d247;
		padding-right: 2px;
		&--grey {
		  color: #4F4E4E;
		}
	  }

	  &-discount {
		text-decoration: line-through;
		color: #8C8C8C;
		font-size: 18px;
		font-size: 1.8rem;
		font-weight: normal;


	  }

	  &-spacer {
		height: 10px;
	  }
	}
  &__alternative {
	  border: 2px dashed $green;
	  background-color: white;
	  min-height: 200px;
	  padding: 15px;
	  margin-top: 20px;

		h2{
		  font-size: 20px;
		  font-size: 2.0rem;
		}

	  &:hover {
		.establishment__book-now {
		  background-color: #4F4E4E;
		  transition: 0.3s;
		}
	  }

	  .alternative-card {
		//min-height: 220px;
		padding: 15px;
		text-align: center;
		margin: auto;
		cursor: pointer;
		text-decoration: none !important;


		a{
		  text-decoration: none !important;
		}

		a:hover{
		  text-decoration: none !important;
		}

		a:focus{
		  text-decoration: none !important;
		}

		h2{
		  font-size: 16px;
		  font-size: 1.6rem;
		  padding: 5px;
		}

		&:hover{
		  text-decoration: underline;
		}

		a{
		  text-decoration: none;
		}
		a:hover{
		  text-decoration: none;
		}
		a:visited{
		  text-decoration: none;
		}


		&:hover {
		  .alternative-card--book {
			background-color: #4F4E4E;
			transition: 0.3s;
		  }

		  .hvr-icon-forward, .hvr-icon-forward, .hvr-icon-forward {
			-webkit-transform: translateX(4px);
			transform: translateX(4px);
		  }

		  .hvr-icon-back, .hvr-icon-back, .hvr-icon-back {
			-webkit-transform: translateX(-4px);
			transform: translateX(-4px);
		  }
		  .alternative-card--border {
			border-left: 1px solid grey;
			border-right: 1px solid grey;
			border-bottom: 1px solid grey;
			transition: 0.3s;
		  }

		}

		&--left {
		  padding-left: 0;
		}

		&--right {
		  padding-right: 0;
		}


		&--image {
		  height: 90px;
		  background: $light-silver;
		  background-size: cover;
		  background-position: center;
		}

		&--border {
		  border-left: dashed 1px #DADADA;
		  border-right: dashed 1px #DADADA;
		  border-bottom: dashed 1px #DADADA;
		  padding: 0;
		  padding-top: 10px;
		  padding-bottom: 10px;
		  transition: 0.3s;
		}

		&--header {
		  font-size: 18px;
		  font-weight: 300;
		  padding: 5px 0 5px 0;
		  margin: 0;
		  height: 64px;
		  overflow: hidden;


		  &:hover{
			text-decoration: underline;
		  }
		}

		&--availability {
		  font-size: 16px;
		  font-size: 1.6rem;
		  font-weight: normal;
		  margin: 0;
		  padding: 0;
		  color: #b1d247;

		  i{
			font-size: 14px;
			font-size: 1.4rem;
			position: relative;
			top: -1px;

		  }

		  &-enquire {
			font-size: 16px;
			font-size: 1.6rem;
			font-weight: normal;
			margin: 0;
			padding: 0;
			color: #999;

			i{
			  font-size: 14px;
			  font-size: 1.4rem;
			  position: relative;
			  top: -1px;
			}

		  }
		}

		&--price {
		  color: $pink;
		  margin: 0;
		  padding: 0;
		  padding-top: 10px;
		  font-size: 17px;
		  font-weight: bold;

		  span{
			font-size: 14px;
			color: $bodygray;
			font-weight: normal;
		  }
		}

		&--spacer {
		  float: left;
		  height: 10px;
		  clear: both;
		}

		&--book {
		  background-color: #726F68;
		  clear: both;
		  margin: auto;
		  color: white;
		  padding: 10px;
		  cursor: pointer;
		  transition: 0.3s;

		  /* This is just for the rough version - use Sass and then look at the css to see what it is actually doing*/
		  &:hover {
			background-color: #4F4E4E;
			transition: 0.3s;
		  }
		}

		&--options {
		  background-color: #b1d247;
		  padding: 3px;
		  margin: auto;
		  text-align: center;
		  color: white;
		  cursor: pointer;
		  transition: 0.3s;
		  float: none;
		  font-size: 16px;


		  a{
			text-decoration: none;
		  }

		  a:hover{
			text-decoration: none;
		  }

		  a:focus{
			text-decoration: none;
		  }

		  &:hover {
			background-color: #a0c330;
			transition: 0.3s;
		  }

		  &-fade {
			background-color: #999999;

			a{
			  text-decoration: none;
			}

			a:hover{
			  text-decoration: none;
			}

			a:focus{
			  text-decoration: none;
			}

			&:hover {
			  background-color: #a0c330;
			  transition: 0.3s;
			}

			&:hover {
			  background-color: #4f4e4e !important;
			  transition: 0.3s;
			  text-decoration: none;
			}
		  }
		}
	  }

	  /* Experimental UX conversion tactic */
	  /* Icon Forward */
	  .hvr-icon-forward {
		display: inline-block;
		vertical-align: middle;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-moz-osx-font-smoothing: grayscale;
		position: relative;
		padding-right: 2.2em;
		-webkit-transition-duration: 0.1s;
		transition-duration: 0.1s;
	  }
	  .hvr-icon-forward:before {
		content: "\f138";
		position: absolute;
		right: 1em;
		padding: 0 1px;
		font-family: FontAwesome;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-transition-duration: 0.1s;
		transition-duration: 0.1s;
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	  }
	  //.hvr-icon-forward:hover:before, .hvr-icon-forward:focus:before, .hvr-icon-forward:active:before {
		//-webkit-transform: translateX(4px);
		//transform: translateX(4px);
	  //}
	  /* Icon Back */
	  .hvr-icon-back {
		display: inline-block;
		vertical-align: middle;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-moz-osx-font-smoothing: grayscale;
		position: relative;
		padding-left: 2.2em;
		-webkit-transition-duration: 0.1s;
		transition-duration: 0.1s;
	  }
	  .hvr-icon-back:before {
		content: "\f137";
		position: absolute;
		left: 1em;
		padding: 0 1px;
		font-family: FontAwesome;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-transition-duration: 0.1s;
		transition-duration: 0.1s;
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	  }
	  //.hvr-icon-back:hover:before, .hvr-icon-back:focus:before, .hvr-icon-back:active:before {
		//-webkit-transform: translateX(-4px);
		//transform: translateX(-4px);
	  //}
  }
}

/* Separate CSS */

#map {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
	background-color: #f2f2f2;

}

// This class is used for Javascript

.display-view {
  display:none;
}

//

.font-size-1-3rem {
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: normal;
}

.font-size-1-8rem {
  font-size: 18px;
  font-size: 1.8rem;
}

.padding-zero {
  padding: 0;
}

.bold {
  font-weight: bold;
}

.margin-none {
  margin: 0 !important;
}

.absolute-left-50 {
  left: 50px;
}

.text-align-left	{
  text-align: left;
}

.text-align-right	{
  text-align: right;
}
/* ------- */

@media screen and (min-width: 0px) and (max-width: 480px) {
/* Email Enquiry Details Page */
  	.email-enquiry {

	   &__secondary-nav {
			 padding-left: 0px;
		  	 padding-right: 0px;
	   }
	   &__reply-clock {
   			 float: left;
	   }
	   &__previous {
			 padding: 0;
	   }

	 }

  	.hidden-xxs {
	  display: none;
	}

  	.alternative-card-xss {
	  width: 100% !important;
	}

  	.alternative-card--options {
	  font-size: 12px !important;
	}
}

@media screen and (min-width: 480px) {
	.email-enquiry__container .my-travel__nav-name {
		padding-left: 0;
	}
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  	.alternative-card--options {
	  width: 372px
	}
}

@media screen and (min-width: 767px) {
	.email-enquiry {
		&__reply {
			&-header {
				padding: 0;
			}
			&-clock {
				padding: 0 0 0 10px;
			}
		}
	  	&__previous {
		  max-width: 400px;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  	.email-enquiry {
		&__chat-box {
			width: 100%;

			&-reply {
			  margin-left: 0;
			}
		}
	  	&__previous {

		  &-header {
			width: 100%;
		  }
		  &-time {
			width: 100%;
		  }

		}
	}

  .email-enquiry__alternative .alternative-card--options{
	padding: 10px;
  }

  .enquiry-or-booking-title__reference {
	text-align: left;
    padding-left: 0;
  }

}

@media screen and (min-width: 0px) and (max-width: 991px) {
  	.email-enquiry {
	  	&__reply-box {
		  	&-bg {
			  min-height: 0;
			}
		  	&-price {
			  width: 100%;
			}

		}
	  	&__chat {

		  &-user-tritop {
			//left: 47px;
			left: 52px;
		  }

		  &-establishment-tritop {
			right: 54px;
			font-size: 13px;
			font-size: 1.3rem;
		  }
		}
	}
  	.absolute-left-50 {
	  left: 47px;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px)  {
	.email-enquiry {
		&__chat-user {
			width: 16%;
		}
		&__chat-box {
		  	width: 68%;
		}
		&__previous {
			&-header {
			  width: 68%;
			}
			&-time {
			  width: 68%;
			}
		}
	  	&__establishment-response {
		  width: 79% !important;
		}
	}
}

@media screen and (min-width: 992px) {
  .email-enquiry {
	&__summary {
	  float: left;
	}
	&__reply-box {
	  min-height: 100px;

	  &-bg {
		padding-right: 50px;
		float: left;
	  }

	  &-book {
		position: absolute;
		right: -172px;
		margin-top: 10px;
		margin-right: 10px;
	  }
	  &-status {
		width: 200px;
		float: left;
	  }
	  &-price {
		margin: 0;
		margin-top: 10px;
		margin-left: -19px;
	  }
	}
	&__previous {
	  max-width: 605px;
	}
	&__establishment-response {
	  font-size: 14px;
	  font-size: 1.4rem;
	}

  }
  .margin-top-20-xs {
	margin-top: 20px;
  }
  .margin-top-50-lg {
	margin-top: 50px;
  }
}

//.alternative-card--ribbon-left {
//  border-color: transparent #383838 transparent transparent;
//  border-style: solid;
//  border-width: 11px 8px 0px 0px;
//  height: 0px;
//  width: 0px;
//  float: left;
//  position: absolute;
//  left: 7px;
//  top: 154px;
//}
//
//.alternative-card--ribbon-right {
//  border-color: transparent transparent transparent #000;
//  border-style: solid;
//  border-width: 10px 0px 0px 8px;
//  /* height: 0px; */
//  /* width: 0px; */
//  /* float: right; */
//  position: absolute;
//  right: 7px;
//  top: 154px;
//}

//body {
//  color: palegreen !important;
//}

@-moz-document url-prefix() {
  .my-travel-tabs__unselected {
	position: relative;
	bottom: 1px;
  }
  .my-travel-tabs__selected {
	position: relative;
	bottom: 1px;
  }
}
