.credit-success {
	margin: 15px;
	margin-bottom: 10px;

	&__container {
		background-color: #FFF;
		padding-bottom: 15px;
		padding-top: 15px;
	}

	&__success {

	  	i {
  		  	font-size: 2.5rem !important;
  			color: #839D43 !important;
	  	}

	  	span {
  		  	color: #839d44 !important;
	  	}

	  	h2 {
		  font-size: 19px !important;
		  font-size: 1.9rem !important;
		  color: #653305 !important;
		  font-weight: bold;
		}
	}

	&__booking {
		&-deposit {
			padding: 10px;
			background-color: #dff0d8;
			border-color: #d6e9c6;
			color: #3c763d;
			border-radius: 4px;
			margin-bottom: 10px;
			font-size: 12px;
			font-size: 1.2rem;
			font-weight: bold;
			margin-top: 10px;
		}

		&-confirmation {
			background-color: #F4F4F4;
			padding: 17px 10px;
			border-radius: 4px;
			p {
				font-size: 12px;
				font-size: 1.2rem;
				color: #653305;
			}
		}
	}

	&__voucher {
		padding: 0;
		margin-top: -15px;
	}




}

@media screen and (min-width: 0px) and (max-width: 480px) {
	.credit-success {
	    margin-left: 0px;
	    margin-right: 0px;
	}
}