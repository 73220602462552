.booking-content {
    margin: 15px;
    margin-bottom: 10px;

    .padding-zero {
        padding: 0;
    }

    .bold {
        font-weight: bold;
    }

    .padding-right-zero {
        padding-right: 0;
    }

    .brown-text {
        color: #653305;
    }

    &__container {
        background-color: #FFF;
        padding-bottom: 15px;
    }

    &__inbox {
        margin: 0;

        &-button {
            padding: 3px;
            margin-top: 10px;
            margin-bottom: 5px;
            padding-right: 9px;
            padding-bottom: 0;
            background-color: #999;
            color: #FFF;
            border: none;
            font-size: 0.9em;

            &:hover {
                background-color: #4f4e4e;
                color: #FFF;
            }

            &:focus {
                background-color: #4f4e4e;
                color: #FFF;
            }

            i {
                margin: 0;
                font-size: 11px;
                font-size: 1.1rem;
                height: 27px;
                padding-top: 6px;
                padding-bottom: 0px;
                width: 22px;
            }

        }
    }

    &__main {
        padding: 0;
        background-color: #FFF;
        padding-bottom: 20px;
    }

    &__summary {
        padding: 10px;
        border: 2px solid #FFF;
        outline: 1px solid #E2E2E2;
        margin: 10px 0;
        font-size: 12px;
        font-size: 1.2rem;
    }

    &__header {
        padding: 0;
        padding-bottom: 10px;
        color: #653305;

        h2 {
            font-weight: bold;
            font-size: 16px;
            font-size: 1.6rem;
            color: #653305;
        }

        &-sub {
            font-weight: bold;
            padding: 0;
            font-size: 1.3rem !important;
            text-decoration: underline;
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }

    &__border {
        border: 2px solid #FFF;
        outline: 1px solid #E2E2E2;
        height: 400px;
    }

    &__secondary {
        width: 100%;
        padding-left: 10px;
        padding-bottom: 10px;
        font-size: 12px;
        font-size: 1.2rem;
    }

    &__outline {
        outline: 1px solid #f1ede4;
    }

    &__img {
        background-color: #999999;
        border: 3px solid #FFF;
        margin: auto;

        img {
            width: 100%;
        }
    }

    &__name {
        h4 {
            color: #607ea8;
            padding: 10px;
            padding-top: 13px;
            padding-bottom: 13px;
            text-decoration: underline;
            margin-top: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    &__rating {
        p {
            padding: 10px;

            img {
                padding-bottom: 4px;
            }
        }
    }

    &__details {
        padding: 10px;
        padding-top: 20px;
        outline: 1px solid #f1ede4;

        p {
            font-weight: bold;
        }
    }

    &__rooms {
        padding: 0;
        clear: both;
        color: #653305;

        &-warning {
            color: $error-red;
            text-align: center;
        }

        &-header {
            font-weight: bold;
            clear: both;
            font-size: 16px;
            font-size: 1.6rem;
            color: #653305;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;

            &-sub {
                font-size: 12px;
                font-size: 1.2rem;
                font-weight: bold;
                clear: both;
                color: #653305;
                padding: 5px;
                margin: 0;
                border-bottom: 1px solid #E2E2E2;

                &--center {
                    text-align: center;
                }
            }
        }

        &-dynamic {
            font-size: 12px;
            font-size: 1.2rem;
            clear: both;
            color: #653305;
            padding: 5px;
        }

        &-rate {
        //    Media queries
        }

        &-total {
            font-size: 12px;
            font-size: 1.2rem;
            clear: both;
            color: #653305;
            padding: 5px;
        }

        &-border-sub-total {
            border-top: 1px solid #E2E2E2;
        }

        &-border-total {
            border-top: 1px solid #E2E2E2;
            border-bottom: 1px solid #E2E2E2;
        }

        &-comments {
            margin: 0;
            padding: 5px;
            font-size: 12px;
            font-size: 1.2rem;
        }

        &-pricing {
            width: 100%;
            font-size: 12px;
            font-size: 1.2rem;

            td, th {
                padding: 5px;
            }

            &-price {
                text-align: right;

                &--old {
                    text-decoration: line-through;
                }

                &--discounted {
                    color: $pink;
                }

                &--total {
                    font-weight: bold;
                }
            }
        }

    }

    &__mobile {
            border: 2px solid #FFF;
            outline: 1px solid #E2E2E2;

            h2 {
                font-weight: bold;
                font-size: 16px;
                font-size: 1.6rem;
                color: #653305;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 20px;
            }

        &-details {
            font-size: 12px;
            font-size: 1.2rem;
            clear: both;
            padding: 5px;
            color: #653305;
        }

        &-total {
            margin: 0;
        }
    }

}

@media screen and (min-width: 0px) and (max-width: 480px) {
    .booking-content {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (min-width: 768px) {
    .booking-content__rooms-rate {
        width: 80px;
        text-align: right;
    }

    .column-spacing {
        padding-left: 35px;
    }

}

@media screen and (min-width: 992px) {
    .booking-content {
        &__summary {
            float: left;
        }
    }
}