h1 {
  font-size: 28px;
  font-size: 2.8rem;
  color: $black;
}

h2 {
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bolder;
  color: $black;
}

h3 {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: $black;
}

h4 {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}

h5, .inParagraphHeading {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  font-style: italic;
}

h6 {
  font-size: 14px;
  font-size: 1.4rem;
  color: #929565;
}

.price {
  text-align: right;

  &__amnt {
    font-weight: bold;
    color: $pink !important;

    &--old {
      font-weight: normal;
      text-decoration: line-through;
      color: $bodygray !important;
    }
  }
}

.pricesupport {
  @include base-font(14px, 1.4rem);
}

.pricelarge {
  @include base-font(24px, 2.4rem);
  font-weight: bold;
  display: block;
  line-height: 1.8px;
  line-height: 1.8rem;
  color: $pink;
}

.text--ellipses {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 768px) {
  .pricelarge {
    @include base-font(18px, 1.8rem);
  }
}