.newsletter{

  &-subscription {
    margin-top: 10px;
  }

  &__background{
    background-color: transparent;
    margin-left: 0px;
    padding-bottom: 20px;
    margin-right: 0px;
  }


  &__headings{
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 10px;
    display: block;
  }

  input{
    width: 100%;
    margin-bottom: 7px;
  }
}