//new colors
$pink: #e5203c;
$silver: #bababa;
$light-silver: #ebebeb;
$bodygray: #726f68;
$darkgray: #6c6c6c;
$linksblue: #3f97ff;
$linkshover: #0365d9;
$filtering-gray: #717171;
$error-red: #dd4b39;

// Page colours
$background: #FFFFFF;
$light-beige: #F1EDE4;

// Font colours
$charcoal: #0E0E0E;
$dark-brown: #653305;
$light-brown: #ac844c;
$green: #b1d247;
$green-success: #839D43;
$red: #921b1d;
$edgars-red: #e11428;
$grey: #4F4E4E;
$black: #252525;
$white: #ffffff;

$menu-background: #EFF2D7;
$menu-borders: #AB864F;

$links-normal: #004b91;
$links-hover: #92000A;