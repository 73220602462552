#article--section{
  padding: 15px;
  color: #76776f;
  font-size: 14px;
  font-size: 1.4rem;


  hr{
    border-top: #76776f solid 1px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h2{
    color: #789c32;
    text-transform: uppercase;
  }

  p{
    img{
      max-width: 100%;
      padding-left: 11px;
    }
  }

}

@-moz-document url-prefix() {
  #article--section {
    outline: none !important;
    outline: none !important;
  }
}


.content--image_caption{
  text-align: center;
  font-style: italic;
  font-weight: 600;
  margin-top: -10px;
  padding-bottom: 20px;
  color: #76776F;
}


.height-mobile-swipe{
  height: 900px !important;
}



.article--socialfeeds__position{
  position: relative;
  right: -55px;
  height: 50px;
  margin-bottom: -19px;
}

#gallery-section{
  h3{
    margin: 15px 0px;
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
  }

  .thumbnail{
    margin-bottom: 0px;
  }
}

div.slide .help_text{
  font-size: 65%;
  font-weight: bold;
  float: right;
  margin-right: 72px;
  margin-bottom: 8px;
}

.content--image{
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 20px 10px;

}



#videoHolder{
  width:480px;
  margin: auto;
}



/*****MEDIA QUERIES******/
@media (min-width: 1200px){
  /*set width of website*/
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
  #ribbon, #pictureRibbon, #voucherRibbon{
    position: relative;
    width: 655px;
    height: 131px;
    overflow: hidden;
  }

  div.slide .help_text{
    margin-top: -7px;
  }

  .content--image{
    height: 440px;
  }
}

@media (max-width: 767px) {
  .article--socialfeeds__position {
    position: initial;
    right: -55px;
    padding-top: 15px;
  }


  .content--image{
    height: 250px;
  }
}


@media (max-width: 320px) {
  .content--image{
    height: 160px;
  }
}
