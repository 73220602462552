// This is styling for auto-complete boxes - should be merged with home page
.auto-complete__header {
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 15px;
  padding: 4px 9px;
  color: #000000;
  margin-top: 4px;
  background-color: #ffffff;
}
