.pagenotfound{
  text-align: center;
  font-weight: bold;
  &__padding{
  }

  span{
    color: #DD0000;
  }

  a{
    font-weight: normal;
  }
  a:visited{
    font-weight: normal;
  }
  a:hover{
    font-weight: normal;
  }


  &__heading{
    h2{
      font-size: 17px;
      font-size: 1.7rem;
      font-weight: bold;
      color: black;
    }
  }
}
