@import "../modules/colours";

.login-page {
  margin-top: 30px;
  padding: 10px;

  img {
    margin-top: 0px !important;
  }

  .club-popup__membership-type {
    height: 105px !important;
    margin-bottom: 10px !important;
  }
}


.login-form {
  max-width: 400px;
  border: 1px solid $silver;
  padding: 10px 30px;
  margin: 0 auto 20px auto;

  h1 {
    margin-top: 5px;
    color: $black;
  }

  form {
    margin-top: 30px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin: 5px 0 20px 0;
  }

  label {
    display: block;
  }

  input[type="text"], input[type="password"] {
    width: 100%;
    padding: 3px;
  }

  input[type=checkbox] {
    margin-left: 5px;
    margin-right: 5px;
    transform: scale(1.5);
  }

  &__cta {
    text-align: center;

    button {
      width: 180px;
    }
  }

  .appear-hidden {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }

  .appear-shown {
    visibility: visible;
    margin-top: 10px;
    height: auto;
    opacity: 1;
    transition: height 0s, visibility 0.5s, opacity 1s linear;
  }

  &__field-check {
    &--valid {
      position: absolute;
      right: 7px;
      margin-top: 7px;
      color: $green-success;
    }

    &--in-valid {
      position: absolute;
      right: 7px;
      margin-top: 3px;
      color: $error-red;
      font-size: 1.6rem;
      font-size: 16px;
      font-weight: bold;
    }

    &-msg {
      margin-top: 5px;
      display: block;
      color: $error-red;
    }
  }
}

.reset-password-form {
  max-width: 600px;
}

.create-account-success {

  h2 {
    color: $green;
  }

  h3 {
    display: inline-block;
  }
}