@mixin gradients($gradient){
  background: #ede4ce; /* Old browsers */
  background: -moz-linear-gradient(top,  #ede4ce 0%, #ffffff 11%, #ffffff 94%, #ede4ce 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ede4ce), color-stop(11%,#ffffff), color-stop(94%,#ffffff), color-stop(100%,#ede4ce)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #ede4ce 0%,#ffffff 11%,#ffffff 94%,#ede4ce 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #ede4ce 0%,#ffffff 11%,#ffffff 94%,#ede4ce 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #ede4ce 0%,#ffffff 11%,#ffffff 94%,#ede4ce 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #ede4ce 0%,#ffffff 11%,#ffffff 94%,#ede4ce 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ede4ce', endColorstr='#ede4ce',GradientType=0 ); /* IE6-9 */
}

@mixin gradient-two-color-mirror($color-one, $color-two, $location-one, $location-two) {
  background: #ede4ce; /* Old browsers */
  background: -moz-linear-gradient(top,  $color-one 0%, $color-two $location-one, $color-two $location-two, $color-one 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$color-one), color-stop($location-one,$color-two), color-stop($location-two,$color-two), color-stop(100%,$color-one)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $color-one 0%,$color-two $location-one,$color-two $location-two,$color-one 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $color-one 0%,$color-two $location-one,$color-two $location-two,$color-one 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $color-one 0%,$color-two $location-one,$color-two $location-two,$color-one 100%); /* IE10+ */
  background: linear-gradient(to bottom,  $color-one 0%,$color-two $location-one,$color-two $location-two,$color-one 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-color-one', endColorstr='$gradient-color-one',GradientType=0 ); /* IE6-9 */
}
