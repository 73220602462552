//@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
//@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,700);

html {
  font-size: 62.5%;
}

body {
  font-size: $base-font-size;
  font-size: $base-font-size-rem;
  background-color: $background;
  color: $bodygray;
  font-family: $base-font-family;
  overflow-x: hidden;
}

.page {
  background-color: #FFFFFF;
}

.invisible {
  visibility: hidden;
}

.hide-wait-JS {
  display: none;
}

hr{
  border-bottom: none;
  margin: 5px 0px 5px 0px;
}

.hr--heading{
  border-top: 1px solid $light-silver;
  border-bottom: 1px solid white;
}

.hr--lists{
  border-top: 1px solid #B4ABAE;
  border-bottom: none;
}

.hr--details{
  border-top: 1px solid $light-silver;
  border-bottom: none;
}


a{
  color: $linksblue;
  font-weight: 400;
  font-size: 14px;
  font-size: 14px;
  cursor: pointer;
}

a:hover{
  color: $linkshover;
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.secondary-links{
  a{
    color: #6c6c6c;
    font-size: 12px;
    cursor: pointer;
  }

  a:hover{
    color: $pink;
    font-size: 12px;
    cursor: pointer;
  }
}

.clickable {
  cursor: pointer;
}

.breadcrumbs {

  font-size: 12px !important;
  font-size: 1.2rem !important;

  
  &__list{
    padding-bottom: 15px;

    li{
      a{
        font-size: 12px !important;
        font-size: 1.2rem !important;
        color: #6c6c6c;
      }

      a:hover{
        font-size: 12px !important;
        font-size: 1.2rem !important;
      }

      a:visited{
        font-size: 12px !important;
        font-size: 1.2rem !important;
      }

      a:focus{
        font-size: 12px !important;
        font-size: 1.2rem !important;
      }
    }
  }

  &_list {
    display: inline;
    font-size: 12px !important;
  }

  ul {
    list-style: none;
    display: inline;
    padding: 0;
    margin: 0;
    font-size: 12px !important;
  }

  li {
    display: inline;
    font-size: 12px !important;
  }

  &__location {
    text-align: right;
    font-size: 12px !important;
    font-size: 1.2rem !important;

    &--showmore{
      float: left;
      padding-top: 5px;
      padding-left: 10px;
    }

    h2{
      float: left;
    }
  }
}


/*****Javascript selectors TODO:CHANGE THIS ACROSS MULTIPLE PLACES********/
.pvtSelector {
  width: 80px;
  float: left;
  font-size: 11px;
  font-size: 1.1rem;
  text-align: center;
  border-top: 1px solid $silver;
  border-bottom: 1px solid $silver;
  padding: 8px 0px;
  margin-right: 0px;

  a{
    color: $darkgray;
    font-size: 12px;
    text-decoration: none;
  }

  a:visited{
    color: $darkgray;
    font-size: 12px;
    text-decoration: none;
  }
  a:hover
  {
    color: $darkgray;
    font-size: 12px;
    text-decoration: none;
  }

  a:focus{
    color: $darkgray;
    font-size: 12px;
    text-decoration: none;
  }
}


.pvtSelected, .pvtSelector:hover {
  border-top: 2px solid $pink;
  border-bottom: 2px solid $pink;
  padding: 7px 0px;
  font-style: italic;
  font-weight:  bold;
  cursor: pointer !important;

  a{
    color: $darkgray;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
  }

  a:visited{
    color: $darkgray;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
  }
  a:hover
  {
    color: $darkgray;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
  }

  a:focus{
    color: $darkgray;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
  }
}

.pvtSelectorSmall {
  padding: 5px 0px;
  width: 55px;
}

.pvtSelectedSmall, .pvtSelectorSmall:hover {
  padding: 4px 0px;
}

.ui-datepicker-trigger{
  padding-left: 5px;
}
