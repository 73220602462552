/* List/Bookings Enquiries Page. */
.my-travel__mobile-contact {
  border: #FFF solid 5px;
  background-color: #B3B3B3;
  color: white;
  padding: 18px;

  i {
	padding: 0 5px 0 5px;
  }
}

.row-margin-zero {
  margin: 0;
}

.text-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btn-disabled {
  color: #ffffff !important;
  background-color: #e7e7e7 !important; ;
}

.enquiries {

	&__margin-mobile {
	  margin: 15px;
	}
	&__inner-nav {
		padding: 0 15px 0 0;
	}
	&__alert-styling {
		margin: 15px 0;
	}

	&__dynamic-content {
		margin: 0;
	}

	&__list-navigation {
		background-color: #FFF;

		&--position {
			float: right;
			padding: 7px 0 7px 0;
			font-size: 11px;
		}

	  	&--button {
		  background-color: #d4d4d4;
		  padding: 4px 7px;
		  cursor: pointer;
		  border-radius: 3px;
		  background: #bababa;
		  color: $black;
		  font-size: 11px !important;
			background: $silver; /* Old browsers */
		  	font-size: 11px;

			&:hover {
			  background: $silver; /* Old browsers */
			  color: $light-silver;
			  font-size: 11px;
			}
		}
	}

	&__list-container {
		margin-short: 0;
		background-color: #FFF;
	    min-height: 400px;

		&--header {
			padding: 5px;
			background-color: #FFF;
			overflow: hidden;
			font-size: 14.5px;
			font-size: 1.45rem;
			font-weight: bold;
		  	color: $black;
		}

		&--mail {
			padding: 5px;
			margin: 0;
			position: relative;
			clear: both;
			cursor: pointer;
			font-size: 13px;
			font-size: 1.3rem;

			&:hover {
				outline: 1px solid #d0d3ca;
 				z-index: 1;	
			}
		}
	  
	  	&--border {
		  border: 1px solid #FFF;
  		  padding: 0;
		}

	  	&--space {
		  padding-right: 0;
		}
	  	
	}

	&__hr {
		margin: 0;
		padding: 0;
	}

	&__cancelled {
	  	background-color: #FFF;
 		color: #999999;
	}

	&__booked {
	  	background-color: #FFF;
  		color: #97966f;
	}

	&__waiting {
		background-color: #EFEFEF;
	}
  	&__mobile-chevron {
	  position: absolute;
	  width:20px;
	  top: 24px;
	  right: 35px;
	  font-size: 18px;
	  font-size: 1.8rem;
	}
}


@media screen and (min-width: 0px) and (max-width: 480px) {
	.enquiries {

		&__margin-mobile {
			margin-left: 0px;
			margin-right: 0px;
		}

		&__inner-nav {
			padding: 0;
		}   
	}
}

@media screen and (min-width: 0px) and (max-width: 767px) { 
	.enquiries {
		&__hr {
			border-color: #E2D8C6;
		}		
    }
  	.bold-xs {
		font-weight: bold;
  	}

}

/**/

@media screen and (min-width: 768px) {
	 .enquiries {
		 &__column-spacing {
		    padding-left: 35px;

			 &--button{
				 background-color: $green;
				 color: $white;
				 font-weight: bold;
				 padding: 5px 10px;
			 }

			 a{text-decoration: none}
			 a:hover{text-decoration: none}
			 a:visited{text-decoration: none}
		 }
	}
}