.estRatingText {
  position: absolute;
  left: 202px;
  width: 107px;
  top: 7px;
}

.reviewform {
  margin-top: 20px;

  .errorsText {
    color: $error-red;
  }

  &-section {
    margin: 10px -15px 20px -15px;
  }

  &-title {
    margin-bottom: 20px;
  }

  &-admindateinput {
    clear: left;
  }

  &-stars {
    clear: left;
  }

  .radio {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.ratingsStarsEmpty {
  width: 36px;
  height: 36px;
  background-image: url('/review/img/largeEmptyStar.png');
  background-repeat: no-repeat;
  position: relative;
  float: left;
  margin-right: 2px;
}

.ratingsStarsFull {
  background-image: url('/review/img/largeYellowStar.png');
}

#rating_div {
  overflow: visible !important;
}

#commentAndButtonDiv{
  display: none;
}

.establishmentreview--heading {
  margin: 5px 0px 3px;
  display: inline-block;
  color: black;
  font-weight: bold;
  margin-bottom: 8px;
  cursor: pointer;
  text-decoration: underline;
  font-size: 28px;
  font-size: 2.8rem;

  &:hover {
    text-decoration: none;
    font-size: 28px;
    font-size: 2.8rem;
    color: black;
  }

  &:visited {
    text-decoration: none;
    font-size: 28px;
    font-size: 2.8rem;
    color: black;
  }
}

.establishmentreview--comments--textarea {
  width: 80%;
  height: 200px;
}

@media (min-width: 1200px) {
  /*set width of website*/
}

@media (max-width: 1199px) {
}

@media (min-width: 991px) {
  /*set width of website*/
}

@media (max-width: 767px) {

  #rating_div {
    margin-top: 8px !important;
  }

  .establishmentreview--heading__textbox {
    width: 100%;
    height: 40px;
  }

  .establishmentreview--comments--textarea {
    width: 100%;
    height: 200px;
  }
}