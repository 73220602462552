
.liveavailability {

  &--loading__overlay {
    width: 92%;
    height: 101%;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    left: 17px;
  }

  &--buttonbox {
    text-align: center;
    text-align: center;
    span {
      margin-bottom: 10px;
      display: block;
      font-weight: bold;
    }
  }

  &--loading__image {
    margin: auto;
    width: 50px;
    position: relative;
    top: 36%;
  }

  &--modal__buttons {
    width: 47%;
    display: block;
    margin-right: 19px;
    float: left;

    &--enquire {
      width: 47%;
      display: block;
      margin-right: 15px;
      float: left;
      margin-top: 0px !important;
    }
  }

  &__creditcard-type {
    img {
      margin-left: 5px;
      margin-top: -2px;
      width: 40px;
    }
  }

  &--radiobuttons {
    width: 15px;
    display: inline-block;
    input[type="radio"] {
      cursor: pointer;
    }
  }

  &--description {
    display: inline-block;
    width: 60%;
    padding-left: 2px;
  }

  &--loading__roomtypes {
    border: solid #bababa 1px;
    margin-bottom: 30px;
    text-align: center;
    padding: 10px 0px;
  }

  &--price {
    span {
      color: $pink;
      font-weight: $bold;
    }
  }

  &__hiddenkeys {
    padding-left: 28px;

  }

  &__calender {
    label {
      width: 100px;
    }

    input[type=text] {
      margin-bottom: 5px;
    }

    &__buttons {
      display: block;
      background-color: rgba(177, 210, 71, 0.4);
      border-radius: 2px;
      width: 13px;
      //-moz-box-shadow:    1px 1px 0px 1px rgba(0, 0, 0, 0.3);
      //-webkit-box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.3);
      //box-shadow:         1px 1px 0px 1px rgba(0, 0, 0, 0.3);
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
  &__position {
    display: inline-block;
    padding-left: 39px;
    margin-top: 91px;
  }
  &--roomstypes {

    &__border {
      border: $silver 1px solid;
      padding: 5px 15px;
      border-bottom: none;
      height: 94px;

      &__selects {
        border: $silver 1px solid;
        border-left: none;
        padding: 10px 5px;
        border-bottom: none;
        height: 94px;
        font-size: 11px;
        text-align: center;
      }

    }

    &--right--arrow {
      display: inline-block;
      padding-left: 10px;
      padding-right: 20px;
      position: relative;
      top: 0px;
      display: inline-block;
      width: 46px;
      height: 61px;
      margin-right: 17px;
    }
  }

  &__roomswidget {
    width: 38px !important;
    height: 61px !important;
  }

  &__submit {
    padding: 30px 15px;
    text-align: center;
  }

  &__elementheight {
    height: 60px !important;
  }

  &__border {
    border: 2px solid $black !important;
  }

  &__border-every {
    border-top: 3px solid $black !important;
    border-bottom: 3px solid $black !important;
  }

  &__border--reset {
    border-bottom: solid 1px;
  }

  &--information__color {
    color: $pink;
  }

  &__keys {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 52px;
    font-weight: normal;
    @include base-font(12px, 1.2rem);

    &-cell {
      clear: both;
      height: 20px !important;
      margin-right: 5px;
    }
  }

  &--column__padding {
    padding-left: 30px;
  }

  &--column__padding--specific {
    padding-left: 17px;
  }

  &--loading {
    position: absolute;

    width: 530px;
    left: 54px;
    z-index: 4;
    top: 204px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);

    img {
      position: relative;
      top: 43%;
    }
  }

  &--check {
    i {
      font-size: 24px;
      font-size: 2.4rem;
    }
  }

  &--numnights {
    text-align: center;
    span {
      color: $black;
      font-weight: bold;
    }
  }
}

.liveavailability--roomstypes__border.last {
  border-bottom: $silver 1px solid;
}

.live--availability__calender {
  width: 38px;
  text-align: center;
  border: solid $silver 1px;
  display: inline-block;
  padding-left: 0px;
  height: 61px;
}

.comiseo-daterangepicker-triggerbutton {
  width: 100%;
}

.display-inline-block {
  display: inline-block;
}

.padding--top__twentyfour {
  padding-top: 24px;
}

.padding--top__eighteen {
  padding-top: 18px;
}

.liveavailability--roomstypes__border:last-of-type {
  border-bottom: solid pink 3px;
}

.live--availability__available {
  background-color: #e0edb5;
  width: 35px;
  text-align: center;
  border: solid $silver 1px;
  height: 40px;
  float: left;
  padding-top: 15px;

}

.live--availability_lastminute_discount {
  color: #e5203c;
  font-weight: bold;
}

.live--availability__available_selected {
  background-color: #0000cc;
  width: 35px;
  text-align: center;
  border: solid $silver 1px;
  height: 40px;
  flaot: left;
  padding-top: 15px;

}

.column--padding {
  padding: 0;
}

.right--arrow__size {
  font-size: 35px !important;
  position: relative;
  color: #726F68;
  top: -17px;
}

.check--available__liveavailability {
  position: relative;
  font-size: 25px !important;
  display: inline-block;
  width: 35px;
  text-align: center;
  outline: solid $silver 1px;
  height: 40px;
  display: inline-block;
}

.unavailable__span--liveavailability {
  background-image: url("/img/unavailable-diagonal-lines.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 35px;
  text-align: center;
  border: solid $silver 1px;
  height: 40px;
  float: left;
  padding-top: 15px;
}

.liveavailability--longerstayrequired {
  background-color: #f7f1cc;
  width: 102px;
  text-align: center;
  border: solid $silver 1px;
  height: 40px;
  float: left;
  padding-top: 5px;
  font-size: 12px;

  a {
    font-size: 11px;
  }
  a:hover {
    font-size: 11px;
  }
  a:visited {
    font-size: 11px;
  }
  a:focus {
    font-size: 11px;
  }
}

.liveavailability--switchroomduringstay {
  background-color: #f4d5af !important;
  text-align: center;
  border: solid $silver 1px;
  height: 40px;
  float: left;
  padding-top: 5px;

  a {
    @include base-font(11px, 1.1rem);
    text-decoration: underline;
  }
}

.popover {
  top: -28px !important;
}

.liveavailability--weekends {
  background-color: $light-silver;
  height: 59px;
}

.background--and__display {
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
}

.left--doublearrow__position {
  position: absolute;
  top: 100px;
  left: 40px;
}

.right--doublearrow__position {
  position: absolute;
  top: 100px;
  right: 45px;
}

.bottom--arrows {
  width: 37px;
  height: 90px;
  float: left;
  clear: both;
  top: 27px;
  position: relative;
  left: -1px;

  &__container {
    float: left;
    clear: both;
    white-space: pre-wrap;
    text-align: center;
    width: 100%;
  }
}

.tooltip-inner {
  width: 250px;
  padding: 3px 8px;
  text-align: center;
  text-decoration: none;
  outline: solid black 1px;
  border-radius: 4px;
  white-space: pre;
  max-width: none;
  white-space: pre-wrap;
  color: $black;
  opacity: 1;
  background-color: $white;
}

.tooltip.top
.tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #ffffff;
}

.liveavailability__calender__buttons_unused {
  background-color: $light-silver;
  display: block;
  border-radius: 2px;
  width: 13px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  color: #D3D3D3;
}

.daySelected {
  border: solid $black 3px;
}

.nextRoomHasAvailability {
  border-bottom: transparent 2px !important;
}

.nextDayHasAvailability {
  border-right: transparent 2px !important;
}

.liveavailability__border-first {
  border-left: solid $black 2px !important;
}

.liveavailability__border-last {
  border-right: 2px solid $black !important;
}

.liveavailability--border__all {
  border: 2px solid $black !important;
}

.liveavailability__border-every {
  border: 2px solid $black !important;
}

.tooltip.top {
  left: 0px !important;
}

.unselectable_red {
  background-color: #FFC0c0;
  text-decoration: line-through;
}

@media (max-width: 991px) {

  .liveavailability--roomsstatus {
    text-align: center;
    font-weight: bold;

    &__unavailable {
      i {
        color: red;
      }
    }

    &__available {
      i {
        color: $green;
      }
    }
  }

  @media (max-width: 767px) {
    .liveavailability--column__padding {
      padding-left: 30px;
      padding-right: 30px;

    }

    .mobile-indicator {

      &-square {
        margin-top: 10px;

        &__border {
          display: inline-block;
          border: solid #DCDBD8 1px;
          width: 23px;
          text-align: center;
          color: #DCDBD8;
        }
      }

      &__selected {
        border: solid 1px #E62043;
        outline: solid 1px #E62043;
        color: #E62043;
      }

      &-circles {
        position: relative;
      }

      &__text {
        position: relative;
        top: 7px;
        left: 10px;
      }

      &__progress-text {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 10px;

        span {
          font-size: 16px;
          font-weight: bolder;
        }
      }

      hr {
        margin-top: -5px;
      }
      &__outer-circle {
        background: #b1d247;
        border-radius: 50%;
        height: 65px;
        width: 67px;
        position: relative;
      }

      &__inner-circle {
        position: absolute;
        background: #ffffff;
        border-radius: 50%;
        height: 55px;
        width: 55px;
        top: 155px;
        left: 156px;
        margin: -150px 0px 0px -150px;

      }

    }

    .liveavailability--modal__buttons {
      width: 100%;
      display: block;
      margin-right: 19px;
      float: left;
      margin-bottom: 29px;
    }

    .liveavailability--modal__buttons--enquire {
      width: 47%;
      display: block;
      margin-right: 15px;
      float: left;
      margin-top: 0px !important;
      width: 100%;
    }

    .tooltip.top {
      right: 80%;
    }

    .liveavailability--roomsstatus {

      &__unavailable {
        i {
          color: red;
        }
      }

      &__available {
        i {
          color: $green;
        }
      }
    }
  }
}
