.my-travel__nav {
  
  	.nav > li > a {
		padding-top: 5px !important;
		padding-bottom: 8px !important;
  	}

  	.navbar-left > li > a {
		transition: 0.5s;
  	}

  	&-container {
		margin-bottom: 0;
		border: none;
		border-radius: 0;
	  background-color: $white;
  	}

  	.navbar-header {
  		button {
			margin: 0 15px;
  		}
  	}

  	&-name {
	    padding-top: 5px;
    	padding-left: 15px;
  	}

  	&-tabs {
  		width: 100%;
  		text-align: center;
	  	background-color: $white;
	    border-bottom: solid 1px $light-silver;
  	}

  	&-enquiries {
  		&-on {
  			color: $pink;
		  	font-weight: bold;
  			a {
				color: $pink !important;
			    font-weight: bold;
  			}

		  border-bottom: solid 2px $pink;
  		}	
  		&-off {
  		}


	}

	&-desktop-number {
		float: right !important;

		a {
			padding-right: 0 !important;
		}

		i {
			padding: 0 5px;
		}
	}

	&-gradient-reverse {
	  padding-bottom: 10px;
	  padding-top: 10px;
	}



}

@media (max-width: 480px) {
	.my-travel__nav {
		&-name {
			padding-left: 0px;
		}
	}
}

@media (max-width: 767px) {
	.my-travel__nav {
		&-enquiries-on {
			border-right: none;
		}
		&-desktop-number {
			float: none !important;
		}
	}

  .header--search__loading{
	display: none;
  }
}